import React, { useEffect, useRef, useState } from "react";
import { Button, Checkbox, Grid, Icon, Image, Input } from "semantic-ui-react";
import { QueueController } from "./Time";
import "./Time.css";

import { useHistory, RouteComponentProps } from "react-router-dom";
import { injectIntl, IntlShape } from "react-intl";
import { makeStyles } from "@mui/styles";
import SelectDate from "../QUE/SelectDate";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Slide,
  Typography,
} from "@mui/material";
//image
import personImg from "react-lib/apps/common/person.png";
import TFHead from "../IsHealth/Transform/TFHead";
import SwipeableViews from "react-swipeable-views";
import moment from "moment";
import { dateToStringWithoutTimeBE } from "react-lib/utils/dateUtils";
import ModalDuplicateAppointment from "react-lib/apps/QUE/ModalDuplicateAppointment";
// Utils
import * as Util from "react-lib/utils";
import MobHeader from "react-lib/apps/IsHealth/IHMobile/MobHeader";

interface RouterProps {
  appointId: string;
  appointStatus: string;
  controller: any;
}

interface PatientCreateAppointmentIHProps
  extends RouteComponentProps<RouterProps, any, { channelId?: string }> {
  appointmentData?: any;
  setProp?: any;
  apiToken?: string;
  controller?: QueueController;
  divisionId?: number | string;
  intl?: IntlShape;
  onEvent?: any;
  createAppointment?: any;
  divisionList?: any;
  doctorList?: any;
  errorMessage?: any;
  successMessage?: any;
  patientAppointmentList?: any;
  userProfile?: any;
  timeDivision?: any;
  newUI?: boolean;
  duplicateAPMList?: any[];
}

const useStyles = makeStyles((theme) => ({
  panes: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
    padding: "5px 0px",
  },
  cadeBox: {
    borderRadius: "10px",
    margin: "10px",
    boxSizing: "border-box",
    border: "0.5px solid rgba(227,239,255,0.5)",
    boxShadow: "3px 3px 0px rgba(227,239,255,0.5)",
  },
}));

const COLOR = {
  primary: "var(--primary-theme-color)",
  primary_font: "var(--primary-font-color)",
  grey: `#9e9e9e`,
};

const PatientCreateAppointmentIH = (props: PatientCreateAppointmentIHProps) => {
  const classes = useStyles();
  const history = useHistory();
  const toTime = moment().format("HH:mm");
  const toDate = moment().format("YYYY-MM-DD");
  const [hospital, setHospital] = useState(true);
  const [telemed, setTelemed] = useState(false);
  const [disableTelemed, setDisableTelemed] = useState(false);
  const [addPerson, setAddPerson] = useState(false);
  const [expanded, setExpanded] = useState<string | false>("");
  const [selectTime, setSelectTime] = useState<any>("");
  const [timeCard, setTimeCard] = useState<any>("");
  const [checked, setChecked] = useState(false);
  const [dateDivisionSchedule, setDateDivisionSchedule] = useState<any>();
  const [timeDivision, setTimeDivision] = useState<any>();
  const [cardName, setCardName] = useState<any>();
  const [doctorName, setDoctorName] = useState<any>();
  const [timeValue, setTimeValue] = useState<any>();
  const [selectTimeDivision, setSelectTimeDivision] = useState<any>();
  const [viewIndex, setViewIndex] = useState<number | string>(0);
  const [repetitionNote, setRepetitionNote] = useState("")
  const containerRef = useRef<any>();

  useEffect(() => {
    if (props.match.params.appointStatus === "division") {
      props.onEvent({
        message: "HandleDivisionList",
        params: { only_have_dsb: true, detail: props.appointmentData?.detail },
      });
    } else {
      if (props.newUI) {
        props.onEvent({
          message: "HandleDivisionList",
          params: { only_have_dsb: true, detail: props.appointmentData?.detail },
        });
      } else {
         props.onEvent({
        message: "HandleDoctorList",
      });
      }
    }

    handleDoctorDivisionServiceBlockSchedule();
    props.onEvent({
      message: "HandleGetUserProfile",
      params: {},
    });

    if (props?.appointmentData?.isTelemed) {
      setTelemed(true);
      setHospital(false)
    }

    console.log("***************** cleardata ****************");
    return () => {
      props.setProp(`appointmentData`, {});
    };
  }, []);

  useEffect(() => {
    props.setProp(`appointmentData`, {
      ...props.appointmentData,
      personPhone: props?.userProfile?.ecp_phone_no,
      personLastName: props?.userProfile?.ecp_last_name,
      personName: props?.userProfile?.ecp_first_name,
    });
  }, [props.userProfile]);

  useEffect(() => {
    if (props?.successMessage) {
      if (props.newUI) {
        history.push(`/appointment`);
      } else {
        history.push(`/tuh-transform/appointment`);
      }
    } else if (props?.errorMessage) {
      return;
    }

    props.setProp("successMessage", null);
    props.setProp("errorMessage", null);
  }, [props.errorMessage, props.successMessage]);

  useEffect(() => {
    handleDoctorDivisionServiceBlockSchedule();
  }, [telemed]);

  useEffect(() => {
    handleSetData();
    // handleDivisionScheduleTimeList();
  }, [props.createAppointment]);

  useEffect(() => {
    setTimeDivision(props.timeDivision);
  }, [props.timeDivision]);

  useEffect(() => {
    setSelectTime("");
  }, [props.appointmentData?.selectDate]);

  useEffect(() => {
    
    if (props.match?.params?.appointStatus === "division") {
      props.divisionList?.forEach((item: any) => {
        if (item?.id === parseInt(props.match?.params?.appointId)) {
          console.log("saika ~ item?.name:", item?.name)
          setDisableTelemed(item.is_telemed)
          setCardName(item?.name);
        }
      });
    } else if (props.match?.params?.appointStatus === "doctor") {
      props.doctorList?.forEach((item: any) => {
        if (item?.id === parseInt(props.match?.params?.appointId)) {
          setDisableTelemed(item.is_telemed)
          setCardName(item);
        }
      });
    }
  }, [props.divisionList, props.doctorList]);

  useEffect(() => {
    if (
      props?.appointmentData?.selectDate &&
      props.match?.params?.appointStatus === "division"
    ) {
      props.onEvent({
        message: "HandleDivisionScheduleTimeList",
        params: {
          divisionID: props.match?.params?.appointId,
          selectDate: props?.appointmentData?.selectDate
            ? dateToStringWithoutTimeBE(props?.appointmentData?.selectDate)
            : "",
        },
      });
    }

    if (props?.appointmentData?.selectDate) {
      props.onEvent({
        message: "HandleGetTimeSlotByDate",
        params: {
          // divisionID: props.match?.params?.appointId,
          selectDate: props?.appointmentData?.selectDate || "",
        },
      });
    }

    setSelectTimeDivision("");
    setTimeCard("");
    // handleSetTextTime();
  }, [props?.appointmentData?.selectDate]);

  const handleChangeDistance =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handleCheckType = (checked: any, type: any) => {
    if (type === "hospital") {
      setHospital(checked);
      if (telemed) {
        setTelemed(false);
      }
    } else if (type === "telemed") {
      setTelemed(checked);
      if (hospital) {
        setHospital(false);
      }
    }
  };

  const handleChangeIndex = (index: number) => {
    setViewIndex(index);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleCreateAppointmentIH = () => {
    props.onEvent({
      message: "HandleCreateAppointment",
      params: {
        date: moment(
          `${props.appointmentData?.selectDate} ${props.appointmentData?.time}`,
          "YYYY-MM-DD HH:mm"
        ).toISOString(),
        is_telemed: telemed,
        repetition_note: repetitionNote
      },
    });
  };

  const goBackDoctor = () => {
    setChecked(!checked);
  };

  const goBackDivision = () => {
    setViewIndex(1);
  };

  const handleDoctorDivisionServiceBlockSchedule = () => {
    let selectedDate = moment();
    if (props.appointmentData?.selectDate) {
      selectedDate = moment(props.appointmentData?.selectDate, "YYYY-MM-DD");
    }

    props.onEvent({
      message: "HandleDoctorDivisionServiceBlockSchedule",
      params: {
        divisionID:
          props.match.params.appointStatus === "division"
            ? props.match?.params?.appointId
            : "",
        doctorID:
          props.match.params.appointStatus === "doctor"
            ? props.match?.params?.appointId
            : "",
        month: selectedDate.format("M"),
        year: selectedDate.format("YYYY"),
        is_telemed: telemed,
      },
    });
  };

  const handleChangeMonthYear = (month: string, year: string) => {
    // console.log("handleChangeMonthYear", month, year, props.appointmentData?.selectDate)
    props.onEvent({
      message: "HandleDoctorDivisionServiceBlockSchedule",
      params: {
        divisionID:
          props.match.params.appointStatus === "division"
            ? props.match?.params?.appointId
            : "",
        doctorID:
          props.match.params.appointStatus === "doctor"
            ? props.match?.params?.appointId
            : "",
        month: month,
        year: year,
        is_telemed: telemed,
      },
    });
  };

  const handleSetData = () => {
    var dataSchedule: any[] = [];
    props.createAppointment?.doctorDivisionServiceBlockSchedule?.forEach(
      (item: any) => {
        if (
          moment(item?.start_datetime_iso).format("YYYY-MM-DD") === toDate &&
          moment(item?.end_datetime_iso).format("HH:mm") <= toTime
        ) {
          return;
        } else if (
          moment(item?.start_datetime_iso).format("YYYY-MM-DD") >=
          toDate
        ) {
          dataSchedule.push(
            moment(item?.start_datetime_iso).format("YYYY-MM-DD")
          );
        }
      }
    );

    setDateDivisionSchedule(dataSchedule);
  };
  console.log("saika ~ props:", props)

  // const handleDivisionScheduleTimeList = async () => {
  //   props.createAppointment?.doctorDivisionServiceBlockSchedule?.forEach(
  //     (item: any) => {
  //       if (
  //         moment(item?.start_datetime_iso, "YYYY-MM-DD").format(
  //           "YYYY-MM-DD"
  //         ) === props?.appointmentData?.selectDate
  //       ) {
  //         props.setProp("appointmentData.doctor", item);

  //         let timeData =
  //           ((parseFloat(moment(item?.end_datetime_iso).format("H")) -
  //             parseFloat(moment(item?.start_datetime_iso).format("H"))) /
  //             item?.slot_length) *
  //           60;
  //         let timeValue: any[] = [];

  //         console.log("timeData")
  //         let patientAppointment = props.patientAppointmentList?.items;
  //         for (let j = 0; j < patientAppointment.length; j++) {
  //           for (let i = 0; i < timeData; i++) {
  //             if (i === 0) {
  //               if (
  //                 moment(patientAppointment?.[j]?.estimated_at_iso).format(
  //                   "HH:mm"
  //                 ) === moment(item?.start_datetime_iso).format("HH:mm")
  //               ) {
  //                 j++;
  //               } else {
  //                 timeValue.push(
  //                   moment(item?.start_datetime_iso).format("HH:mm")
  //                 );
  //               }
  //             } else {
  //               if (
  //                 moment(patientAppointment?.[j]?.estimated_at_iso).format(
  //                   "HH:mm"
  //                 ) ===
  //                 moment(item?.start_datetime_iso)
  //                   .add(item?.slot_length * i, "minutes")
  //                   .format("HH:mm")
  //               ) {
  //                 j++;
  //               } else {
  //                 timeValue.push(
  //                   moment(item?.start_datetime_iso)
  //                     .add(item?.slot_length * i, "minutes")
  //                     .format("HH:mm")
  //                 );
  //               }
  //             }
  //           }
  //         }

  //         setTimeValue(timeValue);
  //       }
  //     }
  //   );
  // };

  // const handleSetTextTime = () => {
  //   let dataTime: any[] = [];
  //   let dataTimeAndDoctorDSB: any[] = [];
  //   let doctorDetail: any[] = [];
  //   props.createAppointment?.doctorDivisionServiceBlockSchedule?.forEach(
  //     (item: any) => {
  //       if (
  //         moment(item?.start_datetime_iso, "YYYY-MM-DD").format(
  //           "YYYY-MM-DD"
  //         ) === props?.appointmentData?.selectDate
  //       ) {
  //         //set text for date time
  //         let textTime = `${moment(item?.start_datetime_iso).format(
  //           "HH:mm"
  //         )} - ${moment(item?.end_datetime_iso).format("HH:mm")}`;
  //         if (dataTime.length === 0) {
  //           dataTime.push(textTime);
  //           dataTimeAndDoctorDSB.push({ periodTime: textTime, doctorDSB: [] })
  //         } else if (dataTime.length !== 0 && !dataTime.includes(textTime)) {
  //           dataTime.push(textTime);
  //           dataTimeAndDoctorDSB.push({ periodTime: textTime, doctorDSB: [] })
  //         }

  //         let indOfDataTime = dataTime.indexOf(textTime)

  //         // set selectable time
  //         let timeData =
  //           ((parseFloat(moment(item?.end_datetime_iso).format("H")) -
  //             parseFloat(moment(item?.start_datetime_iso).format("H"))) /
  //             item?.slot_length) *
  //           60;
  //         let timeValue: any[] = [];
  //         for (let i = 0; i < timeData; i++) {
  //           // TO DO verified patient app
  //           timeValue.push(
  //             moment(item?.start_datetime_iso)
  //               .add(item?.slot_length * i, "minutes")
  //               .format("HH:mm")
  //           );
  //         }

  //         dataTimeAndDoctorDSB[indOfDataTime]["doctorDSB"].push({ ...item, timeValue: timeValue })
  //       }
  //     }
  //   );
  //   setTimeDivision(dataTimeAndDoctorDSB);
  // };

  const handleChangePage = () => {
    if (props?.appointmentData?.selectDate === "" && timeCard === "") {
      return;
    } else if (props?.appointmentData?.selectDate !== "" && timeCard !== "") {
      let selectedPeriodTime = timeDivision.find(
        (item: any) => item.periodTime === timeCard
      );
      setDoctorName(selectedPeriodTime.doctorDSB);
      setViewIndex(1);
      // handleDivisionScheduleTimeList();
    }
  };

  const handleSelectTime = (index: any, time: any, item: any) => {
    setSelectTime(index);
    props.setProp("appointmentData.doctor", item);
    props.setProp("appointmentData.time", time);
  };

  const handleChangeValue = (key: string) => (_event: any, data: any) => {
    if (data.type === "checkbox" || data.type === "radio") {
      props.setProp(`appointmentData.${key}`, data.checked);
    } else {
      props.setProp(`appointmentData.${key}`, data.value);
    }
  };

  const handleSetDate = (date: any) => {
    return moment(Util.formatDate(moment(date)), "DD/MM/YYYY").format(
      "DD MMMM YYYY"
    );
  };

  return (
    <div>
       <ModalDuplicateAppointment
        onEvent={props.onEvent}
        open={!!((props.duplicateAPMList || []).length > 0)}
        duplicateAPMList={props.duplicateAPMList || []}
        onClose={() => {props.setProp("duplicateAPMList",[]); setRepetitionNote(""); } }
        appointmentData={props.appointmentData}
        handleCreateAppointmentIH={handleCreateAppointmentIH}
        setRepetitionNote={(note: string)=> setRepetitionNote(note)}
        repetitionNote={repetitionNote}
      />
      <div>
        {props.match.params.appointStatus === "doctor" ? (
          <div
            style={{
              overflow: "auto",
              height: "calc(100vh)",
              ...(props.newUI && { background: "white", position: "relative" }),
            }}
          >
            {props.newUI ? (
              <>
                <div
                  style={{
                    background: "#1AB3CB",
                    height: "125px",
                    width: "100%",
                    position: "absolute",
                    zIndex: "0",
                  }}
                ></div>
                <MobHeader
                  leftIconClick={() => {
                    checked ? setChecked(false) : history.goBack();
                  }}
                  title={"นัดหมายแพทย์"}
                  history={props.history}
                />
              </>
            ) : (
              <TFHead
                leftIconClick={() => {
                  checked ? setChecked(false) : history.goBack();
                }}
                title={"นัดหมายแพทย์"}
              />
            )}
            {/* Doctor */}

            <CardDoctor
              classes={classes}
              data={
                cardName
                // props?.appointmentData?.selectDate !== undefined
                //   ? props.appointmentData
                //   : cardName
              }
              newUI={props.newUI}
            />
            <Slide direction="right" in={!checked} mountOnEnter unmountOnExit>
              <div>
                {props.newUI ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      margin: "10px 0px",
                    }}
                  >
                    <div
                      style={{
                        padding: "0 20px",
                        display: "flex",
                        width: "95%",
                      }}
                    >
                      <div
                        className="tab-selected-telemed"
                        style={{
                          ...(hospital && {
                            borderBottom: "2px solid #1AB3CB",
                            color: "#1AB3CB",
                          }),
                        }}
                        onClick={() => handleCheckType(true, "hospital")}
                      >
                        โรงพยาบาล
                      </div>
                      <div
                        className="tab-selected-telemed"
                        style={{
                          ...(!disableTelemed && {color: "#BCBCBC"}),
                          ...(telemed && {
                            borderBottom: "2px solid #1AB3CB",
                            color: "#1AB3CB",
                          }),
                        }}
                        onClick={() => disableTelemed ? handleCheckType(true, "telemed") : ""}
                      >
                        ออนไลน์
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "95%",
                      margin: "10px 0px",
                    }}
                  >
                    <Checkbox
                      style={{ margin: "0px 10px" }}
                      checked={hospital}
                      onChange={(e: any, { checked }: any) =>
                        handleCheckType(checked, "hospital")
                      }
                    />
                    <div
                      style={{ color: COLOR.primary_font, fontWeight: "bold" }}
                    >
                      โรงพยาบาล
                    </div>
                    <Checkbox
                      style={{ margin: "0px 10px" }}
                      checked={telemed}
                      onChange={(e: any, { checked }: any) =>
                        handleCheckType(checked, "telemed")
                      }
                    />
                    <div
                      style={{ color: COLOR.primary_font, fontWeight: "bold" }}
                    >
                      Telemed
                    </div>
                  </div>
                )}

                {/* calendar */}
                <SelectDate
                  dateDivisionSchedule={dateDivisionSchedule}
                  setProp={props.setProp}
                  date={props.appointmentData?.selectDate}
                  onClickPrev={handleChangeMonthYear}
                  onClickNext={handleChangeMonthYear}
                  checkSelectDate={true}
                />

                {/* Time */}
                <div style={{ margin: "15px 5%" }}>
                  <div
                    className={props.newUI ? "content-header-laban" : ""}
                    style={{
                      fontWeight: "bold",
                      ...(props.newUI && {
                        borderLeft: "2px solid #1AB3CB",
                        paddingLeft: "10px",
                      }),
                    }}
                  >
                    เวลานัดหมาย
                  </div>

                  <div
                    className={
                      props.newUI ? "appointment-selected-time-slot" : ""
                    }
                    style={{
                      margin: "10px 0px",
                      ...((timeDivision === undefined ||
                        timeDivision.length < 1) &&
                        props.newUI && { display: "none" }),
                    }}
                  >
                    {timeDivision?.map((item: any, number: any) => {
                      return (
                        <Accordion
                          expanded={expanded === number}
                          onChange={handleChangeDistance(number)}
                        >
                          <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                            expandIcon={<Icon className="angle down"></Icon>}
                          >
                            <Typography
                              style={{
                                fontWeight: "bold",
                                color:
                                  expanded === number
                                    ? COLOR.primary_font
                                    : COLOR.grey,
                                ...(props.newUI && {
                                  borderBottom: "1px solid #F5F5F5",
                                  width: "100%",
                                  paddingBottom: "10px",
                                }),
                              }}
                            >
                              {item.periodTime}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails style={{ padding: "0px" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Grid
                                columns={3}
                                style={{
                                  margin: "0px 10px 10px",
                                  height: "100px",
                                  overflow: "auto",
                                }}
                              >
                                {item.doctorDSB?.map(
                                  (doctor: any, doctorOrder: number) => {
                                    return doctor.timeValue
                                      ?.filter((time: any) => {
                                        if (
                                          props.appointmentData?.selectDate ===
                                          toDate
                                        ) {
                                          return toTime < time;
                                        } else {
                                          return time;
                                        }
                                      })
                                      ?.map((time: any, index: any) => {
                                        return (
                                          <Grid.Column
                                            columns={3}
                                            style={{ padding: "0px" }}
                                          >
                                            <div
                                              style={{
                                                backgroundColor:
                                                  selectTime === index
                                                    ? "rgba(93,188,210,0.25)"
                                                    : "rgba(227,239,255,0.5)",
                                                margin: "10px",
                                                justifyContent: "center",
                                                display: "flex",
                                                padding: "5px",
                                                borderRadius: "10px",
                                                fontWeight: "bold",
                                                color:
                                                  selectTime === index
                                                    ? COLOR.primary
                                                    : COLOR.grey,
                                                border:
                                                  selectTime === index
                                                    ? `1px solid ${COLOR.primary}`
                                                    : "",
                                              }}
                                              onClick={() =>
                                                handleSelectTime(
                                                  index,
                                                  time,
                                                  doctor
                                                )
                                              }
                                            >
                                              {time}
                                            </div>
                                          </Grid.Column>
                                        );
                                      });
                                  }
                                )}
                              </Grid>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                  </div>
                </div>
                {/* Button */}
                <div style={{ margin: "30px 0px 60px" }}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "80%", fontWeight: "bold" }}>
                      {props.newUI ? (
                        <div
                          className={`content-button ${(!props.appointmentData?.time || !props.appointmentData?.selectDate) && "disable"}`}
                          onClick={() => {
                            if (props.appointmentData?.time && props.appointmentData?.selectDate) {
                              setChecked(!checked);
                            }
                          }}
                        >
                          {"ดำเนินการต่อ"}
                        </div>
                      ) : (
                        <Button
                          color="teal"
                          fluid={true}
                          onClick={() => {
                            if (props.appointmentData?.time !== undefined) {
                              setChecked(!checked);
                            }
                          }}
                        >
                          ดำเนินการต่อ
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
            <div>
              <Slide direction="left" in={checked} mountOnEnter unmountOnExit>
                <div>
                  <CardSummary
                    classes={classes}
                    checked={checked}
                    setChecked={setChecked}
                    addPerson={addPerson}
                    setAddPerson={setAddPerson}
                    handleCreateAppointmentIH={handleCreateAppointmentIH}
                    goBack={goBackDoctor}
                    data={props.appointmentData}
                    handleChangeValue={handleChangeValue}
                    hospital={hospital}
                    telemed={telemed}
                    handleSetDate={handleSetDate}
                    newUI={props.newUI}
                  />
                </div>
              </Slide>
            </div>
          </div>
        ) : (
          <div
            style={{
              overflow: "auto",
              height: "calc(100vh)",
              ...(props.newUI && { background: "white", position: "relative" }),
            }}
          >
            {/* Division Select */}
            {props.newUI ? (
              <>
                <div
                  style={{
                    background: "#1AB3CB",
                    height: "125px",
                    width: "100%",
                    position: "absolute",
                    zIndex: "0",
                  }}
                ></div>
                <MobHeader
                  leftIconClick={() => {
                    viewIndex === 0
                      ? history.goBack()
                      : viewIndex === 1
                      ? setViewIndex(0)
                      : viewIndex === 2 && setViewIndex(1);
                  }}
                  title={
                    viewIndex === 0
                      ? "นัดหมายคลินิก"
                      : viewIndex === 1
                      ? "ระบุแพทย์"
                      : viewIndex === 2 && "นัดหมายแพทย์"
                  }
                  history={props.history}
                />
              </>
            ) : (
              <TFHead
                leftIconClick={() => {
                  viewIndex === 0
                    ? history.goBack()
                    : viewIndex === 1
                    ? setViewIndex(0)
                    : viewIndex === 2 && setViewIndex(1);
                }}
                title={
                  viewIndex === 0
                    ? "นัดหมายคลินิก"
                    : viewIndex === 1
                    ? "ระบุแพทย์"
                    : viewIndex === 2 && "นัดหมายแพทย์"
                }
              />
            )}
            {/* CardDetail */}
            <>
              {console.log(
                "saika props?.appointmentData?.selectDate ",
                props?.appointmentData
              )}
            </>
            {viewIndex !== 2 ? (
              <CardDivision
                classes={classes}
                viewIndex={viewIndex}
                setViewIndex={setViewIndex}
                timeCard={timeCard}
                divisionName={cardName}
                date={props?.appointmentData?.selectDate}
                handleSetDate={handleSetDate}
                newUI={props.newUI}
              />
            ) : (
              <CardDoctor
                classes={classes}
                data={props.appointmentData}
                newUI={props.newUI}
              />
            )}

            <SwipeableViews
              ref={containerRef}
              index={+viewIndex}
              onChangeIndex={handleChangeIndex}
              slideClassName="cheack-view"
            >
              {/* page1 */}
              <div>
                <div>
                  {/* calendar */}
                  <div style={{ margin: "30px 5% 0px" }}>
                    <div
                      style={{
                        fontWeight: "bold",
                        ...(props.newUI && { display: "none" }),
                      }}
                    >
                      ระบุวันที่และเวลาที่ต้องการพบแพทย์
                    </div>
                  </div>
                  <SelectDate
                    dateDivisionSchedule={dateDivisionSchedule}
                    setProp={props.setProp}
                    checkSelectDate={false}
                  />

                  {/* Time */}
                  <div style={{ margin: "15px 5%" }}>
                    <div
                      className={props.newUI ? "content-header-laban" : ""}
                      style={{ fontWeight: "bold" }}
                    >
                      ระบุช่วงเวลา
                    </div>
                    <div
                      className={
                        props.newUI ? "appointment-selected-time-slot" : ""
                      }
                      style={{
                        margin: "10px 0px",
                        ...((timeDivision === undefined ||
                          timeDivision.length < 1) &&
                          props.newUI && { display: "none" }),
                      }}
                    >
                      {timeDivision?.map((item: any, number: any) => {
                        return (
                          <div
                            style={{
                              backgroundColor:
                                selectTimeDivision === number
                                  ? "rgba(93, 188, 210, 0.25)"
                                  : "",
                            }}
                            onClick={() => {
                              setSelectTimeDivision(number);
                              setTimeCard(item.periodTime);
                            }}
                          >
                            {props.newUI ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  margin: "0px 15px",
                                  borderBottom: "2px solid #F5F5F5",
                                  padding: "10px 0px",
                                  color: "#746A6A",
                                  fontWeight: "bold",
                                }}
                              >
                                <div>{item.periodTime}</div>
                                <div>
                                  <Icon className="angle right"></Icon>
                                </div>
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  margin: "0px 30px",
                                  borderBottom:
                                    selectTimeDivision === number
                                      ? "1px solid #5dbcd2"
                                      : "",
                                  color:
                                    selectTimeDivision === number
                                      ? COLOR.primary
                                      : "",
                                  fontWeight: "bold",
                                }}
                              >
                                <div>{item.periodTime}</div>
                                <div>
                                  <Icon className="angle right"></Icon>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {/* Button */}
                  <div style={{ margin: "30px 0px 60px" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div style={{ width: "80%", fontWeight: "bold" }}>
                        {props.newUI ? (
                          <div
                            className="content-button "
                            onClick={() => handleChangePage()}
                          >
                            {"ดำเนินการต่อ"}
                          </div>
                        ) : (
                          <Button
                            color="teal"
                            fluid={true}
                            onClick={() => handleChangePage()}
                          >
                            ดำเนินการต่อ
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* page2 */}
              <div>
                <div style={{ margin: props.newUI ? "10px" : "0px 10px" }}>
                  <TimeSlotDoctor
                    doctorName={doctorName}
                    expanded={expanded}
                    handleChangeDistance={handleChangeDistance}
                    handleSelectTime={handleSelectTime}
                    selectTime={selectTime}
                    newUI={props.newUI}
                  />
                </div>
                {/* Button */}
                <div style={{ margin: "30px 0px 60px" }}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "80%", fontWeight: "bold" }}>
                      {props.newUI ? (
                        <div
                          className="content-button "
                          onClick={() => {
                            if (
                              props.appointmentData?.doctor === undefined &&
                              props.appointmentData?.time === undefined
                            ) {
                              return;
                            } else {
                              setViewIndex(2);
                            }
                          }}
                        >
                          {"ดำเนินการต่อ"}
                        </div>
                      ) : (
                        <Button
                          color="teal"
                          fluid={true}
                          onClick={() => {
                            if (
                              props.appointmentData?.doctor === undefined &&
                              props.appointmentData?.time === undefined
                            ) {
                              return;
                            } else {
                              setViewIndex(2);
                            }
                          }}
                        >
                          ดำเนินการต่อ
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* page3 */}
              <div>
                <CardSummary
                  classes={classes}
                  checked={checked}
                  setChecked={setChecked}
                  addPerson={addPerson}
                  setAddPerson={setAddPerson}
                  handleCreateAppointmentIH={handleCreateAppointmentIH}
                  goBack={goBackDivision}
                  data={props.appointmentData}
                  displayType={true}
                  handleChangeValue={handleChangeValue}
                  handleSetDate={handleSetDate}
                  newUI={props.newUI}
                  hospital={hospital}
                  telemed={telemed}
                />
              </div>
            </SwipeableViews>
          </div>
        )}
      </div>
    </div>
  );
};

PatientCreateAppointmentIH.defaultProps = {};

const TimeSlotDoctor = (props: any) => {
  return (
    <div>
      {props.newUI ? (
        <div>
          {props.doctorName?.map((item: any, number: any) => {
            return (
              <div className="card-appointment">
                <Accordion
                  expanded={props.expanded === number}
                  onChange={props.handleChangeDistance(number)}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Typography>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ width: "15%", margin: "5px" }}>
                          <Image
                            src={
                              item?.image
                                ? item?.image
                                : item?.doctor?.image
                                ? item.doctor.image
                                : personImg
                            }
                            style={{ borderRadius: "10px" }}
                          />
                        </div>
                        <div
                          style={{
                            width: "75%",
                            margin: "10px",
                          }}
                        >
                          <div
                            style={{
                              color: "var(--primary-theme-color)",
                              fontWeight: "bold",
                              paddingBottom: "5px",
                            }}
                          >
                            {item?.doctor_full_name}
                          </div>
                        </div>
                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: "0px" }}>
                    <div>
                      <div
                        className={props.newUI ? "content-header-laban" : ""}
                        style={{
                          color: COLOR.primary_font,
                          fontWeight: "bold",
                          paddingBottom: "5px",
                          margin: "10px 10px",
                        }}
                      >
                        ช่วงเวลาออกตรวจ
                      </div>
                      <div>
                        <Grid
                          columns={3}
                          style={{
                            margin: "0px 10px 10px",
                            overflow: "auto",
                          }}
                        >
                          {item?.timeValue?.map((items: any, index: any) => {
                            return (
                              <Grid.Column
                                columns={3}
                                style={{ padding: "0px" }}
                              >
                                <div
                                  style={{
                                    backgroundColor:
                                      props.selectTime === index
                                        ? "rgba(93,188,210,0.25)"
                                        : "rgba(227,239,255,0.5)",
                                    margin: "10px",
                                    justifyContent: "center",
                                    display: "flex",
                                    padding: "5px",
                                    borderRadius: "10px",
                                    fontWeight: "bold",
                                    color:
                                      props.selectTime === index
                                        ? COLOR.primary
                                        : COLOR.grey,
                                    border:
                                      props.selectTime === index
                                        ? `1px solid ${COLOR.primary}`
                                        : "",
                                  }}
                                  onClick={() =>
                                    props.handleSelectTime(index, items, item)
                                  }
                                >
                                  {items}
                                </div>
                              </Grid.Column>
                            );
                          })}
                        </Grid>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            padding: "0px 20px 10px",
                          }}
                          onClick={props.handleChangeDistance(number)}
                        >
                          <Icon name="chevron up" color="grey" size="large" />
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          })}
        </div>
      ) : (
        <div>
          {props.doctorName?.map((item: any, number: any) => {
            return (
              <Accordion
                expanded={props.expanded === number}
                onChange={props.handleChangeDistance(number)}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  expandIcon={<Icon className="angle down"></Icon>}
                >
                  <Typography>
                    <div
                      style={{
                        display: "flex",
                        padding: "10px",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ width: "25%", margin: "5px" }}>
                        <Image
                          src={
                            item?.image
                              ? item?.image
                              : item?.doctor?.image
                              ? item.doctor.image
                              : personImg
                          }
                          style={{ borderRadius: "10px" }}
                        />
                      </div>
                      <div
                        style={{
                          width: "75%",
                          margin: "10px",
                        }}
                      >
                        <div
                          style={{
                            color: COLOR.primary_font,
                            fontWeight: "bold",
                            paddingBottom: "5px",
                          }}
                        >
                          {item?.doctor_full_name}
                        </div>
                        <div style={{ color: COLOR.grey }}>
                          {item?.division_name}
                        </div>
                      </div>
                    </div>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: "0px" }}>
                  <div>
                    <div
                      className={props.newUI ? "content-header-laban" : ""}
                      style={{
                        color: COLOR.primary_font,
                        fontWeight: "bold",
                        paddingBottom: "5px",
                        margin: "0px 10px",
                      }}
                    >
                      ช่วงเวลาออกตรวจ
                    </div>
                    <div>
                      <Grid
                        columns={3}
                        style={{
                          margin: "0px 10px 10px",
                          height: "100px",
                          overflow: "auto",
                        }}
                      >
                        {item?.timeValue?.map((items: any, index: any) => {
                          return (
                            <Grid.Column columns={3} style={{ padding: "0px" }}>
                              <div
                                style={{
                                  backgroundColor:
                                    props.selectTime === index
                                      ? "rgba(93,188,210,0.25)"
                                      : "rgba(227,239,255,0.5)",
                                  margin: "10px",
                                  justifyContent: "center",
                                  display: "flex",
                                  padding: "5px",
                                  borderRadius: "10px",
                                  fontWeight: "bold",
                                  color:
                                    props.selectTime === index
                                      ? COLOR.primary
                                      : COLOR.grey,
                                  border:
                                    props.selectTime === index
                                      ? `1px solid ${COLOR.primary}`
                                      : "",
                                }}
                                onClick={() =>
                                  props.handleSelectTime(index, items, item)
                                }
                              >
                                {items}
                              </div>
                            </Grid.Column>
                          );
                        })}
                      </Grid>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      )}
    </div>
  );
};

const CardDoctor = (props: any) => {
  console.log("CardDoctor create: ", props);
  return (
    <>
      {props.newUI ? (
        <div className="card-selected-doctor">
          <div
            style={{
              display: "flex",
              padding: "10px",
              alignItems: "center",
            }}
          >
            <div style={{ width: "15%", margin: "5px" }}>
              <Image
                src={
                  props.data?.doctor?.image
                    ? props.data?.doctor?.image
                    : props.data?.image
                    ? props.data.image
                    : personImg
                }
              />
            </div>
            <div
              style={{
                width: "75%",
                margin: "10px",
              }}
            >
              <div
                style={{
                  color: "#1ABDD4",
                  fontWeight: "bold",
                }}
              >
                {props.data?.doctor?.doctor_full_name || props?.data?.full_name}
              </div>
              <div style={{ color: COLOR.grey, padding: "5px 0px" }}>
                {props.data?.divisions?.[0]?.name || ""}
              </div>
              <div style={{ color: COLOR.grey }}>
                {props.data?.doctor?.hospital_name}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ margin: "3%" }}>
          <div className={props.classes.cadeBox}>
            <div
              style={{
                display: "flex",
                padding: "10px",
                alignItems: "center",
              }}
            >
              <div style={{ width: "25%", margin: "5px" }}>
                <Image
                  src={
                    props.data?.doctor?.image
                      ? props.data?.doctor?.image
                      : props.data?.image
                      ? props.data.image
                      : personImg
                  }
                  circular
                />
              </div>
              <div
                style={{
                  width: "75%",
                  margin: "10px",
                }}
              >
                <div
                  style={{
                    color: COLOR.primary_font,
                    fontWeight: "bold",
                  }}
                >
                  {props.data?.doctor?.doctor_full_name ||
                    props?.data?.full_name}
                </div>
                <div style={{ color: COLOR.grey, padding: "5px 0px" }}>
                  {props.data?.doctor?.division_name}
                </div>
                <div style={{ color: COLOR.grey }}>
                  {props.data?.doctor?.hospital_name}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const CardDivision = (props: any) => {
  return (
    <>
      {props.newUI ? (
        <div className="card-selected-division">
          <div
            style={{
              display: "flex",
              padding: "10px",
              alignItems: "center",
            }}
          >
            <div className="division" style={{ width: "15%", margin: "5px" }}>
              <Image
                src={
                  props.data?.doctor?.image
                    ? props.data?.doctor?.image
                    : props.data?.image
                    ? props.data.image
                    : personImg
                }
              />
            </div>
            <div
              style={{
                width: "80%",
                margin: "10px",
              }}
            >
              <div
                style={{
                  color: COLOR.primary,
                  fontWeight: "bold",
                  paddingBottom: "5px",
                }}
              >
                {props.divisionName}
              </div>
              <div
                style={{
                  paddingBottom: "5px",
                }}
              >
                {props.viewIndex === 1
                  ? `${props.handleSetDate(props.date)} (${props.timeCard})`
                  : " "}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{ margin: "15px 0px" }}
          onClick={() => {
            props.viewIndex === 1 && props.setViewIndex(0);
          }}
        >
          <div className={props.classes.cadeBox}>
            <div
              style={{
                display: "flex",
                padding: "10px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "20%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Image
                  src={personImg}
                  circular
                  style={{
                    border: "1px solid rgba(227,239,255,0.5)",
                    height: "50px",
                    width: "auto",
                  }}
                />
              </div>
              <div
                style={{
                  width: "80%",
                  margin: "10px",
                }}
              >
                <div
                  style={{
                    color: COLOR.primary,
                    fontWeight: "bold",
                    paddingBottom: "5px",
                  }}
                >
                  {props.divisionName}
                </div>
                <div
                  style={{
                    paddingBottom: "5px",
                  }}
                >
                  {props.viewIndex === 1
                    ? `${props.handleSetDate(props.date)} (${props.timeCard})`
                    : " "}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const CardSummary = (props: any) => {
  return (
    <div>
      <div className={props.newUI ? "" : props.classes.cadeBox}>
        {/* Confirm */}
        <div style={{ margin: props.newUI ? "35px 5%" : "15px 5%" }}>
          <div
            className={props.newUI ? "content-header-laban" : ""}
            style={{ fontWeight: "bold" }}
          >
            วันและเวลานัดหมาย
          </div>
          {props.newUI ? (
            <div className="appointment-card-summary">
              <div>
                <div className="card-appointment-detail">
                  <Icon
                    circular
                    name="calendar alternate outline"
                    size="small"
                    style={{
                      ...(props.newUI && {
                        background: "#E3F3F9",
                      }),
                    }}
                  />
                  <div style={{ marginLeft: "10px" }}>
                    {props.handleSetDate(props.data?.selectDate)}
                  </div>
                </div>
                <div className="card-appointment-detail">
                  <Icon
                    circular
                    name="clock outline"
                    size="small"
                    style={{
                      ...(props.newUI && {
                        background: "#E3F3F9",
                      }),
                    }}
                  />
                  <div style={{ marginLeft: "10px" }}>{props.data?.time}</div>
                </div>
              </div>
              <div>
                {props?.hospital ? (
                  <div
                    className="card-appointment-telemed"
                    style={{
                      background: "#96CF9E",
                      marginRight: "10px",
                    }}
                  >
                    <Icon name="hospital outline" />
                    <div style={{ marginLeft: "2px" }}>{"โรงพยาบาล"}</div>
                  </div>
                ) : props?.telemed ? (
                  <div
                    className="card-appointment-telemed"
                    style={{ background: "#F493AC" }}
                  >
                    <Icon name="video camera" />
                    <div style={{ marginLeft: "2px" }}>{"Telemedicine"}</div>
                  </div>
                ) : (
                  " "
                )}
              </div>
            </div>
          ) : (
            <div
              style={{
                margin: "10px 0px",
                padding: "10px 20px",
                backgroundColor: "rgba(93,188,210,0.25)",
                borderRadius: "10px",
              }}
              onClick={() => props.goBack()}
            >
              <div style={{ display: "flex" }}>
                <Icon className="calendar alternate outline" color="grey" />
                <div style={{ width: "100%", margin: "0px 10px" }}>
                  {props.handleSetDate(props.data?.selectDate)}
                </div>
              </div>
              <div style={{ display: "flex", margin: "5px 0px" }}>
                <Icon className="clock outline" color="grey" />
                <div style={{ width: "100%", margin: "0px 10px" }}>
                  {props.data?.time}
                </div>
              </div>
              <div style={{ display: props.displayType ? "none" : "flex" }}>
                <Icon className="video" color="grey" />
                <div style={{ width: "100%", margin: "0px 10px" }}>
                  {props?.hospital
                    ? "Hospital"
                    : props?.telemed
                    ? "Telemed"
                    : " "}
                </div>
              </div>
            </div>
          )}
        </div>
        {/* detail */}
        <div style={{ margin: "20px 5%" }}>
          <div
            className={props.newUI ? "content-header-laban" : ""}
            style={{ fontWeight: "bold" }}
          >
            รายละเอียดอาการเพิ่มเติม
          </div>
          <div
            className={props.newUI ? "content-input-bottom" : ""}
            style={{ marginTop: "10px" }}
          >
            <Input
              fluid={true}
              onChange={props.handleChangeValue("detail")}
              value={props.data?.detail}
            />
          </div>
        </div>
        <div
          style={{
            margin: "50px 5px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Checkbox
              className="ui.toggle.checkbox"
              checked={props.addPerson}
              onChange={(e: any, { checked }: any) =>
                props.setAddPerson(checked)
              }
              style={{ margin: "0px 10px" }}
            />
            <div
              style={{
                color: COLOR.primary_font,
                fontWeight: "bold",
              }}
            >
              เพิ่มผู้ติดต่อกรณีฉุกเฉิน
            </div>
          </div>
          <div style={{ display: props.addPerson ? "block" : "none" }}>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <div
                className={props.newUI ? "content-input-bottom" : ""}
                style={{ width: "50%", margin: "0px 10px" }}
              >
                <Input
                  fluid={true}
                  onChange={props.handleChangeValue("personName")}
                  value={props.data?.personName}
                  placeholder="ชื่อ"
                />
              </div>
              <div
                className={props.newUI ? "content-input-bottom" : ""}
                style={{ width: "50%", margin: "0px 10px" }}
              >
                <Input
                  fluid={true}
                  onChange={props.handleChangeValue("personLastName")}
                  value={props.data?.personLastName}
                  placeholder="นามสกุล"
                />
              </div>
            </div>

            <div
              className={props.newUI ? "content-input-bottom" : ""}
              style={{
                width: props.newUI ? "" : "45%",
                margin: "10px 10px",
                ...(props.newUI && { display: "flex" }),
              }}
            >
              <Input
                fluid={true}
                onChange={props.handleChangeValue("personPhone")}
                value={props.data?.personPhone}
                placeholder="เบอร์โทรศัพท์"
              />
            </div>
          </div>
        </div>
      </div>
      {/* Button */}
      <div style={{ marginTop: "30px" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ width: "80%", fontWeight: "bold" }}>
            {props.newUI ? (
              <div
                className="content-button "
                onClick={() => {
                  props.handleCreateAppointmentIH();
                }}
              >
                {"ดำเนินการต่อ"}
              </div>
            ) : (
              <Button
                color="teal"
                fluid={true}
                onClick={() => {
                  props.handleCreateAppointmentIH();
                }}
              >
                ยืนยันนัดหมาย
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(
  injectIntl(PatientCreateAppointmentIH, { forwardRef: true })
);
