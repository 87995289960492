import React, { Ref, forwardRef, useEffect, useState } from "react";

import {
  Divider,
  Form,
  Icon,
  Image,
  Input,
  SelectProps,
  Modal,
  Radio,
  Transition,
  InputProps,
  Search,
} from "semantic-ui-react";
import MobHeader from "./MobHeader";
import "react-lib/apps/IsHealth/IHMobile/ishealth.scss";

// UI
import ChartList from "./ChartList";
import ChatBox from "react-lib/apps/IsHealth/Chat/ChatBox";

//type
import { profileInfo } from "./TypeModal";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import createStyles from "@mui/material/styles/createStyles";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Cookies from "js-cookie";

type MobChatListTypes = {
  onEvent?: any;
  noApiToken: Function;
  history: any;
  profileInfo?: profileInfo;
  chatListRef: Ref<unknown> | undefined;
  chatBoxRef: Ref<unknown> | undefined;
  apiToken?: any;
  chatController: any;
  userId?: any;
  channelList?: any;
  loadChannelList: () => any;
  followDivisionProfile: (item: any) => any;
  unfollowChannelList: any;
  isLoading?: any;
  setProp: any;
  focusSelectMenu?: any;
  chatUnread?: number;
};

const MobChatList = (props: MobChatListTypes) => {
  const [selectChat, setSelectChat] = useState<any>({
    id: "",
    division: "",
    channel_Name: "",
  });
  const [searchBox, setSearchBox] = useState<string>("");
  const [keyWord, setkeyWord] = useState<string>("");
  const [chatListSelect, setChatListSelect] = useState<string>("");
  const [typingTimeout, setTypingTimeout] = useState<any>(null);
  const [lockChatList, setLockChatList] = useState<boolean>(true);

  useEffect(() => {
    if (!props.apiToken && !Cookies.get("apiToken")) {
      props.noApiToken();
    } else {
      props.loadChannelList();
    }

    return () => {};
  }, []);

  const handleUpdateProfile = () => {
    props.onEvent({
      message: "HandleSetProfileInfo",
      params: { history: props.history },
    });
  };

  const handleSelectChat = (
    id: string,
    division: string,
    channel_Name: string
  ) => {
    // console.log(
    //   "saika ;) id,division,channel_Name ",
    //   id,
    //   " : ",
    //   division,
    //   " : ",
    //   channel_Name
    // );
    setChatListSelect(id);
    setSelectChat({
      id: id,
      division: division,
      channel_Name: channel_Name,
    });
    props.setProp("chatDivision", { division });
    let path = "/chatChannel/" + id + "/";
    props.history.push(path);
  };

  const handleSearchChat = (value: any) => {
    setSearchBox(value);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => {
        setkeyWord(value);
      }, 800)
    );
  };

  return (
    <div className="content-detail">
      <div style={{ padding: "20px" }}>
        <div className="chat-search">
          <Search
            onSearchChange={(e, { value }) => {
              handleSearchChat(value);
            }}
            placeholder="Search..."
            showNoResults={false}
            value={searchBox}
          />
        </div>
        <ChartList
          ref={props.chatListRef}
          apiToken={props.apiToken}
          userId={props.userId}
          chatController={props.chatController}
          isLoading={props.isLoading}
          loadChannelList={props.loadChannelList}
          channelList={props.channelList}
          unfollowChannelList={props.unfollowChannelList}
          followDivisionProfile={props.followDivisionProfile}
          onChannelSelected={handleSelectChat}
          keyWordSearch={keyWord}
          menuSelected={chatListSelect}
          setProp={props.setProp}
          onEvent={function (e: any) {
            throw new Error("Function not implemented.");
          }}
          chatUnread={props.chatUnread}
        />
      </div>
    </div>
  );
};

MobChatList.displayname = "MobChatList";
export default MobChatList;
