import React from "react";
import PropTypes from "prop-types";
import { Image, Form, Header } from "semantic-ui-react";
import personImg from "../../common/person.png"

const UserProfile = props => {

  return (
    <Form>
      <Form.Group inline>
      <Form.Field>
        <Image
          src={props.user.image ? props.user.image : personImg}
          circular
          size="tiny"
        />
        </Form.Field>
        <Form.Field>
          <Header>{`${props.user.user_obj.full_name}`}</Header>
        </Form.Field>
      </Form.Group>
      <Form.Group style={{ display: props.user.isDisplay_email ? null : "none" }}>
        <Form.Field>
          E-mail:
        </Form.Field>
        <Form.Field>
          {props.user.email}
        </Form.Field>
      </Form.Group>
    </Form>
  );
};

UserProfile.defaultProps = {
  user: {},
};

UserProfile.propTypes = {
  user: PropTypes.object,
};

export default React.memo(UserProfile);
