import axios from "axios";
import CONFIG from "config/config";

// For Check File Exist in origin (Example of Path: "/static/images/filename.jpg")
export async function ImageExistsCheck(path: string): Promise<boolean> {
  const fullUrl = `${origin}${path}`;
  try {
    await axios.get(fullUrl);
    return true;
  } catch {
    return false;
  }
}

// Only path: "static/images/logoform/" and ".png" file
export async function LogoFormCheck(company: string, form: string): Promise<boolean> {
  return await ImageExistsCheck(`/static/images/logoform/${company}_${form}.png`);
}

// Return Path of File If Exist
export async function getLogoFormPath(
  form: string
): Promise<{ src: string; width: number; height: number } | null> {
  const path = `/static/images/logoform/${CONFIG.COMPANY}/${form}.png`;
  const fullUrl = `${origin}${path}`;

  try {
    let res = await axios.get(fullUrl, { responseType: "arraybuffer" });

    if (!(res.data instanceof ArrayBuffer)) {
      return null;
    }

    return new Promise((resolve) => {
      const blob = new Blob([res.data]);
      const img = new Image();
      img.onload = function () {
        resolve({ src: path, width: img.width, height: img.height });
      };
      img.src = URL.createObjectURL(blob);
    });
  } catch {
    return null;
  }
}
