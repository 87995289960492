import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
  Divider,
  Form,
  Icon,
  Image,
  Input,
  SelectProps,
  Modal,
  Radio,
  Transition,
  InputProps,
  Button,
} from "semantic-ui-react";
import MobHeader from "./MobHeader";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "react-lib/apps/IsHealth/IHMobile/ishealth.scss";
import BottomSheetOption from "react-lib/apps/IsHealth/IHMobile/BottomSheetOption";
import DateScrollPicker from "react-lib/apps/IsHealth/IHMobile/DateScrollPicker";

//type
import { dropDownOption, profileInfo } from "./TypeModal";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import createStyles from "@mui/material/styles/createStyles";
import { useIntl } from "react-intl";
import { error, profile } from "console";
import MessageErrorCurvedDialog from "../Common/MessageErrorCurvedDialog";
// import { logoSquare } from "/images/ishealth/logo-square.png";s

type MobEditProfileTypes = {
  onEvent?: any;
  history: any;
  loading?: boolean;
  profileInfo?: profileInfo;
  dropDownOption?: dropDownOption;
  setProps: any;
};

const useStyles = makeStyles((theme) => ({
  datePick: {
    ".MuiFormControl-root": {
      width: "100%",
    },
    "& .root ": {
      width: "100%",
    },
  },
}));

const MobEditProfile = (props: MobEditProfileTypes) => {
  const [checkField, setCheckField] = useState<any[]>([]);
  const [errorPrename, setErrorPrename] = useState<boolean>(false);
  const [errorFieldPrename, setErrorFieldPrename] = useState<boolean>(false);
  const [errorFieldDOB, setErrorFieldDOB] = useState<boolean>(false);
  const intl = useIntl();
  const lang = intl.locale.toUpperCase().includes("TH") ? "TH" : "EN";

  useEffect(() => {
    props.onEvent({
      message: "HandleGetUserProfile",
      params: { history: props.history, loading: true, lang: lang },
    });
  }, []);
  // Callback memo
  const mapItemsToOptions = useCallback(
    (items: any) => {
      return items
        .map((item: any) => ({
          key: item.id,
          text: item.name,
          value: item.id,
          language: item.language,
        }))
        .filter((options: any) => !!options.text)
        .filter((option: any) => lang.includes(option.language));
    },
    [intl.locale]
  );

  useEffect(() => {
    if (props.profileInfo?.dob && props.profileInfo?.pre_name) {
      const filterPrename = prenameOptions.filter((item:any)=> item.value === props.profileInfo?.pre_name)
      if (handleCalculateAge(props.profileInfo?.dob)) {
        if (filterPrename[0]?.text === "ด.ญ." || filterPrename[0]?.text === "ด.ช.") {
          setErrorPrename(true)
          setErrorFieldPrename(true)
          setErrorFieldDOB(true)
        } else {
          setErrorPrename(false)
          setErrorFieldPrename(false)
          setErrorFieldDOB(false)
        }
      } else {
        if (filterPrename[0]?.text !== "ด.ญ." && filterPrename[0]?.text !== "ด.ช.") {
          setErrorPrename(true)
          setErrorFieldPrename(true)
          setErrorFieldDOB(true)
        } else {
          setErrorPrename(false)
          setErrorFieldPrename(false)
          setErrorFieldDOB(false)
        }
      }
    }
  }, [props.profileInfo?.dob, props.profileInfo?.pre_name]);

  const prenameOptions = useMemo(() => {
    return mapItemsToOptions(props.dropDownOption?.prenames || []);
  }, [props.dropDownOption?.prenames, intl.locale]);

  const genderOptions = useMemo(() => {
    return mapItemsToOptions(props.dropDownOption?.genders || []);
  }, [props.dropDownOption?.genders, intl.locale]);

  const nationalityOptions = useMemo(() => {
    return mapItemsToOptions(props.dropDownOption?.nationalities || []);
  }, [props.dropDownOption?.nationalities, intl.locale]);

  const handleChangeProfileInfo = (e: any, v: any) => {
    if (v.name === "phone_no" && `${v.value}`.length > 10) {
      return;
    }
    props.onEvent({
      message: "HandleSetProfileInfo",
      params: { key: v.name, value: v.value },
    });
  };

  const handleUpdateProfile = () => {
    if (!props.loading) {
      props.onEvent({
        message: "HandleUpdateUserProfile",
        params: { history: props.history },
      });
    }
  };

  const handleCalculateAge = (dobString: string) => {
    const today = moment();
    const dob = moment(dobString, "YYYYMMDD");
    const year = today.year() - dob.year();
    const month = today.month() - dob.month();

    if (year < 15) {
      return false;
    } else if (year == 15 && ((month < 0) || (month === 0 && today.date() < dob.date()))) {
      return false;
    } else {
      return true;
    }
  };

  const handleDateChange = (date: Date) => {
    props.onEvent({
      message: "HandleSetProfileInfo",
      params: {
        key: "dob",
        value: moment(date).format("YYYYMMDD"),
      },
    });
  };

  const checkDisable = () => {
    if (
      props.profileInfo?.error?.dob ||
      props.profileInfo?.error?.email ||
      props.profileInfo?.error?.first_name ||
      props.profileInfo?.error?.gender ||
      props.profileInfo?.error?.last_name ||
      props.profileInfo?.error?.nationality ||
      props.profileInfo?.error?.pre_name
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleClearError = (filed: string) => {
    let filedAll: any = props.profileInfo?.error || {};

    if (filedAll[filed]) {
      filedAll[filed] = "";
    }

    props.onEvent({
      message: "HandleSetLoginInfo",
      params: { key: "error", value: { ...filedAll } },
    });
  };

  const HandleShowError = (type: string) => {
    let error = "";

    switch (type) {
      case "pre_name":
        error = "โปรดกรอกข้อมูลคำนำหน้า";
        break;
      case "first_name":
        error = "โปรดกรอกข้อมูลชื่อ";
        break;
      case "last_name":
        error = "โปรดกรอกข้อมูลนามสกุล";
        break;
      case "gender":
        error = "โปรดกรอกข้อมูลเพศ";
        break;
      case "nationality":
        error = "โปรดกรอกข้อมูลสัญชาติ";
        break;
      case "email":
        error = "โปรดกรอกข้อมูล e-mail";
        break;
      case "phone_no":
        error = "โปรดกรอกข้อมูลเบอร์มือถือ";
        break;
    }

    return <div style={{ color: "red", margin: "0 0 5px" }}>{error}</div>;
  };

  const handleCheckDisable = () => {
    if (["VERIFIED"].includes(props.profileInfo?.profile_status || "")) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="content-header">
      <MessageErrorCurvedDialog
        open={errorPrename}
        onClose={() => setErrorPrename(false)} 
        textMessage={"คำนำหน้าไม่ถูกต้อง \nอายุและคำนำหน้าชื่อไม่สอดคล้องกัน"}
        icon="/images/ishealth/icon_close_error.png"
        disableTimer={true}
      />
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MobHeader title="สร้างโปรไฟล์ผู้ป่วย" history={props.history} />

        {/* Form EditProfile */}
        <div className="content-detail">
          <div style={{ padding: "30px" }}>
            <Form>
              <Form.Field>
                <div
                  style={{
                    color: "#1AB3CB",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  ข้อมูลผู้ป่วย
                </div>
              </Form.Field>

              <div
                className={"form-margin"+ `${errorFieldPrename ? " error" : ""}`}
                onClick={() => handleClearError("pre_name")}
              >
                <BottomSheetOption
                  disabled={handleCheckDisable()}
                  required={true}
                  value={props.profileInfo?.pre_name}
                  name="pre_name"
                  options={prenameOptions}
                  label={"คำนำหน้า"}
                  onChange={handleChangeProfileInfo}
                />
              </div>
              {props.profileInfo?.error?.pre_name &&
                HandleShowError("pre_name")}

              <div
                className="form-margin"
                onClick={() => handleClearError("first_name")}
              >
                <Form.Input
                  disabled={handleCheckDisable()}
                  required
                  name="first_name"
                  value={props.profileInfo?.first_name || ""}
                  label="ชื่อ"
                  onChange={handleChangeProfileInfo}
                />
              </div>
              {props.profileInfo?.error?.first_name &&
                HandleShowError("first_name")}

              <div
                className="form-margin"
                onClick={() => handleClearError("last_name")}
              >
                <Form.Input
                  disabled={handleCheckDisable()}
                  required
                  name="last_name"
                  value={props.profileInfo?.last_name || ""}
                  label="นามสกุล"
                  onChange={handleChangeProfileInfo}
                />
              </div>
              {props.profileInfo?.error?.last_name &&
                HandleShowError("last_name")}

              <div
                className="form-margin"
                onClick={() => handleClearError("gender")}
              >
                <BottomSheetOption
                  disabled={handleCheckDisable()}
                  required={true}
                  value={props.profileInfo?.gender || ""}
                  name="gender"
                  options={genderOptions}
                  label={"เพศ"}
                  onChange={handleChangeProfileInfo}
                />
              </div>
              {props.profileInfo?.error?.gender && HandleShowError("gender")}

              <Form.Input
                value={props.profileInfo?.cid || ""}
                label="บัตรประชาชน / หนังสือเดินทาง"
                disabled
              />

              <div
                className={"form-margin"+ `${errorFieldDOB ? " error" : ""}`}
                onClick={() => handleClearError("dob")}
              >
                <DateScrollPicker
                  disabled={handleCheckDisable()}
                  label={intl.formatMessage({
                    id: "profile.day_month_year_birth",
                  })}
                  value={moment(props.profileInfo?.dob) || moment()}
                  required={true}
                  dateFormat="DD/MM/YYYY"
                  maxDate={moment()}
                  onChange={handleDateChange}
                />
              </div>
              {props.profileInfo?.error?.dob && HandleShowError("dob")}

              <div
                className="form-margin"
                onClick={() => handleClearError("nationality")}
              >
                <BottomSheetOption
                  disabled={handleCheckDisable()}
                  required={true}
                  value={props.profileInfo?.nationality}
                  name="nationality"
                  options={nationalityOptions}
                  label={"สัญชาติ"}
                  onChange={handleChangeProfileInfo}
                />
              </div>
              {props.profileInfo?.error?.nationality &&
                HandleShowError("nationality")}

              <Form.Field>
                <div
                  style={{
                    color: "#1AB3CB",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  ข้อมูลติดต่อ
                </div>
              </Form.Field>

              <div
                className="form-margin"
                onClick={() => handleClearError("phone_no")}
              >
                <Form.Input
                  disabled={handleCheckDisable()}
                  required
                  name="phone_no"
                  type="number"
                  value={props.profileInfo?.phone_no || ""}
                  label="เบอร์มือถือ"
                  onChange={handleChangeProfileInfo}
                />
              </div>
              {props.profileInfo?.error?.phone_no &&
                HandleShowError("phone_no")}
              <div
                className="form-margin"
                onClick={() => handleClearError("email")}
              >
                <Form.Input
                  disabled={handleCheckDisable()}
                  required
                  name="email"
                  value={props.profileInfo?.email || ""}
                  label="อีเมล์"
                  onChange={handleChangeProfileInfo}
                />
              </div>
              {props.profileInfo?.error?.email && HandleShowError("email")}
            </Form>

            <div style={{ width: "100%", margin: "30px 0" }}>
              <div className="ishealth-buttom">
                <Button
                  disabled={checkDisable()}
                  loading={props.loading}
                  onClick={() => handleUpdateProfile()}
                >
                  ยืนยัน
                </Button>
              </div>
            </div>
          </div>
        </div>
      </LocalizationProvider>
    </div>
  );
};

MobEditProfile.displayname = "EditProfile";
export default MobEditProfile;
