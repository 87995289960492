import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Icon, Divider, Button, Modal, Segment } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import moment from "moment";

interface CardManageAppointmentsProps {
  contentPayload?: any;
  setProps?: any;
  history?: any;
  onEvent?: any;
  remindInComing?: boolean;
}

const useStyles = makeStyles((theme) => ({
  modalHeader: {
    color: "#1AB3CB",
    fontSize: "18",
    fontWeight: "bold",
  },
}));

const CardManageAppointments = (props: CardManageAppointmentsProps) => {
  const classes = useStyles();
  const [openManageAppointments, setOpenManageAppointments] =
    useState<boolean>(false);
  const [openConfirmAppointments, setOpenConfirmAppointments] =
    useState<boolean>(false);
  const [openCancelAppointments, setOpenCancelAppointments] =
    useState<boolean>(false);
  const [selectContentPayload, setSelectContentPayload] = useState<any>();

  let date = moment(props.contentPayload?.appointment_datetime);
  let year = parseInt(date.format("YYYY")) + 543;

  const onSelectContentPayload = () => {
    setOpenManageAppointments(true);
    setSelectContentPayload(props.contentPayload);
  };

  const onConfirmAppointment = () => {
    props.onEvent({
      message: "HandleConfirmAppointment",
      params: {
        id: selectContentPayload?.patient_appointment_id,
        selectedItem: {
          id: selectContentPayload?.patient_appointment_id,
          doctor_full_name: selectContentPayload?.doctor_name,
        },
      },
    });

    setOpenConfirmAppointments(false);
    setOpenManageAppointments(false);
  };

  const onCancelAppointment = () => {
    props.onEvent({
      message: "HandleCancelAppointment",
      params: {
        id: selectContentPayload?.patient_appointment_id,
      },
    });

    setOpenCancelAppointments(false);
    setOpenManageAppointments(false);
  };

  return (
    <div style={{ fontSize: "12px" }}>
      {props.remindInComing && (
        <div style={{
          fontSize: "12px",
          margin:"10px 0",
          minWidth: "max-content"
        }}>{"นัดหมายของท่านใกล้ถึงวันและเวลาที่นัดหมาย"}</div>
      )}
      <div
        style={{
          fontWeight: "bold",
        }}
      >
        {props.remindInComing
          ? "รายการนัดหมายที่ใกล้มาถึง"
          : "กรุณายืนยันนัดหมายเพื่อเข้าพบแพทย์"}
      </div>
      <div
        style={{
          fontWeight: "bold",
          color: "var(--primary-theme-color)",
          margin: "10px 0px",
        }}
      >
        {props.contentPayload?.doctor_name}
      </div>

      <div>{props.contentPayload?.division_name}</div>
      <div style={{ display: "flex", ...(props.remindInComing && {marginBottom:"10px"}) }}>
        <div className="card-appointment-detail">
          <Icon
            circular
            name="calendar alternate outline"
            size="small"
            style={{
              background: "#E3F3F9",
            }}
          />
          <div style={{ fontSize: "12px" }}>{`${date.format(
            "DD/MM"
          )}/${year}`}</div>
        </div>
        <div className="card-appointment-detail">
          <Icon
            circular
            name="clock outline"
            size="small"
            style={{
              background: "#E3F3F9",
            }}
          />
          <div style={{ fontSize: "12px" }}>{date.format("HH:mm")}</div>
        </div>
        <div
          className="card-appointment-telemed"
          style={{
            background: "#96CF9E",
            marginRight: "10px",
          }}
        >
          <Icon name="hospital outline" />
          <div>{"OPD"}</div>
        </div>
      </div>
      {!props.remindInComing && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            className="content-common-button"
            onClick={() => onSelectContentPayload()}
            style={{
              background: "#1AB3CB",
              margin: "15px 0px",
              width: "80%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "0px 20px",
              }}
            >
              {"จัดการนัดหมาย"}
            </div>
          </div>
        </div>
      )}

      <Modal
        open={openManageAppointments}
        onClose={() => setOpenManageAppointments(false)}
        size="mini"
      >
        <Segment padded align="center" className="modContent">
          <div>
            <div className={classes.modalHeader}>
              {"ท่านต้องการยืนยันนัดหมายใช่หรือไม่ ?"}
            </div>
            <div style={{ whiteSpace: "pre-line", margin: "30px 0px" }}>
              {`วันที่ ${date.format("DD MMMM")} ${year}  ${date.format(
                "HH:mm"
              )} \n${selectContentPayload?.division_name}`}
            </div>
          </div>
          <div style={{ marginTop: 20 }}>
            <div style={{ marginTop: 10 }}>
              <Button
                style={{
                  background: "#96CF9E",
                  color: "white",
                  borderRadius: "20px",
                  width: "60%",
                }}
                onClick={() => setOpenConfirmAppointments(true)}
              >
                <div>
                  <FormattedMessage id="common.approve" />
                </div>
              </Button>
            </div>
            <div style={{ marginTop: 10 }}>
              <Button
                style={{
                  background: "#25A7B9",
                  color: "white",
                  borderRadius: "20px",
                  width: "60%",
                }}
                onClick={() => {
                  setOpenManageAppointments(false);
                  props.history.push(
                    `/editAppointmentIH/${selectContentPayload?.patient_appointment_id}`
                  );
                }}
              >
                <div style={{ margin: "0px 20px" }}>
                  <FormattedMessage id="appoint.postpone" />
                </div>
              </Button>
            </div>
            <div style={{ marginTop: 10 }}>
              <Button
                style={{
                  background: "#F69E97",
                  color: "white",
                  borderRadius: "20px",
                  width: "60%",
                }}
                onClick={() => setOpenCancelAppointments(true)}
              >
                <div>
                  <FormattedMessage id="appoint.cancel" />
                </div>
              </Button>
            </div>
          </div>
        </Segment>
      </Modal>

      <Modal open={openConfirmAppointments} size="mini">
        <Segment padded align="center" className="modContent">
          <div>
            <div className={classes.modalHeader}>
              {"ท่านต้องการยืนยันนัดหมายใช่หรือไม่ ?"}
            </div>
            <div style={{ whiteSpace: "pre-line", margin: "30px 0px" }}>
              {`วันที่ ${date.format("DD MMMM")} ${year}  ${date.format(
                "HH:mm"
              )} \n${selectContentPayload?.division_name}`}
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ marginTop: 20 }}>
              <Button
                style={{
                  background: "#8BB6C6",
                  color: "white",
                  borderRadius: "20px",
                }}
                onClick={() => setOpenConfirmAppointments(false)}
              >
                <div style={{ margin: "0px 20px" }}>
                  <FormattedMessage id="common.cancel" />
                </div>
              </Button>
              <Button
                style={{
                  background: "#1ABDD4",
                  color: "white",
                  borderRadius: "20px",
                }}
                onClick={() => onConfirmAppointment()}
              >
                <div style={{ margin: "0px 20px" }}>
                  <FormattedMessage id="common.confirm" />
                </div>
              </Button>
            </div>
          </div>
        </Segment>
      </Modal>

      <Modal open={openCancelAppointments} size="mini">
        <Segment padded align="center" className="modContent">
          <div>
            <div className={classes.modalHeader}>
              {"ท่านต้องการยกเลิกนัดหมายนี้หรือไม่"}
            </div>
            <div style={{ whiteSpace: "pre-line", margin: "40px 0px" }}>
              {"หากท่านยกเลิกแล้ว \nจะต้องทำนัดหมายใหม่"}
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ marginTop: 20 }}>
              <Button
                style={{
                  background: "#8BB6C6",
                  color: "white",
                  borderRadius: "20px",
                }}
                onClick={() => setOpenCancelAppointments(false)}
              >
                <div style={{ margin: "0px 20px" }}>
                  <FormattedMessage id="common.cancel" />
                </div>
              </Button>
              <Button
                style={{
                  background: "#1ABDD4",
                  color: "white",
                  borderRadius: "20px",
                }}
                onClick={() => onCancelAppointment()}
              >
                <div style={{ margin: "0px 20px" }}>
                  <FormattedMessage id="common.confirm" />
                </div>
              </Button>
            </div>
          </div>
        </Segment>
      </Modal>
    </div>
  );
};

export default CardManageAppointments;
