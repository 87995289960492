import {
  ColumnProperties,
  ContentColumns,
  ContentStack,
  TDocumentDefinitions,
} from "pdfmake/interfaces";

import { getLogoReportNResize, getWidth } from "./CommonInterface";

import CONFIG from "config/config";

// Types
type ComponyLogoFormProps = {
  font: FontType;
  form: string;
  height: number;
  isHorizontal?: boolean;
  type?: number;
};

export type FontType = "KanitLM" | "THSarabunNew";

type ReturnType = {
  addressWidth: number;
  fontSizes: Record<number, number>;
  lineHeights: Record<number, number>;
} & ColumnProperties &
  ContentColumns &
  Pick<TDocumentDefinitions, "images">;

const COMPANY_LOGO: Partial<
  Record<
    string,
    { address: { horizontal: ContentStack; vertical: ContentStack }; marginTop?: number }
  >
> = {
  CU: {
    address: {
      horizontal: {
        stack: [
          { bold: true, lineHeight: 0.8, text: "โรงพยาบาลคณะทันตแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย" },
          { bold: true, text: "Chulalongkorn University Faculty of Dentistry Hospital" },
        ],
      },
      vertical: {
        stack: [
          { bold: true, lineHeight: 0.8, text: "โรงพยาบาลคณะทันตแพทยศาสตร์" },
          { bold: true, text: "จุฬาลงกรณ์มหาวิทยาลัย" },
          { lineHeight: 0.8, text: "ถนนอังรีดูนังต์ ปทุมวัน กรุงเทพฯ 10330" },
          { text: "โทร 02-218-8705" },
        ],
      },
    },
  },
};

const generateAdjustedValues = (
  start: number,
  end: number,
  step: number,
  fontOffset: number
): Record<number, number> => {
  const length = Math.floor((end - start) / step) + 1;
  const entries = Array.from({ length }, (_, i) => {
    const value = Number.parseFloat((start + i * step).toFixed(2));
    const adjustedValue = Number.parseFloat((value * fontOffset).toFixed(2));

    return [value, adjustedValue] as [number, number];
  });

  return Object.fromEntries(entries);
};

const generateFontSize = (fontType: FontType): Record<number, number> => {
  const fontOffset = fontType === "KanitLM" ? 0.75 : 1;

  return generateAdjustedValues(0, 30, 0.05, fontOffset);
};

const generateLineHeight = (fontType: FontType): Record<number, number> => {
  const fontOffset = fontType === "KanitLM" ? 1.125 : 1;

  return generateAdjustedValues(0, 3, 0.05, fontOffset);
};

const ComponyLogoForm = async (props: ComponyLogoFormProps): Promise<ReturnType> => {
  const type = props.type || 1;

  const companyLogo = COMPANY_LOGO[CONFIG.COMPANY || ""];
  const logoHeight = props.height;

  const logo = await getLogoReportNResize(logoHeight, type, props.form);

  // 140: Address width
  const fontSizes = generateFontSize(props.font);
  const lineHeights = generateLineHeight(props.font);
  const align = props.isHorizontal ? "horizontal" : "vertical";
  const stack = companyLogo?.address ? companyLogo.address[align].stack : [{ text: "" }];
  const fontSize = Object.keys(fontSizes).find((size) => {
    const height = stack.length * (Number(size) + 2);
    const difference = Math.abs(logoHeight - height);

    return difference >= 0 && difference <= 1;
  });
  const textWidths = stack.map((data: any) => getWidth(data.text, Number(fontSize)));
  const addressWidth = Math.max(...textWidths) + 5;

  const width = addressWidth + logo.width;

  return {
    addressWidth,
    columns: CONFIG.HIDE_COMPANY_LOGO_PRINT
      ? []
      : [
          {
            height: logoHeight,
            image: "logo",
            marginTop: -1.5,
            width: logo.width,
          },
          companyLogo?.address
            ? { ...companyLogo.address[align], fontSize: Number(fontSize) }
            : { text: "" },
        ],
    font: props.font,
    fontSizes,
    images: {
      logo: logo.src ? `${origin}${logo.src}` : "",
    },
    lineHeights,
    width,
  };
};

export default ComponyLogoForm;
