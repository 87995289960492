import React, { useState, useRef, useEffect, CSSProperties } from "react";
import {
  Menu,
  Icon,
  List,
  Search,
  Image,
  Header,
  Input,
  Loader,
  Dimmer,
  Button,
  Label,
  Divider,
} from "semantic-ui-react";
import Cookies from "js-cookie";
import "react-lib/apps/IsHealth/IHMobile/ishealth.scss";
const COLOR = {
  primary: "var(--primary-theme-color)",
};

const styles = {
  fixSize: {
    width: "50px",
    height: "50px",
  } as CSSProperties,
};

type ChartListProps = {
  onEvent: (e: any) => any;
  apiToken?: any;
  chatController: any;
  userId?: any;
  channelList?: any;
  loadChannelList: () => any;
  unfollowChannelList: any;
  followDivisionProfile: (item: any) => any;
  isLoading: boolean;
  menuSelected: any;
  onChannelSelected: (
    id: any,
    division: any,
    channel_Name: any,
  ) => any;
  keyWordSearch?: any;
  setProp: any;
  chatUnread?: number;
};

export const ChartList = React.forwardRef((props: ChartListProps, ref) => {
  const isMounted = useRef(true);
  const inputRef = useRef();
  const [menuSelect, setMenuSelect] = useState<string>("");
  const [isload, setIsLoad] = useState<boolean>(false);
  const [chatList, setChatList] = useState<any[]>([]);
  const [isLoadUnfollow, setIsLoadUnfollow] = useState<boolean>(false);

  useEffect(() => {
    if (props.chatController && props.apiToken && props.userId) {
      props.loadChannelList();
    }
  }, [props.chatController, props.apiToken, props.userId]);

  useEffect(() => {
    setMenuSelect(props.menuSelected);
    if (props.channelList?.length !== 0) {
      setChatList(props.channelList);
    } else {
      setChatList([]);
    }
  }, [props.menuSelected, props.channelList]);

  useEffect(() => {
    if (props.channelList?.length === 0) {
      setIsLoad(props.isLoading);
    } else {
      setIsLoad(false);
    }
  }, [props.isLoading]);

  useEffect(() => {
    if (isLoadUnfollow) {
      setIsLoadUnfollow(false);
    }
  }, [props.unfollowChannelList]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleChannelSelected = (item: any, index: any) => {
    console.log("saika ~ handleChannelSelected ")
    if (item.unread_message_count !== 0) {
      var tmpList = [...chatList];
      tmpList[index].unread_message_count = 0;
      setChatList(tmpList);
    }
    props.setProp("chatUnread",((props.chatUnread || 0) -item.unread_message_count))
    const name = item.division_name || item.name;
    props.onChannelSelected(item.id, item.division, name);
  };

  const getIcon = (feature: any) => {
    let iconName = "/images/Feed/my_bplus.png";

    // console.log(" getIcon: ", feature);

    switch (feature) {
      case CONFIG.DM_FEATURE:
        iconName = "/dm.png";
        break;
      case CONFIG.MOM_FEATURE:
        iconName = "/mom.png";
        break;
      case CONFIG.CHILD_FEATURE:
        iconName = "/child.png";
        break;
      default:
        break;
    }
    return iconName;
  };

  const createChannelList = () => {
    if (!props.userId && !Cookies.get("userId")) {
      return (
        <div className="no-user-id">
          <h2>Error: No User Id</h2>
        </div>
      );
    }
    return (
      <>
        {(
          chatList || []
        )
          .filter(
            (item: any) =>
              item.division_name
                .toLocaleLowerCase()
                .search(props.keyWordSearch.toLocaleLowerCase()) !== -1
          )
          .map((item: any, index: any) => {
            let iconName = "";
            let isFeature = false;
            let unread_noti = "none";
            // TODO: change to feature
            // console.log(" getIcon Map ", item)
            // if (
            //   item.division_feature === CONFIG.DM_FEATURE ||
            //   item.division_feature === CONFIG.MOM_FEATURE ||
            //   item.division_feature === CONFIG.CHILD_FEATURE
            // ) {
            //   isFeature = true;
            //   iconName = getIcon(item.division_feature);
            // }

            return (
                <div className="chat-list-items" onClick={() => handleChannelSelected(item,index)} key={index}>
                  <div className="chat-list-items-image">
                    <Image
                      src={
                        iconName
                          ? iconName
                          : "/images/ishealth/bottom_bar/appointment.png"
                      }
                      style={{ width: "25px" }}
                    />
                  </div>
                  <div className="chat-list-items-name">
                    <div style={{ paddingLeft: "30px" }}>
                      {item.division_name || item.name}
                    </div>
                    {item.unread_message_count > 0 && (
                      <div>{item.unread_message_count}</div>
                    )}
                  </div>
                </div>
            );
          })}
      </>
    );
  };

  const createunfollowChannelList = () => {
    return (
      <>
        {(
          props.unfollowChannelList || []
        )
          .filter(
            (item: any) =>
              item.division_name
                .toLocaleLowerCase()
                .search(props.keyWordSearch.toLocaleLowerCase()) !== -1
          )
          .map((item: any, index: any) => {
            let iconName = "";
            let isFeature = false;
            // TODO: change to feature
            // console.log(" getIcon Map ", item)
            // console.log("saika ;) item ",item)
            // console.log("saika ;) item.division_feature ",item.division_feature," : " ,CONFIG.DM_FEATURE)
            // if (
            //   item.feature === CONFIG.DM_FEATURE ||
            //   item.feature === CONFIG.MOM_FEATURE ||
            //   item.feature === CONFIG.CHILD_FEATURE
            // ) {
            //   isFeature = true;
            //   iconName = getIcon(item.feature);
            // }

            return (
              <Menu.Item
                key={index}
                onClick={() => console.log("can't load chart  unfollow ")}
                active={menuSelect === item.id}
              >
                <div className="chat-list-items">
                  <div className="chat-list-items-image">
                    <Image
                      src={
                        iconName
                          ? iconName
                          : "/images/ishealth/bottom_bar/appointment.png"
                      }
                      style={{ width: "25px" }}
                    />
                  </div>
                  <div className="chat-list-items-name">
                    <div style={{ paddingLeft: "30px" }}>
                      {item.division_name || item.name}
                    </div>
                    {item.unread_message_count > 0 && (
                      <div>{item.unread_message_count}</div>
                    )}
                  </div>
                </div>
              </Menu.Item>
            );
          })}
      </>
    );
  };

  console.log("Chats props:", props);

  return (
    <div className="chat-page" style={{ overflowY: "auto" }}>
      <Dimmer.Dimmable>
        <Dimmer
          data-testid="chat-list-loader"
          active={isload || isLoadUnfollow}
          inverted
        >
          <Loader inverted>Loading...</Loader>
        </Dimmer>
        <div>
          <Menu.Menu className="chat">{createChannelList()}</Menu.Menu>
          <Menu.Menu className="chat">{createunfollowChannelList()}</Menu.Menu>
        </div>
      </Dimmer.Dimmable>
    </div>
  );
});
ChartList.displayName = "ChartList";
export default ChartList;
