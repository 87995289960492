import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import {
  Header,
  Icon,
  Segment,
  Dimmer,
  Loader,
  Button,
  Label,
  Form
} from "semantic-ui-react";
import ModAddExpense from "./ModAddExpense";
import { ErrorMessage } from "../../common";
import { convertToBEDate } from "../../../utils/tsUtils";
import moment from "moment";

export const INVOICE_ITEM_STATUS = {
  BILLED: 1,
  PAID: 2,
  CANCELED: 3,
  REPLACED: 4,
  ADJUSTED: 5
};
interface Props {
  encounterData?: {
    triage_level?: string | number;
  };
  patientData?: {
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    hn?: string;
    age?: string | number;
    birthdate?: string;
    phone_no?: string | number;
    email?: string;
  };
  controller: {};
  triageLevelList?: any[];
  loading?: boolean;
  apiToken?: string;
  division?: string | number;
  readOnly?: boolean;
  onRefetchChat?: () => {};
}

const CardBILPatientPanel = React.forwardRef((props: Props, ref) => {
  const [classify, setClassify] = useState<string | number>("");
  const [openModAddItem, setOpenModAddItem] = useState(false);
  const [expenseList, setExpenseList] = useState([]);
  const [selectedItem, setSelectedItem] = useState<{
    id?: number;
    product?: number | string;
    price?: number | string;
  }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [notPaidArr, setNotPaidArr] = useState([]);
  const [paymentError, setPaymentError] = useState(null);
  const [shippingAddress, setShippingAddress] = useState({});
  const modAddExpenseRef = useRef();
  const isMounted = useRef(true);

  useImperativeHandle(ref, () => ({
    getShippingAddress: ({ shippingAddressId, encounterId, patientId }) => {
      if (
        encounterId === props.encounterData.id ||
        patientId === props.patientData.id
      ) {
        getShippingAddress();
      }
    }
  }));

  useEffect(() => {
    if (!openModAddItem) {
      setSelectedItem({});
    }
  }, [openModAddItem]);

  useEffect(() => {
    handleGetInvoiceItem();
  }, [
    props.division,
    Cookies.get("division_id"),
    props.encounterData.id,
    props.patientData.id
  ]);

  useEffect(() => {
    if (props.encounterData.triage_level && props.triageLevelList.length > 0) {
      let item = props.triageLevelList.find(
        item => item.value === props.encounterData.triage_level
      );
      if (item) {
        setClassify(item.text);
      } else {
        setClassify(props.encounterData.triage_level);
      }
    } else {
      setClassify(props.encounterData.triage_level);
    }
  }, [
    props.triageLevelList,
    props.encounterData && props.encounterData.triage_level
  ]);

  React.useEffect(() => {
    getShippingAddress();
  }, [
    props.encounterData && props.encounterData.id,
    props.encounterData && props.encounterData.patient
  ]);

  const handleOpenModAddItem = () => {
    setOpenModAddItem(true);
  };

  const handleCloseModAddItem = () => {
    setOpenModAddItem(false);
    if (modAddExpenseRef.current) {
      setSelectedItem({});
      modAddExpenseRef.current.clear();
    }
  };

  const handleDeleteItem = ({ id }: { id: number }) => {
    let index = expenseList.findIndex(item => item.id === id);
    let newExpenseList = [...expenseList];
    if (index > -1) {
      newExpenseList.splice(index, 1);
    }
    setExpenseList(newExpenseList);
    handleCloseModAddItem();
  };

  const createExpenseTableBody = () => {
    return expenseList.map((item, index) => {
      return (
        <tr key={index}>
          <td>{item.product}</td>
          <td>{item.price ? item.price : ""}</td>
          {!props.readOnly && (
            <td>
              <Icon name="pencil" onClick={() => handleEditItem({ item })} />
            </td>
          )}
        </tr>
      );
    });
  };

  const handleEditItem = ({ item }) => {
    handleOpenModAddItem();
    setSelectedItem(item);
  };

  const handleSaveItemSuccess = () => {
    handleCloseModAddItem();
    handleGetInvoiceItem();
  };

  const handleGetInvoiceItem = async () => {
    //! Will(Must) implement cancel Token :: CHERRY 14/07/2020 14.56
    setPaymentError(null);
    setIsLoading(true);
    const [res, err] = await props.controller.getInvoiceItemByItem({
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      encounterId: props.encounterData.id,
      patientId: props.patientData.id
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (res) {
        let activeItems = res.items.filter(
          item => item.status !== INVOICE_ITEM_STATUS.CANCELED
        );
        setExpenseList(activeItems);
        setNotPaidArr(
          activeItems.filter(item => item.status !== INVOICE_ITEM_STATUS.PAID)
        );
      } else {
        setPaymentError(err);
      }
    }
  };

  const handleSendInvoice = () => {
    handleCreateInvoice();
  };

  const handleCreateInvoice = async () => {
    setPaymentError(null);
    setIsLoading(true);
    const [res, err] = await props.controller.postInvoice({
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      items: expenseList.filter(item => !item.invoice).map(item => item.id),
      patientId: props.patientData.id,
      // type: 1,
      type: "FULL_PAYMENT",
      device: 1
      // encounterId: props.encounterData.id,
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (res) {
        console.log({ res });
        props.onRefetchChat();
      } else {
        setPaymentError(err);
      }
    }
  };

  useEffect(() => {
    if (paymentError) {
      setTimeout(() => {
        setPaymentError(null);
      }, 10000);
    }
  }, [paymentError]);

  const getShippingAddress = async () => {
    if (!props.encounterData.id) {
      return;
    }
    setIsLoading(true);
    const [res, err, network] = await props.controller.getShippingAddress({
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      encounterId: props.encounterData.id
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (res) {
        if (res.items.length > 0) {
          setShippingAddress(res.items[0]);
        } else {
          setShippingAddress({});
        }
      } else {
        setShippingAddress({});
      }
    }
  };

  const displayAddress = address => {
    if (!address) {
      return;
    }
    if (address.self_pickup) {
      return <div style={{ textAlign: "left" }}>รับยาที่โรงพยาบาล</div>;
    }
    let name = address.receiver_name ? `${address.receiver_name} ` : "";
    let no = address.no ? `${address.no} ` : "";
    let district = address.district_label ? `${address.district_label} ` : "";
    let city = address.city_label ? `${address.city_label} ` : "";
    let province = address.province_label ? `${address.province_label} ` : "";
    let zipcode = address.zipcode ? `${address.zipcode} ` : "";
    let note = address.note ? `${address.note} ` : "";
    let tel = address.receiver_tel ? `${address.receiver_tel} ` : "";
    let text = name + note + no + district + city + province + zipcode;
    if (!text) {
      // text = "กรุณาเลือกที่อยู่จัดส่ง"
    }
    return (
      <>
        <p style={{ textAlign: "left" }}>
          {text}<br/>
          {tel ? `เบอร์โทร: ${tel}` : ""}
        </p>
      </>
    );
  };

  return (
    <div className="PatientPanel">
      <ModAddExpense
        ref={modAddExpenseRef}
        open={openModAddItem}
        onClose={handleCloseModAddItem}
        onSaveSuccess={handleSaveItemSuccess}
        onDelete={handleDeleteItem}
        item={{
          name: selectedItem.product,
          id: selectedItem.id,
          price: selectedItem.price,
          order_id: selectedItem.order_id
        }} //! Beware, this is id of invoiceItem not id of product :: CHERRY 14/07/2020 14.52
        controller={props.controller}
        apiToken={props.apiToken}
        encounterData={props.encounterData}
        division={props.division}
      />
      <Dimmer.Dimmable>
        <Segment className="main-segment" textAlign="center">
          <div className="patient-info">
            <Header as="h5">สถานะ : {classify}</Header>

            <Icon name="user circle" inverted size="massive" />

            <Header.Subheader as="h4">
              {`${props.patientData.first_name} ${props.patientData.middle_name} ${props.patientData.last_name}`}
            </Header.Subheader>
            <Header.Subheader as="h5" className="left-align">
              HN : {props.patientData.hn}
            </Header.Subheader>
            <Header.Subheader as="h5" className="left-align">
              อายุ : {props.patientData.age}
            </Header.Subheader>
            <Header.Subheader as="h5" className="left-align">
              วันเกิด :{" "}
              {props.patientData.birthdate
                ? convertToBEDate({
                    date: moment(props.patientData.birthdate, [
                      "DD/MM/YYYY",
                      "YYYY-MM-DD"
                    ]).format("DD/MM/YYYY")
                  })
                : "-"}
            </Header.Subheader>
            <Header.Subheader as="h5" className="left-align">
              โทร : {props.patientData.phone_no}
            </Header.Subheader>
            {/* <Header.Subheader as="h5" className="left-align">
          Line ID : {props.patientData.line_id}
        </Header.Subheader> */}
            <Header.Subheader as="h5" className="left-align">
              E-mail : {props.patientData.email}
            </Header.Subheader>
          </div>
          <Segment className="expense-segment" loading={isLoading}>
            <div>
              <Header>Invoice สรุปข้อมูลแจ้งหนี้</Header>
              {notPaidArr.length === 0 && expenseList.length > 0 && (
                <Label color="green" size="medium" content="PAID" />
              )}
            </div>
            <Form>
              <ErrorMessage error={paymentError} />
            </Form>
            <div className="expense-table-wrapper">
              <table>
                <thead>
                  <tr>
                    <td>รายการค่าใช้จ่าย</td>
                    <td>ยอดชำระรวม (บาท)</td>
                    {!props.readOnly && (
                      <td>
                        <Icon name="plus" onClick={handleOpenModAddItem} />
                      </td>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {createExpenseTableBody()}
                  <tr>
                    <td>รวมชำระ (บาท)</td>
                    <td>
                      {expenseList
                        .reduce((a, b) => a + (parseFloat(b.price) || 0), 0)
                        .toFixed(2)}
                    </td>
                    {!props.readOnly && <td />}
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
            <br />
            {!props.readOnly && (
              <>
                <Button
                  className="upload-bil"
                  fluid
                  content="Upload เอกสารใบแจ้งหนี้"
                />
                <br />
                <Button
                  className="send-bil"
                  fluid
                  content="ส่งใบแจ้งหนี้ให้ผู้ป่วย"
                  onClick={handleSendInvoice}
                />
              </>
            )}
          </Segment>
          <Segment>
            <Header as="h3">ที่อยู่จัดส่ง</Header>
            {displayAddress(shippingAddress)}
          </Segment>
        </Segment>

        <Dimmer active={props.loading} inverted>
          <Loader inverted>Loading...</Loader>
        </Dimmer>
      </Dimmer.Dimmable>
    </div>
  );
});

CardBILPatientPanel.defaultProps = {
  apiToken: null,
  patientData: {},
  encounterData: {},
  division: null,
  loading: false,
  triageLevelList: [],
  readOnly: false,
  onRefetchChat: () => {},
};

CardBILPatientPanel.propTypes = {
  apiToken: PropTypes.string,
  patientData: PropTypes.object,
  encounterData: PropTypes.object,
  division: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  loading: PropTypes.bool,
  triageLevelList: PropTypes.array,
  readOnly: PropTypes.bool,
  onRefetchChat: PropTypes.func
};

export default React.memo(CardBILPatientPanel);
