import React, { useEffect, useState } from "react";
import { profileInfo } from "./TypeModal";
import Cookies from "js-cookie";
import MessageErrorCurvedDialog from "react-lib/apps/IsHealth/Common/MessageErrorCurvedDialog"

type MobHistoryProps = {
  noApiToken: Function;
  onEvent?: any;
  history: any;
  apiToken: any;
  profileInfo?: profileInfo;
  consentFinished?: boolean;
  setProp: any;
};

const MobHistory = (props: MobHistoryProps) => {

    const [openMessage, setOpenMessage] = useState<boolean>(false) 

  useEffect(() => {
    if (!props.apiToken && !Cookies.get("apiToken")) {
      console.log("saika ~MobMainIshealth noApiToken:");
      props.noApiToken();
    }
  }, []);

  const HandleOpenConsent = () => {
    props.setProp("uiState","CONSENT")
    props.onEvent({
        message: "HandleCheckConsent",
        params: { history: props.history},
    })
  }

  return (
    <div className="content-detail">
      <div style={{ padding: "30px" }}>
        
      </div>
        <MessageErrorCurvedDialog 
            open={!props.consentFinished} 
            textMessage={"โปรดอัพเดตข้อมูลขอความยินยอม เพื่อเข้าถึงประวัติการรักษา"}
            onClickEdit={() => HandleOpenConsent}
            showIconEdit={true}
        />
    </div>
  );
};

export default MobHistory;
