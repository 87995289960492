import WasmController from "react-lib/frameworks/WasmController";
import RejectedOrderList from "issara-sdk/apis/RejectedOrderList_core";
import EncounterDetail from "issara-sdk/apis/EncounterDetail_core";
import AdverseReactionList from "issara-sdk/apis/AdverseReactionList_apps_ADR";
import DrugOrderDetailForAction from "issara-sdk/apis/DrugOrderDetailForAction_apps_TPD";
import DrugOrderActionView from "issara-sdk/apis/DrugOrderActionView_apps_TPD";
import DrugOrderActionLogList from "issara-sdk/apis/DrugOrderActionLogList_apps_TPD";
// OPD DRUG
import DrugOPDOneDoseOrderView from "issara-sdk/apis/DrugOPDOneDoseOrderView_apps_TPDM";
import DrugOPDStatOrderView from "issara-sdk/apis/DrugOPDStatOrderView_apps_TPDM";
import DrugOPDHomeOrderView from "issara-sdk/apis/DrugOPDHomeOrderView_apps_TPDM";
// IPD DRUG
import DrugIPDOneDoseOrderView from "issara-sdk/apis/DrugIPDOneDoseOrderView_apps_TPDM";
import DrugIPDStatOrderView from "issara-sdk/apis/DrugIPDStatOrderView_apps_TPDM";
import DrugIPDHomeOrderView from "issara-sdk/apis/DrugIPDHomeOrderView_apps_TPDM";
import DrugOneDayOrderView from "issara-sdk/apis/DrugOneDayOrderView_apps_TPDM";
// OPERATING DRUG
import DrugOperatingOrderView from "issara-sdk/apis/DrugOperatingOrderView_apps_TPDM";

// Interface
import { State as MainState } from "../../../../../HIS/MainHISInterface";

export type State = {
  RejectOrderSequence?:
    | ({
        sequenceIndex?: string | null;
        orderType?: string | null;
        RejectOrderList?: any;
        drugOrderActionLog?: any;
      } & FilterType)
    | null;
};

type Picked = Pick<
  MainState,
  | "buttonLoadCheck"
  | "errorMessage"
  | "successMessage"
  | "drugOrder"
  | "patientADR"
  | "selectedEncounter"
  | "selectedEmr"
>;

export type FilterType = {
  selectedHN?: string | null;
  selectedFirstname?: string | null;
  selectedLastname?: string | null;
};

export const StateInitial: State = {
  RejectOrderSequence: {
    sequenceIndex: "",
    RejectOrderList: [],
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "SelectEncounter"; params: any };

export type Data = {
  division?: number;
};

const DrugAPI: { [key: string]: any } = {
  // OPD
  HOME_OPD: DrugOPDHomeOrderView,
  ONE_DOSE_OPD: DrugOPDOneDoseOrderView,
  STAT_OPD: DrugOPDStatOrderView,
  // IPD
  ONE_DOSE_IPD: DrugIPDOneDoseOrderView,
  STAT_IPD: DrugIPDStatOrderView,
  HOME_IPD: DrugIPDHomeOrderView,
  ONE_DAY_IPD: DrugOneDayOrderView,
  OPERATING: DrugOperatingOrderView,
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State & Picked, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.RejectOrderSequence) return;

  const rejectOrder = await RejectedOrderList.list({
    params: {
      hn: state.RejectOrderSequence?.selectedHN || "",
      firstname: state.RejectOrderSequence?.selectedFirstname || "",
      lastname: state.RejectOrderSequence?.selectedLastname || "",
    },
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
    },
  });

  if (rejectOrder[0]) {
    let rejectedData = rejectOrder[0]?.items;

    const promiseDrugArr = rejectedData.map(async (item: any) => {
      if (item.model_name === "DrugStatOrder") {
        const [drugOrderRes, drugOrderErr, drugOrderNet] =
          await DrugOPDStatOrderView.get({
            pk: item.order_id,
            apiToken: controller.apiToken,
            extra: {
              division: controller.data.division,
            },
          });

        return {
          ...item,
          need_approve: drugOrderRes?.need_approve,
        };
      } else if (item.model_name === "DrugOneDoseOrder") {
        const [drugOrderRes, drugOrderErr, drugOrderNet] =
          await DrugOPDOneDoseOrderView.get({
            pk: item.order_id,
            apiToken: controller.apiToken,
            extra: {
              division: controller.data.division,
            },
          });

        return {
          ...item,
          need_approve: drugOrderRes?.need_approve,
        };
      } else if (item.model_name === "DrugOPDHomeOrder") {
        const [drugOrderRes, drugOrderErr, drugOrderNet] =
          await DrugOPDHomeOrderView.get({
            pk: item.order_id,
            apiToken: controller.apiToken,
            extra: {
              division: controller.data.division,
            },
          });

        return {
          ...item,
          need_approve: drugOrderRes?.need_approve,
        };
      } else {
        console.warn(`${item.model_name} model_name not implement!`)
      }
    });

    const promiseRejectedData = await Promise.all(promiseDrugArr);

    controller.setState(
      {
        RejectOrderSequence: {
          sequenceIndex: "SearchAndAction",
          RejectOrderList: promiseRejectedData,
        },
      },
      () => SearchAndAction(controller, { action: "search", ...params })
    );
  }
};

export const SearchAndAction: Handler = async (controller, params) => {
  const state = controller.getState();

  if (params.action === "search") {
    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [params.card]: "LOADING" },
    });

    const rejectOrder = await RejectedOrderList.list({
      params: {
        hn: state.RejectOrderSequence?.selectedHN || "",
        firstname: state.RejectOrderSequence?.selectedFirstname || "",
        lastname: state.RejectOrderSequence?.selectedLastname || "",
      },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    if (rejectOrder[0]) {
      let rejectedData = rejectOrder[0]?.items;

      const promiseDrugArr = rejectedData.map(async (item: any) => {
        if (item.model_name === "DrugStatOrder") {
          const [drugOrderRes] = await DrugOPDStatOrderView.get({
            pk: item.order_id,
            apiToken: controller.apiToken,
            extra: {
              division: controller.data.division,
            },
          });

          return {
            ...item,
            need_approve: drugOrderRes?.need_approve,
          };
        } else if (item.model_name === "DrugOneDoseOrder") {
          const [drugOrderRes] = await DrugOPDOneDoseOrderView.get({
            pk: item.order_id,
            apiToken: controller.apiToken,
            extra: {
              division: controller.data.division,
            },
          });

          return {
            ...item,
            need_approve: drugOrderRes?.need_approve,
          };
        }
      });

      const promiseRejectedData = await Promise.all(promiseDrugArr);

      controller.setState({
        RejectOrderSequence: {
          ...state.RejectOrderSequence,
          RejectOrderList: promiseRejectedData,
        },
        buttonLoadCheck: { ...state.buttonLoadCheck, [params.card]: "SUCCESS" },
      });
    }
  } else if (params.action === "select_encounter") {
    const encounter = await EncounterDetail.retrieve({
      pk: params.item?.encounter,
      apiToken: controller.apiToken,
    });
    if (encounter[1]) {
      return controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: encounter[1] },
      });
    }
    controller.handleEvent({
      message: "SelectEncounter",
      params: {
        encounter: encounter[0],
        goToMenu: params.goToMenu,
        goToTab: params.goToTab,
      },
    });
  } else if (params.action === "select_drug") {
    const adr = await AdverseReactionList.list({
      params: {
        patient: params.item?.patient,
        exclude_cancel: true,
        severe_first: true,
      },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    const drug = await DrugOrderDetailForAction.retrieve({
      pk: params.item?.order_id,
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    let orderTypeName = `${drug?.[0]?.type}_${params.item.encounter_type}`;

    const orderResult = await DrugAPI[orderTypeName].get({
      pk: params.item?.order_id,
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    const orderType =
      drug?.[0]?.type === "ONE_DOSE" ||
      drug?.[0]?.type === "STAT" ||
      drug?.[0]?.type === "ONE_DAY"
        ? `${drug?.[0]?.type}_${params.item?.encounter_type}`
        : drug?.[0]?.type;
    controller.setState(
      {
        patientADR: adr?.[0],
        RejectOrderSequence: {
          ...state.RejectOrderSequence,
          orderType: orderType,
        },
        selectedEncounter: { id: params.item.encounter },
        drugOrder: orderResult?.[0],
      } as any
      // () => {
      //   controller.handleEvent({
      //     message: "SetDrugOrder" as any,
      //     params: {
      //       item: params.item,
      //       orderType: orderType,
      //       card: params.card,
      //     },
      //   });
      //   controller.handleEvent({
      //     message: "HandleRefreshEncounter" as any,
      //     params: { isOpen: false },
      //   });
      // }
    );
  } else if (params.action === "cancel") {
    const data = state?.drugOrder;
    delete data?.items;
    const [r, e, n] = await DrugOrderActionView.update({
      pk: state?.drugOrder?.id,
      data: {
        ...data,
        action: "CANCEL",
      },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });
    if (e) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: e },
      });
    } else {
      controller.setState(
        {
          errorMessage: { ...state.errorMessage, [params.card]: null },
          successMessage: { ...state.successMessage, [params.card]: r },
          RejectOrderSequence: {
            sequenceIndex: "START",
          },
        },
        () =>
          controller.handleEvent({
            message: "RunSequence" as any,
            params: {
              sequence: "RejectOrder" as any,
            },
          })
      );
    }
  } else if (params.action === "action_log") {
    const [actionLogRes, actionLogErr, actionLogNet] =
      await DrugOrderActionLogList.get({
        pk: params.item.order_id,
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
      });

    if (actionLogRes) {
      controller.setState({
        RejectOrderSequence: {
          ...state.RejectOrderSequence,
          drugOrderActionLog: actionLogRes.items,
        },
      });
    }
  }
};
