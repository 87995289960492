import React, { useState, useEffect, useImperativeHandle, useRef } from "react";
import { Header, Icon, Modal, Segment, Button, Form } from "semantic-ui-react";
import SearchBox from "../../common/SearchBox";
import Cookies from "js-cookie";
import ErrorMessages from "../../common/ErrorMessage";
import moment from "moment";
import * as Util from "../../../utils/tsUtils";
interface ModAddExpenseProps {
  encounterData?: {
    id: string | number;
    triage_level?: string | number;
  };
  division?: number | string;
  open?: boolean;
  onClose?: () => void;
  onSaveSuccess?: () => void;
  onDelete?: ({ id: number }) => void;
  item?: { id?: number; name?: string | number; price_total?: number };
  apiToken?: string;
}

const ModAddExpense = React.forwardRef((props: ModAddExpenseProps, ref) => {
  const [expenseType, setExpenseType] = useState<{ name?: string; id: number }>(
    {}
  );
  const [totalExpense, setTotalExpense] = useState<string | number>("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useRef(true);

  useImperativeHandle(ref, () => ({
    clear: () => clear()
  }));

  useEffect(() => {
    return () => (isMounted.current = false);
  }, []);

  useEffect(() => {
    if(props.item){
      setExpenseType(props.item);
    }
    if (props.item.price) {
      setTotalExpense(props.item.price);
    }
  }, [props.item]);

  const clear = () => {
    setTotalExpense("");
    setExpenseType("");
    setError(null)
  };

  const handleClose = () => {
    props.onClose();
  };

  const handleEdit = async () => {
    setError(null);
    setIsLoading(true);
    const [res, err] = await props.controller.patchMiscellaneousOrder({
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      orderId: props.item.order_id,
      data: {
          override_unit_price: totalExpense,
          charges_date_iso: new Date().toISOString().split("T")[0],
        }
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (res) {
        props.onSaveSuccess();
      } else {
        setError(err);
      }
    }
  }

  const handleSave = async () => {
    if(props.item.id){
      return handleEdit()
    }
    setError(null);
    setIsLoading(true);
    let item = {
      id: props.item.id,
      eligibility_type: "PATIENT_PAY",
      encounter: props.encounterData.id,
      quantity: 1,
      // charges_date: Util.convertToBEDate({
      //   date: moment().format("DD/MM/YYYY")
      // })
      override_unit_price: totalExpense,
      charges_date_iso: new Date().toISOString().split("T")[0],
    }
    if(!props.item.id){
      item.product = expenseType.id
    }
    const [res, err] = await props.controller.postMiscellaneousOrderListCreate({
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      division: props.division ? props.division : Cookies.get("division_id"),
      orderList: [item]
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (res) {
        props.onSaveSuccess();
      } else {
        setError(err);
      }
    }
  };

  const handleRemove = () => {
    // will be change after have API :: Cherry 13/07/2020 10.25 AM
    // props.onDelete({ id: props.item.id });
    handleDeleteMiscellaneousOrder()
  };

  const handleDeleteMiscellaneousOrder = async () => {
    setError(null);
    setIsLoading(true);
    const [res, err] = await props.controller.deleteMiscellaneousOrder({
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      orderId: props.item.order_id
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (res) {
        props.onSaveSuccess();
      } else {
        setError(err);
      }
    }
  }

  const handleTextChange = (e, { value }) => {
    setTotalExpense(value);
  };

  const handleSearchProduct = async ({ searchText } = {}) => {
    const [res, err] = await props.controller.getProduct({
      name: searchText,
      // code: searchText,
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken")
    });
    return [res, err];
  };

  const handleSelectType = async ({ item } = {}) => {
    setExpenseType(item);
  };

  const handleClearType = () => {
    setExpenseType({});
  };

  return (
    <Modal open={props.open} size="tiny" className="mod-add-expense">
      <Segment className="mod-header">
        <div className="mod-header-content">
          <Header>เพิ่ม/แก้ไขรายการค่าใช้จ่าย</Header>
          <div>
            <Icon name="close" size="large" onClick={handleClose} />
          </div>
        </div>
      </Segment>
      <Segment>
        <Form>
          <ErrorMessages error={error} />
          <Form.Group inline>
            <Form.Field width={4}>
              <label>หมวดค่าใช้จ่าย</label>
            </Form.Field>
            <Form.Field width={12}>
              <SearchBox
                readOnly={!!props.item.id}
                defaultOptions={[expenseType]}
                defaultId={expenseType.id}
                inputStyle={{ margin: 0 }}
                onGetSearchOptions={handleSearchProduct}
                textField="name"
                onSelectOption={handleSelectType}
                onClear={handleClearType}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group inline>
            <Form.Field width={4}>
              <label>ยอดรวม(บาท)</label>
            </Form.Field>
            <Form.Input
              width={12}
              type="number"
              onChange={handleTextChange}
              value={totalExpense}
            />
          </Form.Group>
          <Form.Group inline className="btn-group">
            <Form.Field width={props.item.id ? 3 : 5} />
            <Form.Field width={props.item.id ? 5 : 6}>
              <Button
                fluid
                type="button"
                className="save-btn"
                content="บันทึก"
                icon="pencil"
                onClick={handleSave}
                disabled={
                  Object.keys(expenseType).length === 0 ||
                  !totalExpense ||
                  isLoading
                }
                loading={isLoading}
              />
            </Form.Field>
            {props.item.id && (
              <Form.Field width={5}>
                <Button
                  fluid
                  type="button"
                  className="delete-btn"
                  icon="trash alternate"
                  content="ลบรายการ"
                  onClick={handleRemove}
                  disabled={isLoading}
                  loading={isLoading}
                />
              </Form.Field>
            )}
          </Form.Group>
        </Form>
      </Segment>
    </Modal>
  );
});

export default React.memo(ModAddExpense);
