import AddTablePreSedationEN from "./AddTablePreSedationEN";

export default function toPDFMakeData(props: any) {
  return {
    defaultStyle: {
      font: `THSarabunNew`,
    },
    pageSize: `A4`,
    content: [
      {
        width: `auto`,
        fontSize: `18`,
        decoration: ``,
        color: ``,
        alignment: `center`,
        pageBreak: ``,
        decorationStyle: ``,
        text: `Pre Procedural Sedation and Pre Anesthesia Informed Consent`,
        preserveLeadingSpaces: true,
        decorationColor: ``,
        bold: `true`,
      },
      {
        alignment: `left`,
        color: ``,
        decorationStyle: ``,
        fontSize: 15,
        pageBreak: ``,
        decoration: ``,
        decorationColor: ``,
        bold: false,
        width: `auto`,
        preserveLeadingSpaces: true,
        text: ` `,
      },
      {
        columns: [
          {
            decoration: ``,
            bold: false,
            pageBreak: ``,
            decorationColor: ``,
            alignment: `left`,
            fontSize: 15,
            text: ``,
            preserveLeadingSpaces: true,
            width: 30,
            decorationStyle: ``,
            color: ``,
          },
          {
            fontSize: 15,
            color: ``,
            decorationStyle: ``,
            bold: false,
            decoration: ``,
            alignment: `left`,
            text: `I authorize the monitoring of vital bodily functions and the administration of sedatives and anesthetics`,
            width: `auto`,
            pageBreak: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            width: `auto`,
            pageBreak: ``,
            alignment: `left`,
            bold: false,
            decorationStyle: ``,
            color: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            text: `under the direction of physician`,
          },
          {
            text: ` `,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationStyle: ``,
            decoration: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            width: 5,
            alignment: `left`,
            fontSize: 15,
            color: ``,
          },
          {
            stack: [
              {
                pageBreak: ``,
                decoration: ``,
                decorationStyle: ``,
                text: props.items?.formatDoctor,
                alignment: `center`,
                color: ``,
                fontSize: 15,
                bold: false,
                margin: [0, 0, 0, -15],
                preserveLeadingSpaces: true,
                width: `auto`,
                decorationColor: ``,
              },
              {
                alignment: `left`,
                fontSize: 15,
                margin: [0, 0, 0, 0],
                width: `auto`,
                decoration: ``,
                text: `........................................................................`,
                pageBreak: ``,
                decorationStyle: ``,
                decorationColor: ``,
                bold: false,
                preserveLeadingSpaces: true,
                color: ``,
              },
            ],
          },
          {
            preserveLeadingSpaces: true,
            alignment: `left`,
            decorationColor: ``,
            text: `or assigned another physician`,
            bold: false,
            decoration: ``,
            fontSize: 15,
            pageBreak: ``,
            color: ``,
            decorationStyle: ``,
            width: `auto`,
          },
          {
            text: ``,
            color: ``,
            bold: false,
            fontSize: 15,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            decoration: ``,
            width: 3,
            decorationColor: ``,
            decorationStyle: ``,
            pageBreak: ``,
          },
          {
            text: `member of`,
            width: `auto`,
            decoration: ``,
            decorationColor: ``,
            pageBreak: ``,
            alignment: `left`,
            decorationStyle: ``,
            bold: false,
            preserveLeadingSpaces: true,
            color: ``,
            fontSize: 15,
          },
        ],
      },
      {
        color: ``,
        bold: false,
        text: `${props.items?.hospital}.`,
        fontSize: 15,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        decorationColor: ``,
        pageBreak: ``,
        decoration: ``,
        width: `auto`,
        alignment: `left`,
      },
      {
        columns: [
          {
            pageBreak: ``,
            decoration: ``,
            fontSize: 15,
            decorationColor: ``,
            decorationStyle: ``,
            alignment: `left`,
            width: 30,
            bold: false,
            color: ``,
            text: ``,
            preserveLeadingSpaces: true,
          },
          {
            bold: false,
            width: `auto`,
            pageBreak: ``,
            fontSize: 15,
            decorationStyle: ``,
            decorationColor: ``,
            alignment: `left`,
            color: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            text: `I, `,
          },
          {
            fontSize: 15,
            decoration: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            text: ` `,
            width: 5,
            bold: false,
            decorationColor: ``,
            pageBreak: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            color: ``,
          },
          {
            stack: [
              {
                margin: [0, 0, 0, -15],
                alignment: `center`,
                pageBreak: ``,
                bold: false,
                color: ``,
                fontSize: 15,
                decorationStyle: ``,
                decoration: ``,
                preserveLeadingSpaces: true,
                text: props.items?.formatPatient,
                width: `auto`,
                decorationColor: ``,
              },
              {
                margin: [0, 0, 0, 0],
                width: `auto`,
                decorationStyle: ``,
                text: `.....................................................................,`,
                fontSize: 15,
                decoration: ``,
                alignment: `left`,
                color: ``,
                preserveLeadingSpaces: true,
                bold: false,
                decorationColor: ``,
                pageBreak: ``,
              },
            ],
            width: `auto`,
          },
          {
            text: ` `,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: `have been scheduled for`,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: `auto`,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            stack: [
              {
                fontSize: 15,
                decorationColor: ``,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
                alignment: `left`,
                text: props.items?.formatOperation,
                bold: false,
                width: `auto`,
                color: ``,
                pageBreak: ``,
                decoration: ``,
                decorationStyle: ``,
              },
              {
                alignment: `left`,
                text: `..............................................................................................................................................................................`,
                bold: false,
                pageBreak: ``,
                decorationStyle: ``,
                width: `auto`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
                decorationColor: ``,
                fontSize: 15,
                decoration: ``,
                color: ``,
              },
            ],
          },
          {
            color: ``,
            text: `Procedure/ surgery.`,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            bold: false,
            width: `auto`,
            pageBreak: ``,
            alignment: `left`,
          },
        ],
      },
      {
        width: `auto`,
        fontSize: 15,
        decorationStyle: ``,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        color: ``,
        alignment: `left`,
        pageBreak: ``,
        text: `It has been explained to me that all forms of anesthesia involve some risks of complications. Although rare, severe unexpected complications can occur with each type of anesthesia including infection, bleeding, cognitive dysfunction, tooth injury, drug reaction, blood clots, loss of sensation, loss of vision, loss of limb function, paralysis, awareness, stroke, brain damage, heart attack or death. I understand that these risks apply to ALL forms of anesthesia and that additional or specific risks of identified below will apply to me. I understand the consequences including the risks and benefits of alternatives of procedural sedation and postprocedure analgesia. It has been explained to me that sometime an anesthetic technique that involves the use of local anesthetic, may not succeed completely and therefore another technique may have to be used including general anesthesia.`,
        decoration: ``,
        bold: false,
      },
      {
        columns: [
          {
            decorationColor: ``,
            decoration: ``,
            decorationStyle: ``,
            pageBreak: ``,
            width: 30,
            alignment: `left`,
            text: ``,
            preserveLeadingSpaces: true,
            color: ``,
            fontSize: 15,
            bold: false,
          },
          {
            preserveLeadingSpaces: true,
            color: ``,
            fontSize: 15,
            decorationColor: ``,
            width: `auto`,
            pageBreak: ``,
            alignment: `left`,
            decorationStyle: ``,
            decoration: ``,
            bold: false,
            text: `I consent to the anesthetic techniques checked below and also consent to an alternative type of `,
          },
        ],
      },
      {
        decorationStyle: ``,
        alignment: `left`,
        text: `anesthesia, if necessary.`,
        color: ``,
        preserveLeadingSpaces: true,
        width: `auto`,
        bold: false,
        fontSize: 15,
        pageBreak: ``,
        decoration: ``,
        decorationColor: ``,
      },
      {
        text: ` `,
        fontSize: 15,
        width: `auto`,
        alignment: `left`,
        pageBreak: ``,
        decoration: ``,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        color: ``,
        bold: false,
        decorationColor: ``,
      },
      {
        decoration: ``,
        decorationStyle: ``,
        bold: `true`,
        color: ``,
        decorationColor: ``,
        fontSize: 15,
        pageBreak: ``,
        text: `Anesthetic Technique`,
        width: `auto`,
        alignment: `left`,
        preserveLeadingSpaces: true,
      },
      {
        columns: [
          {
            fontSize: 15,
            decorationColor: ``,
            width: `auto`,
            color: ``,
            decoration: ``,
            bold: false,
            alignment: `center`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            text: `[  ]`,
            pageBreak: ``,
          },
          {
            text: ``,
            bold: false,
            color: ``,
            pageBreak: ``,
            alignment: `left`,
            decorationStyle: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            width: 5,
            fontSize: 15,
            decoration: ``,
          },
          {
            width: `auto`,
            pageBreak: ``,
            alignment: `left`,
            color: ``,
            decorationColor: ``,
            bold: false,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            fontSize: 15,
            text: `General anesthesia  	`,
          },
          {
            decoration: ``,
            color: ``,
            fontSize: 15,
            decorationColor: ``,
            decorationStyle: ``,
            alignment: `left`,
            text: ``,
            preserveLeadingSpaces: true,
            width: 75,
            bold: false,
            pageBreak: ``,
          },
          {
            fontSize: 15,
            width: `auto`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            color: ``,
            alignment: `center`,
            text: `[  ]`,
            pageBreak: ``,
            decorationColor: ``,
            bold: false,
          },
          {
            width: 5,
            color: ``,
            pageBreak: ``,
            decorationColor: ``,
            alignment: `left`,
            decorationStyle: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            text: ``,
            decoration: ``,
            bold: false,
          },
          {
            pageBreak: ``,
            color: ``,
            alignment: `left`,
            decorationColor: ``,
            bold: false,
            text: `Spinal and/or Epidural anesthesia `,
            width: `auto`,
            fontSize: 15,
            decoration: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            width: 198,
            fontSize: 15,
            text: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationStyle: ``,
            bold: false,
            color: ``,
            pageBreak: ``,
          },
          {
            width: `auto`,
            fontSize: 15,
            alignment: `left`,
            decoration: ``,
            decorationColor: ``,
            decorationStyle: ``,
            bold: false,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            text: `(With or without sedation)`,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            text: `[  ]`,
            decorationColor: ``,
            bold: false,
            width: `auto`,
            color: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            pageBreak: ``,
            decorationStyle: ``,
            fontSize: 15,
          },
          {
            decorationStyle: ``,
            text: ``,
            pageBreak: ``,
            decoration: ``,
            color: ``,
            bold: false,
            alignment: `left`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            width: 5,
          },
          {
            fontSize: 15,
            text: `Major/Minor nerve block`,
            pageBreak: ``,
            bold: false,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            color: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            width: `auto`,
          },
          {
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            pageBreak: ``,
            text: ``,
            decoration: ``,
            fontSize: 15,
            width: 49,
            alignment: `left`,
            color: ``,
            bold: false,
          },
          {
            decorationStyle: ``,
            color: ``,
            bold: false,
            pageBreak: ``,
            decoration: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            text: `[  ]`,
            width: `auto`,
            decorationColor: ``,
            alignment: `left`,
          },
          {
            text: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            alignment: `left`,
            decorationStyle: ``,
            color: ``,
            fontSize: 15,
            bold: false,
            pageBreak: ``,
            width: 5,
          },
          {
            bold: false,
            preserveLeadingSpaces: true,
            decoration: ``,
            text: `Monitored anesthesia care`,
            pageBreak: ``,
            decorationStyle: ``,
            color: ``,
            alignment: `left`,
            width: `auto`,
            decorationColor: ``,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            width: 18,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            alignment: `left`,
            decoration: ``,
            bold: false,
            text: ``,
            pageBreak: ``,
            fontSize: 15,
            decorationColor: ``,
          },
          {
            alignment: `left`,
            decorationColor: ``,
            width: `auto`,
            color: ``,
            bold: false,
            pageBreak: ``,
            text: `(With or without sedation)`,
            preserveLeadingSpaces: true,
            decoration: ``,
            fontSize: 15,
            decorationStyle: ``,
          },
          {
            decoration: ``,
            alignment: `left`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            text: ``,
            width: 60,
            decorationStyle: ``,
            bold: false,
            color: ``,
            fontSize: 15,
            pageBreak: ``,
          },
          {
            width: `auto`,
            decorationColor: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            color: ``,
            decorationStyle: ``,
            text: `(With or without sedation)`,
            decoration: ``,
            pageBreak: ``,
            alignment: `left`,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            alignment: `center`,
            decorationStyle: ``,
            pageBreak: ``,
            decoration: ``,
            text: `[  ]`,
            width: `auto`,
            fontSize: 15,
            color: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decorationColor: ``,
          },
          {
            fontSize: 15,
            decorationColor: ``,
            color: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            pageBreak: ``,
            bold: false,
            width: 5,
            decoration: ``,
            text: ``,
          },
          {
            fontSize: 15,
            decorationColor: ``,
            text: `Sedation`,
            bold: false,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            width: `auto`,
            decoration: ``,
            pageBreak: ``,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            pageBreak: ``,
            decorationStyle: ``,
            text: ``,
            decorationColor: ``,
            color: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            width: 30,
            bold: false,
            decoration: ``,
          },
          {
            alignment: `left`,
            decorationColor: ``,
            color: ``,
            decorationStyle: ``,
            pageBreak: ``,
            decoration: ``,
            text: `I understand that may use of any type of illegal drug may give rise to serious complications and must be`,
            preserveLeadingSpaces: true,
            width: `auto`,
            bold: false,
            fontSize: 15,
          },
        ],
      },
      {
        alignment: `left`,
        preserveLeadingSpaces: true,
        fontSize: 15,
        decoration: ``,
        color: ``,
        decorationStyle: ``,
        decorationColor: ``,
        bold: false,
        text: `disclosed to my health care providers include my anesthesiologist. In the event that I am released to go home 24 hours of surgery. I will not drive myself home or use a public conveyance. A responsible adult will take me.`,
        width: `auto`,
        pageBreak: ``,
      },
      {
        columns: [
          {
            bold: false,
            decorationColor: ``,
            text: ``,
            width: 30,
            pageBreak: ``,
            alignment: `left`,
            color: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decoration: ``,
            fontSize: 15,
          },
          {
            decorationStyle: ``,
            alignment: `left`,
            fontSize: 15,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            color: ``,
            decoration: ``,
            width: `auto`,
            bold: false,
            pageBreak: ``,
            text: `I acknowledge that I have read this form or had it read to me, that I understand the risks, alternative and`,
          },
        ],
      },
      {
        bold: false,
        width: `auto`,
        alignment: `left`,
        fontSize: 15,
        color: ``,
        pageBreak: ``,
        decoration: ``,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        text: `expected results of the anesthesia service, that I had ample time to ask questions and the answers and`,
        decorationColor: ``,
      },
      {
        decoration: ``,
        preserveLeadingSpaces: true,
        decorationColor: ``,
        alignment: `left`,
        fontSize: 15,
        decorationStyle: ``,
        text: `additional information provided have met with my satisfaction and that I had ample time to consider my decisions.`,
        color: ``,
        width: `auto`,
        pageBreak: ``,
        bold: false,
      },
      {
        margin: [0, 0, 0, 0],
        decorationStyle: ``,
        alignment: `left`,
        decoration: ``,
        bold: false,
        pageBreak: `after`,
        decorationColor: ``,
        fontSize: 15,
        width: `auto`,
        text: ``,
        preserveLeadingSpaces: true,
        color: ``,
      },
      {
        columns: [
          {
            decorationColor: ``,
            width: 30,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationStyle: ``,
            alignment: `left`,
            color: ``,
            pageBreak: ``,
            fontSize: 15,
            bold: false,
            text: ``,
          },
          {
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            width: `auto`,
            pageBreak: ``,
            color: ``,
            text: `For women only:`,
            decorationColor: ``,
            bold: `true`,
            decoration: ``,
            fontSize: 15,
            alignment: `left`,
          },
          {
            fontSize: 15,
            color: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decoration: ``,
            bold: false,
            decorationColor: ``,
            text: ``,
            pageBreak: ``,
            width: 5,
          },
          {
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decoration: ``,
            pageBreak: ``,
            decorationStyle: ``,
            bold: false,
            width: `auto`,
            color: ``,
            alignment: `left`,
            text: ` If I am pregnant, I understand that elective surgery should be postponed until after`,
            fontSize: 15,
          },
        ],
      },
      {
        text: `the baby is born. Anesthetics penetrate the placenta and may temporarily anesthetize my baby. Although fetal complications of anesthesia during pregnancy are very rare, the risks to my baby include, but are not limited to birth defects, premature labor, permanent brain damage and death. If there is a possibility of me being pregnant, I have so informed the anesthesiologist.`,
        bold: false,
        preserveLeadingSpaces: true,
        decoration: ``,
        fontSize: 15,
        decorationStyle: ``,
        pageBreak: ``,
        decorationColor: ``,
        width: `auto`,
        alignment: `left`,
        color: ``,
      },
      {
        columns: [
          {
            bold: false,
            width: 30,
            fontSize: 15,
            color: ``,
            text: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            pageBreak: ``,
            alignment: `left`,
          },
          {
            width: `auto`,
            bold: false,
            decorationStyle: ``,
            fontSize: 15,
            decoration: ``,
            alignment: `left`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            color: ``,
            decorationColor: ``,
            text: `Additional or specific risks apply to my condition and my anesthesia service: ……..................……...……………...…`,
          },
        ],
      },
      {
        width: `auto`,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        text: `..................................................................................................................................................................................................................`,
        fontSize: 15,
        decorationColor: ``,
        color: ``,
        pageBreak: ``,
        decoration: ``,
        bold: false,
        alignment: `left`,
      },
      {
        columns: [
          {
            decoration: ``,
            decorationStyle: ``,
            text: ``,
            bold: false,
            width: 30,
            decorationColor: ``,
            pageBreak: ``,
            color: ``,
            fontSize: 15,
            alignment: `left`,
            preserveLeadingSpaces: true,
          },
          {
            decoration: ``,
            width: `auto`,
            pageBreak: ``,
            alignment: `left`,
            decorationColor: ``,
            bold: false,
            color: ``,
            fontSize: 15,
            text: `The above information was given to me by `,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
          },
          {
            decoration: ``,
            decorationStyle: ``,
            width: 5,
            decorationColor: ``,
            alignment: `left`,
            fontSize: 15,
            color: ``,
            pageBreak: ``,
            bold: false,
            preserveLeadingSpaces: true,
            text: ``,
          },
          {
            stack: [
              {
                text: props.items?.operatingData?.anes_name,
                decoration: ``,
                width: `auto`,
                pageBreak: ``,
                bold: false,
                color: ``,
                decorationColor: ``,
                alignment: `left`,
                decorationStyle: ``,
                margin: [0, 0, 0, -15],
                fontSize: 15,
                preserveLeadingSpaces: true,
              },
              {
                color: ``,
                pageBreak: ``,
                margin: [0, 0, 0, 0],
                bold: false,
                alignment: `left`,
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                width: `auto`,
                decoration: ``,
                text: `...................................................................................................................`,
                decorationColor: ``,
                fontSize: 15,
              },
            ],
          },
        ],
      },
      {
        text: ` `,
        bold: false,
        width: `auto`,
        alignment: `left`,
        decorationColor: ``,
        fontSize: 15,
        pageBreak: ``,
        decoration: ``,
        preserveLeadingSpaces: true,
        margin: [0, 0, 0, 0],
        decorationStyle: ``,
        color: ``,
      },
      {
        text: `I hereby AGREE to receive the treatment mentioned above.`,
        decorationStyle: ``,
        pageBreak: ``,
        color: ``,
        bold: false,
        alignment: `left`,
        preserveLeadingSpaces: true,
        fontSize: 15,
        width: `auto`,
        decoration: ``,
        decorationColor: ``,
      },
      {
        decoration: ``,
        pageBreak: ``,
        color: ``,
        width: `auto`,
        bold: false,
        decorationStyle: ``,
        text: ` `,
        fontSize: 15,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        alignment: `left`,
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            text: `Signature.................................................................`,
            fontSize: 15,
            width: 200,
            color: ``,
            decorationStyle: ``,
            alignment: `center`,
            bold: false,
            decoration: ``,
            pageBreak: ``,
            decorationColor: ``,
          },
          {
            alignment: `left`,
            fontSize: 15,
            decorationStyle: ``,
            width: 80,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decoration: ``,
            pageBreak: ``,
            bold: false,
            color: ``,
            text: `  `,
          },
          {
            bold: false,
            fontSize: 15,
            color: ``,
            width: 200,
            text: `Witness 1........................................................`,
            decorationStyle: ``,
            alignment: `center`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            decorationColor: ``,
            fontSize: 15,
            decoration: ``,
            text: ` `,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            color: ``,
            width: 24,
            bold: false,
          },
          {
            fontSize: 15,
            text: `(.............................................................)`,
            decorationStyle: ``,
            width: 200,
            preserveLeadingSpaces: true,
            decoration: ``,
            alignment: `center`,
            decorationColor: ``,
            pageBreak: ``,
            bold: false,
            color: ``,
          },
          {
            color: ``,
            pageBreak: ``,
            decoration: ``,
            width: 54,
            decorationStyle: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            text: ``,
            bold: false,
            alignment: `left`,
            fontSize: 15,
          },
          {
            decorationColor: ``,
            preserveLeadingSpaces: true,
            text: `(.............................................................)`,
            color: ``,
            width: 200,
            fontSize: 15,
            decorationStyle: ``,
            decoration: ``,
            bold: false,
            pageBreak: ``,
            alignment: `center`,
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            decoration: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decorationStyle: ``,
            fontSize: 15,
            color: ``,
            text: `Physician’s Signature........................................................ `,
            bold: false,
            width: 250,
          },
          {
            preserveLeadingSpaces: true,
            width: 30,
            alignment: `left`,
            bold: false,
            text: `  `,
            pageBreak: ``,
            fontSize: 15,
            color: ``,
            decorationColor: ``,
            decorationStyle: ``,
            decoration: ``,
          },
          {
            fontSize: 15,
            decorationStyle: ``,
            width: 200,
            alignment: `center`,
            color: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            text: `Witness 2........................................................`,
            pageBreak: ``,
            decoration: ``,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            text: `(.............................................................)`,
            fontSize: 15,
            bold: false,
            width: 250,
            preserveLeadingSpaces: true,
            color: ``,
            alignment: `center`,
            decorationStyle: ``,
            decoration: ``,
            decorationColor: ``,
          },
          {
            decoration: ``,
            fontSize: 15,
            decorationColor: ``,
            text: ``,
            color: ``,
            decorationStyle: ``,
            width: 30,
            alignment: `left`,
            preserveLeadingSpaces: true,
            bold: false,
            pageBreak: ``,
          },
          {
            fontSize: 15,
            decorationColor: ``,
            text: `(.............................................................)`,
            bold: false,
            decorationStyle: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            width: 200,
            alignment: `center`,
            color: ``,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            color: ``,
            width: 250,
            text: `(Physician provides explanation)`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            alignment: `center`,
            pageBreak: ``,
            bold: false,
            decorationStyle: ``,
          },
          {
            decorationColor: ``,
            width: 30,
            text: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            pageBreak: ``,
            color: ``,
            fontSize: 15,
            decoration: ``,
            bold: false,
            decorationStyle: ``,
          },
          {
            fontSize: 15,
            alignment: `center`,
            pageBreak: ``,
            bold: false,
            text: `(Fingerprint/consent over telephone)`,
            preserveLeadingSpaces: true,
            width: 200,
            decorationColor: ``,
            color: ``,
            decoration: ``,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            decoration: ``,
            pageBreak: ``,
            alignment: `center`,
            decorationColor: ``,
            width: 250,
            preserveLeadingSpaces: true,
            text: `Date........................Time..................`,
            bold: false,
            color: ``,
            fontSize: 15,
          },
        ],
      },
      {
        alignment: `left`,
        preserveLeadingSpaces: true,
        width: `auto`,
        bold: false,
        pageBreak: ``,
        text: ` `,
        decorationColor: ``,
        color: ``,
        fontSize: 15,
        decorationStyle: ``,
        decoration: ``,
      },
      {
        columns: [
          {
            text: `Interpreter’s Statement`,
            decoration: `underline`,
            bold: `true`,
            color: ``,
            fontSize: 15,
            width: `auto`,
            decorationStyle: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            pageBreak: ``,
          },
        ],
      },
      {
        width: `auto`,
        text: `I have given a translation of Pre Procedural Sedation and Pre Anesthesia Informed Consent including information that physician has explained to patient/patient’s representative.`,
        preserveLeadingSpaces: true,
        bold: false,
        decoration: ``,
        alignment: `left`,
        decorationStyle: ``,
        pageBreak: ``,
        decorationColor: ``,
        color: ``,
        fontSize: 15,
      },
      {
        preserveLeadingSpaces: true,
        decorationColor: ``,
        pageBreak: ``,
        decorationStyle: ``,
        decoration: ``,
        width: `auto`,
        color: ``,
        text: ` `,
        bold: false,
        alignment: `left`,
        fontSize: 15,
      },
      {
        columns: [
          {
            fontSize: 15,
            decoration: ``,
            width: 200,
            text: `Translate to Language..................................... `,
            alignment: `center`,
            color: ``,
            pageBreak: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decorationStyle: ``,
          },
          {
            bold: false,
            decorationColor: ``,
            color: ``,
            pageBreak: ``,
            text: ` `,
            width: 80,
            fontSize: 15,
            alignment: `left`,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
          },
          {
            decoration: ``,
            alignment: `center`,
            bold: false,
            fontSize: 15,
            text: `Interpreter........................................................`,
            width: 200,
            pageBreak: ``,
            decorationColor: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            decorationColor: ``,
            color: ``,
            fontSize: 15,
            pageBreak: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            alignment: `left`,
            width: 280,
            text: ``,
          },
          {
            decoration: ``,
            width: 200,
            alignment: `center`,
            decorationColor: ``,
            text: `(.............................................................)`,
            pageBreak: ``,
            decorationStyle: ``,
            bold: false,
            color: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        pageBreak: `before`,
        decorationColor: ``,
        bold: false,
        fontSize: 15,
        alignment: `left`,
        decoration: ``,
        color: ``,
        text: ` `,
        width: `auto`,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
      },
      AddTablePreSedationEN,
    ],
    pageOrientation: `portrait`,
  };
}
