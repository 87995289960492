import JsBarcode from "jsbarcode";

const textToBase64BarcodeBlob = (text: string) => {
  const canvas = document.createElement("canvas");
  JsBarcode(canvas, text, { displayValue: false });
  return canvas.toDataURL("image/png");
};

const FormPatientStickerSingle = (props: any) => {
  console.log("Form Patient Sticker Props: ", props);

  return {
    pageSize: {
      width: 141.75,
      height: 56.7,
    },
    pageOrientation: "landscape",
    defaultStyle: {
      font: "THSarabunNew",
      lineHeight: 1,
      fontSize: 12,
    },
    pageMargins: [8, 0, 8, 0],
    styles: {
      mainKey: {
        bold: true,
        fontSize: 14,
      },
    },
    content: [
      { text: props.items?.hn, style: "mainKey" },
      {
        columns: [
          { text: props.items?.full_name, bold: true, width: `auto` },
          { text: `${props.items?.age} Y`, alignment: "right" },
        ],
      },
      {
        columns: [
          {
            image: textToBase64BarcodeBlob(props.items?.hn || ""),
            width: 65,
            height: 15.07,
            margin: [-3, 2, 0, 0],
          },
          {
            text: `DOB: ${
              props.items?.birthdate.length > 0 ? props.items?.birthdate.replace(/\//g, "-") : " "
            }`,
            alignment: "right",
            margin: [0, 2, 0, 0],
          },
        ],
      },
    ],
  };
};

export default FormPatientStickerSingle;
