import BaseService from "./BaseService";
import { CORE } from "../../configs/apis";
import _ from "../../compat/lodashplus";
class CoreService extends BaseService {
  /**
   * get choices from a model field
   * @param {string} {model} a model name with a module prefix, e.g, INF.ARTransaction
   * @param {string} {field} a model field, e.g., status
   * @param {string} {withEmptyItem} if this is true, an empty item will be added to the result
   */
  getChoices({ model, field, withEmptyItem, params }:
    { model: any, field: any, withEmptyItem: any, params: any}) {
    return this.client
      .get(CORE.MODEL_CHOICE, { params: params ? params : { model, field } })
      .then(response => response.data)
      .catch(this.throwErrorMessage);
  }
  /**
   * get episode by id
   * @param {object} data
   */
  getEpisode(episodeId: any) {
    const url = CORE.EPISODE({ episode_id: episodeId });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * Create episode
   * @param {patient, episode_type, name, description} params
   */
  createEpisode(params: any) {
    return this.client
      .post(CORE.EPISODE_LIST, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * get episode list (search)
   * params: {patient, start_date, end_date, doctor_name_code, id, name}
   */
  getEpisodeList(params: any) {
    return this.client
      .get(CORE.EPISODE_LIST, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * get episode from encounterID
   */
  getEpisodeFromEN(encounterId: any) {
    var params = { encounter: encounterId };
    return this.client
      .get(CORE.EPISODE_LATEST, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * get encounter list from episodeID
   */
  getENListFromEpisode(episodeId: any) {
    var params = { episode: episodeId };
    return this.client
      .get(CORE.EPISODE_ENCOUNTER, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * get patient data by encounter
   * @param {object} data
   */
  getEncounter(id: any, apiToken: any) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    const url = CORE.ENCOUNTER({ encounter_id: id });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * Change episode of encounter
   * @param {encounter ID, episode ID} params
   */
  changeEncounterToEpisode(encounterId: any, episodeId: any) {
    const url = CORE.ENCOUNTER({ encounter_id: encounterId });
    var param: any = { action: "EDIT_EPISODE" };
    if (episodeId !== null) {
      param = {
        ...param,
        episode: episodeId
      };
    }
    return this.client
      .put(url, param)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * get progression cycle data by emr
   * @param {object} data
   */
  getProgressionCycle(params: any) {
    return this.client
      .get(CORE.PROGRESSION_CYCLE, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * get patient data by encounter
   * @param {object} data
   */
  getLastEncounterByPatient(id: any) {
    const url = CORE.LAST_PATIENT_ENCOUNTER({ patient_id: id });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  encounterGetEmrs(encounterId: any) {
    const url = CORE.ENCOUNTER_GET_EMRS({ encounter_id: encounterId });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * update encounter status
   * @param {object} data
   */
  updateEncounterStatus(id: any, data: any) {
    const url = CORE.UPDATE_ENCOUNTER_STATUS({ encounter_id: id });
    return this.client
      .put(url, data)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  putEncounterPatientArrive({ pk, data }: { pk?: any, data?: any} = {}) {
    const url = CORE.ENCOUNTER_PATIENT_ARRIVE_DETAIL({ pk });
    return this.client
      .put(url, data)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  /**
   * update encounter zone
   * @param {object} data
   */
  changeZone(id: any, data: any) {
    const url = CORE.CHANGE_ZONE({ encounter_id: id });
    return this.client
      .put(url, data)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * create buyer encounter from tpd
   * @param {patient: patientId}
   */
  createBuyerEncounter(patientId: any) {
    const url = CORE.BUYER_ENCOUNTER;
    return this.client
      .post(url, { patient: patientId })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * get last ipd med record
   * @param {object} data
   */
  getLastIpdMedicalRecord(patientId: any) {
    const url = CORE.LAST_IPD_MED_RECORD;
    let param: any = {
      patient: patientId,
      encounter_type: "IPD",
      active_only: true,
      lasted_only: true
    };
    return this.client
      .get(url, param )
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getSearchDoctor(nameCode: any) {
    const url = CORE.SEARCH_DOCTOR_OLD({ name_code: nameCode });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getSearchPatient(number: any) {
    const url = CORE.SEARCH_PATIENT_EN({ number: number });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getnationalities(params: any, apiToken: any) {
    // console.log(apiToken, "apiToken")
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(CORE.NATIONALITY)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getProcedure(params: any) {
    return this.client
      .get(CORE.PROCEDURE, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getSpeciality() {
    return this.client
      .get(CORE.SPECIALITY)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getDoctor(params: any) {
    return this.client
      .get(CORE.DOCTOR, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getClinicalTerm(params: any) {
    return this.client
      .get(CORE.CLINICAL_TERM, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getScannedDoc(params: any) {
    return this.client
      .get(CORE.SCANNED_DOCUMENT, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getDocumentCategory(params: any) {
    console.log("getDocumentCategory DOCUMENT_CATEGORY ")
    return this.client
      .get(CORE.DOCUMENT_CATEGORY, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getDocumentType(params: any) {
    return this.client
      .get(CORE.DOCUMENT_TYPE, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getDivision(params: any, apiToken: any) {
    // console.log(apiToken, "apiToken")
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(CORE.DIVISION, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getDivisionDetail({ divisionId, params, apiToken }: any = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(CORE.DIVISION_DETAIL({ divisionId }), { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getSecretEncounter(params: any) {
    return this.client
      .get(CORE.SECRET_ENCOUNTER, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postSecretEncounter(data: any, params: any) {
    return this.client
      .post(CORE.SECRET_ENCOUNTER, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postClinicalTermSet({ data, params }: any) {
    return this.client
      .post(CORE.CLINICAL_TERM_SET, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getBarcodePrefix() {
    return this.client
      .get(CORE.BARCODE_PREFIX_LIST)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getDefaultBarcode(barcode: any) {
    const url = CORE.DEFAULT_BARCODE_DETAIL({ barcode: barcode });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getDistrict({ apiToken, params }: any = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(CORE.DISTRICT, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getCity({ apiToken, params }: any = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(CORE.CITY, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getProvince({ apiToken }: any = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(CORE.PROVINCE)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getCountry({ apiToken }: any = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(CORE.COUNTRY)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getReligion({ apiToken }: any) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(CORE.RELIGION)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getEncounterStatus({ params }: any = {}) {
    return this.client
      .get(CORE.ENCOUNTER_STATUS, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  putEncounterDetail({ params, data, encounterId, apiToken }: any = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    let url = CORE.ENCOUNTER_DETAIL({ encounter_id: encounterId });
    return this.client
      .put(url, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getRejectedOrder() {
    return this.client
      .get(CORE.REJECTED_ORDER)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getCleaningChoice() {
    return this.client
      .get(CORE.CLEANING_CHOICE)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getCareer() {
    return this.client
      .get(CORE.CAREER)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getTaskTrackingPrintResult(params: any) {
    return this.client
      .get(CORE.TASK_TRACKING_RESULT, { params })
      .then(response => response.data)
      .catch(this.throwErrorMessage);
  }
  /**
   * get ICD10 list with ICD_CODE
   */
  getICD10ListWithCode(params: any) {
    return this.client
      .post(CORE.ICD10_ICDCODE, params)
      .then(response => response.data.response)
      .catch(this.throwErrorMessage);
  }
  getICD9CMListWithCode(params: any) {
    return this.client
      .post(CORE.ICD9CM_ICDCODE, params)
      .then(response => response.data.response)
      .catch(this.throwErrorMessage);
  }
  /**
   * get ICD10 list with ICD_TERM
   */
  getICD10ListWithTerm(params: any) {
    return this.client
      .post(CORE.ICD10_ICDTERM, params)
      .then(response => response.data.response)
      .catch(this.throwErrorMessage);
  }
  getICD9CMListWithTerm(params: any) {
    return this.client
      .post(CORE.ICD9CM_ICDTERM, params)
      .then(response => response.data.response)
      .catch(this.throwErrorMessage);
  }
  /**
   * get ICD10 list with MED_TERM
   */
  getICD10ListWithMedTerm(params: any) {
    return this.client
      .post(CORE.ICD10_MEDTERM, params)
      .then(response => response.data.response)
      .catch(this.throwErrorMessage);
  }
  getICD9CMListWithMedTerm(params: any) {
    return this.client
      .post(CORE.ICD9CM_MEDTERM, params)
      .then(response => response.data.response)
      .catch(this.throwErrorMessage);
  }

  getMiscellaneous(params: any, apiToken: any) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    return this.client
      .get(CORE.MISCELLANEOUS, { params })
      .then(this.handleResponse);
  }
  /**
   * get Sublevel list of ICD10 with ICD_CODE
   */
  getICD10SublevelListWithCode(params: any) {
    return this.client
      .post(CORE.ICD10_SUBLEVEL, params)
      .then(response => response.data.response)
      .catch(this.throwErrorMessage);
  }
  getICD9CMSublevelListWithCode(params: any) {
    return this.client
      .post(CORE.ICD9CM_SUBLEVEL, params)
      .then(response => response.data.response)
      .catch(this.throwErrorMessage);
  }
  postMiscellaneousOrderPreview({ params, data }: any = {}) {
    return this.client
      .post(CORE.MISCELLANEOUS_ORDER_PREVIEW, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getMiscellaneousOrder({ params }: any = {}) {
    return this.client
      .get(CORE.MISCELLANEOUS_ORDER, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getConstance({ params }: any= {}) {
    return this.client
      .get(CORE.CONSTANCE, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  deleteMiscellaneousOrder({ id, params, data, apiToken }: any = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    const url = CORE.MISCELLANEOUS_ORDER_DETAIL({ miscellaneous_order_id: id });
    return this.client
      .delete(url, data)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  patchMiscellaneousOrder({ id, params, data, apiToken }: any = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    const url = CORE.MISCELLANEOUS_ORDER_DETAIL({ miscellaneous_order_id: id });
    return this.client
      .patch(url, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  postMiscellaneousOrderCreate({ params, data }: any = {}) {
    return this.client
      .post(CORE.MISCELLANEOUS_ORDER_LIST_CREATE, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getCurrentDoctor(params: any) {
    return this.client
      .get(CORE.CURRENT_DOCTOR, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  deleteProgressionCycle({ data, params, progressionId }: any = {}) {
    const url = CORE.PROGRESSION_CYCLE_DETAIL({
      progression_cycle_id: progressionId
    });
    return this.client
      .delete(url, data)
      // .delete(url, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postProgressionCycle({ data, params, progressionId }: any = {}) {
    return this.client
      .post(CORE.PROGRESSION_CYCLE, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putProgressionCycle({ data, params, progressionId }: any = {}) {
    const url = CORE.PROGRESSION_CYCLE_DETAIL({
      progression_cycle_id: progressionId
    });
    return this.client
      .put(url, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getEncounterSearch({ params }: any = {}) {
    return this.client
      .get(CORE.ENCOUNTER_SEARCH, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getEncounterSearchDetail({ encounterId, params }: any = {}) {
    const url = CORE.ENCOUNTER_SEARCH_DETAIL({
      encounter_id: encounterId
    });
    return this.client
      .get(url, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putScannedDocumentUpdateSecret({ params, data }: any = {}) {
    return this.client
      .put(CORE.SCANNED_DOCUMENT_UPDATE_SECRET, data, { params })
      .then(response => response.data)
      .catch(this.throwErrorMessage);
  }
  patchScannedDocument({ params, data, scanDocId }: any = {}) {
    const url = CORE.SCANNED_DOCUMENT_DETAIL({
      scanned_doc_id: scanDocId
    });
    return this.client
      .patch(url, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postScannedDocument({ params, data }: any = {}) {
    return this.client
      .post(CORE.SCANNED_DOCUMENT, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getTaskTrackingResult({ params }: any = {}) {
    return this.client
      .get(CORE.TASK_TRACKING_RESULT, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getEncounterPatient({ params }: any = {}) {
    return this.client
      .get(CORE.ENCOUNTER_PATIENT, { params })
      .then(response => response.data)
      .catch(this.throwErrorMessage);
  }
  getDoctorOrderDetail({ params, id }: any = {}) {
    return this.client
      .get(CORE.DOCTOR_ORDER_DETAIL({ id }), { params })
      .then(response => response.data)
      .catch(this.throwErrorMessage);
  }
  getEncounterList({ params }: any = {}) {
    return this.client
      .get(CORE.ENCOUNTER_LIST, { params })
      .then(response => response.data)
      .catch(this.throwErrorMessage);
  }
  getEncounterPatientOptimized({ params }: any = {}) {
    return this.client
      .get(CORE.ENCOUNTER_PATIENT_OPTIMIZED, { params })
      .then(response => response.data)
      .catch(this.throwErrorMessage);
  }

  getProduct({ params, apiToken }: any = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    return this.client.get(CORE.PRODUCT, { params })
      .then((response) => response.data)
      .catch(this.throwErrorMessage);
  }

  postMiscellaneousOrderListCreate({ params, apiToken, data, division }: any = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    if (division) {
      this.client.defaults.headers["division"] = division
    }
    return this.client.post(CORE.MISCELLANEOUS_ORDER_LIST_CREATE, data, { params })
      .then((response) => response.data)
      .catch(this.throwErrorMessage);
  }

  postPatientAddress({ params, apiToken, data }: any = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    return this.client.post(CORE.PATIENT_ADDRESS, data, { params })
      .then((response) => response.data)
      .catch(this.throwErrorMessage);
  }

  getPatientAddressList({ params, apiToken }: any = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    return this.client.get(CORE.PATIENT_ADDRESS, { params })
      .then((response) => response.data)
      .catch(this.throwErrorMessage);
  }

  getPatientAddressDetail({ params, apiToken, patientAddressId }: any = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    return this.client.get(CORE.PATIENT_ADDRESS_DETAIL({ patientAddressId }), { params })
      .then((response) => response.data)
      .catch(this.throwErrorMessage);
  }

  putPatientAddressDetail({ params, apiToken, patientAddressId, data }: any = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    return this.client.put(CORE.PATIENT_ADDRESS_DETAIL({ patientAddressId }), data, { params })
      .then((response) => response.data)
      .catch(this.throwErrorMessage);
  }

  deletePatientAddressDetail({ params, apiToken, patientAddressId }: any = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    return this.client.delete(CORE.PATIENT_ADDRESS_DETAIL({ patientAddressId }), { params })
      .then((response) => response.data)
      .catch(this.throwErrorMessage);
  }

  getPatientDiagnosisList(params: any) {
    return this.client
      .get(CORE.PATIENT_DIAGNOSIS_LIST, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getListPreName({ params }: any) {
    return this.client.get(CORE.LIST_PRE_NAME, { params })
      .then((response) => response.data)
      .catch(this.throwErrorMessage);
  }
}
export default CoreService;