import getPdfMake from "react-lib/appcon/common/pdfMake";

export type ConsetFormHospitelIRCovid = {
  language?: string;
  patientName?: string;
  nationality?: string;
  room?: string;
  hn?: string;
  physician?: string;
  visitDate?: string;
  department?: string;
  birthDate?: string;
  age?: string;
  sex?: string;
  allergies?: string;
  sideEffect?: string;
  citizenNumber?: string;
  prename?: string;
  acknowledged1?: boolean;
  acknowledged2?: boolean;
  consent1?: boolean;
  refuse1?: boolean;
  consent2?: boolean;
  refuse2?: boolean;
  consent3?: boolean;
  refuse3?: boolean;
  consent4?: boolean;
  refuse4?: boolean;
  patientSignature?: string;
  patientSignDate?: string;
  patientSignTime?: string;
  witnessName1?: string;
  witnessSignature1?: string;
  witnessSignDate1?: string;
  witnessSignTime1?: string;
  witnessName2?: string;
  witnessSignature2?: string;
  witnessSignDate2?: string;
  witnessSignTime2?: string;
  assignDoctor?: string;
  assignDoctorSignature?: string;
  assignDoctorSignDate?: string;
  assignDoctorSignTime?: string;
  isNotLawful?: boolean;
  isDisabillity?: boolean;
  disabillityText?: string;
  isOtherReason?: boolean;
  otherReasonText?: string;
  patientAgent?: string;
  patientAgentSignature?: string;
  patientAgentSignDate?: string;
  patientAgentSignTime?: string;
  patientAgentRelative?: string;
  patientAgentCitizenNumber?: string;
  patientAgentCardAt?: string;
  patientAgentCardDate?: string;
  patientAgentCardExpire?: string;
  patientAgentAddress?: string;
};

export const ConsetFormHospitelIRCovidInitail = {
  language: "TH",
  patientName: "",
  nationality: "",
  room: "",
  hn: "",
  physician: "",
  visitDate: "",
  department: "",
  birthDate: "",
  age: "",
  sex: "",
  allergies: "",
  sideEffect: "",
  citizenNumber: "",
  prename: "",
  acknowledged1: false,
  acknowledged2: false,
  consent1: false,
  refuse1: false,
  consent2: false,
  refuse2: false,
  consent3: false,
  refuse3: false,
  consent4: false,
  refuse4: false,
  patientSignature: "",
  patientSignDate: "",
  patientSignTime: "",
  witnessName1: "",
  witnessSignature1: "",
  witnessSignDate1: "",
  witnessSignTime1: "",
  witnessName2: "",
  witnessSignature2: "",
  witnessSignDate2: "",
  witnessSignTime2: "",
  assignDoctor: "",
  assignDoctorSignature: "",
  assignDoctorSignDate: "",
  assignDoctorSignTime: "",
  isNotLawful: false,
  isDisabillity: false,
  disabillityText: "",
  isOtherReason: false,
  otherReasonText: "",
  patientAgent: "",
  patientAgentSignature: "",
  patientAgentSignDate: "",
  patientAgentSignTime: "",
  patientAgentRelative: "",
  patientAgentCitizenNumber: "",
  patientAgentCardAt: "",
  patientAgentCardDate: "",
  patientAgentCardExpire: "",
  patientAgentAddress: "",
};

export const conFormHospitelIRCovid = async (props) => {
  let pdfMake = await getPdfMake();

  props = { ...ConsetFormHospitelIRCovidInitail, ...props };
  // let make = await import("pdfmake/build/pdfmake");
  // let font = await import("../assets/fonts/pdfFonts")
  // let pdfMake = make.default
  // pdfMake.vfs = font.default
  // pdfMake.fonts = {
  //     THSarabunNew: {
  //         normal: 'THSarabunNew.ttf',
  //         bold: 'THSarabunNew-Bold.ttf',
  //         italics: 'THSarabunNew-Italic.ttf',
  //         bolditalics: 'THSarabunNew-BoldItalic.ttf'
  //     },
  //     Roboto: {
  //         normal: 'Roboto-Regular.ttf',
  //         bold: 'Roboto-Medium.ttf',
  //         italics: 'Roboto-Italic.ttf',
  //         bolditalics: 'Roboto-MediumItalic.ttf'
  //     }
  // }
  // pdfMake.vfs = pdfFonts;
  // pdfMake.fonts = {
  //   THSarabunNew: {
  //     normal: "THSarabunNew.ttf",
  //     bold: "THSarabunNew-Bold.ttf",
  //     italics: "THSarabunNew-Italic.ttf",
  //     bolditalics: "THSarabunNew-BoldItalic.ttf",
  //   },
  //   Roboto: {
  //     normal: "Roboto-Regular.ttf",
  //     bold: "Roboto-Medium.ttf",
  //     italics: "Roboto-Italic.ttf",
  //     bolditalics: "Roboto-MediumItalic.ttf",
  //   },
  // };

  var origin = window?.location?.origin;

  // playground requires you to assign document definition to a variable called dd

  var docDefinition = {
    info: {
      title: props.patientName,
      author: props.patientName,
      subject: "consent hopitel IR covid",
      keywords: "consent hopitel IR covid",
    },

    defaultStyle: {
      font: "THSarabunNew",
      // alignment: 'justify'
      lineHeight: 1,
      fontSize: 14,
    },
    footer: function (currentPage: number, pageCount: number) {
      return {
        columns: [
          {
            text:
              props.language === "TH"
                ? "F/M-03-BHQ-086 Rev.1 (11 Aug 2021)"
                : "F/M-03-BHQ-086 Rev.1 (11 Aug 2021)",
            alignment: "left",
            style: "fontFooterPage",
            margin: [40, 0, 10, 0],
          },
          {
            text: "Page " + currentPage.toString() + " / " + pageCount,
            alignment: "center",
            style: "fontFooterPage",
            margin: [0, 0, 0, 0],
          },
          {
            text: "Internal Use Only",
            alignment: "right",
            style: "fontFooterPage",
            margin: [0, 0, 40, 0],
          },
        ],
      };
    },
    pageMargins: [40, 120, 20, 20],
    // pageMargins: [20, 250, 20, 20],
    //A4: [595.28, 841.89]
    pageSize: "A4",
    styles: {
      facultyHeader: {
        fontSize: 16,
        bold: true,
        margin: [0, 0, 0, 0],
      },
      fontHeader: {
        fontSize: 16,
        bold: true,
      },
      fontHeaderTable: {
        fontSize: 14,
        bold: true,
      },
      fieldHeader: {
        bold: true,
        fontSize: 14,
      },
      fieldKey: {
        bold: true,
        fontSize: 13,
      },
      fieldValue: {
        fontSize: 13,
      },
      tableNormal: {
        margin: [0, 0, 0, 0],
      },
      tableWithOutBorder: {
        margin: [0, 0, 0, 0],
        border: [false, false, false, false],
      },
      tableHeader: {
        fillColor: "white",
      },
      fontDevelopTitle: {
        fontSize: 10,
      },
      fontDevelopName: {
        fontSize: 14,
        bold: true,
      },
      fontFooter: {
        fontSize: 12,
        bold: true,
      },
      fontRemark: {
        fontSize: 11,
        bold: true,
      },
      fontFooterPage: {
        fontSize: 12,
      },
      fontAnswer: {
        fontSize: 14,
        bold: true,
      },
      fontEllipsis: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "20px",
      },
    },
    header: {
      margin: [40, 10, 20, 0],
      stack: [
        {
          columns: [
            {
              margin: [0, 3, 0, 0],
              width: "40%",
              stack: [
                {
                  image: "logobkhpt",
                  width: 145,
                },
                {
                  text: "**Confidential**",
                  style: "fontHeader",
                  alignment: "center",
                },
                {
                  margin: [0, -5, 0, 0],
                  style: "tableNormal",
                  table: {
                    widths: ["97%"],
                    body: [
                      [
                        {
                          style: "tableHeader",
                          margin: [0, 0, 0, 0],
                          text: [
                            {
                              text:
                                props.language === "TH"
                                  ? "หนังสือแสดงความยินยอมรับการรักษาโรคติดเชื้อไวรัสโคโรนา 2019 โรงพยาบาลเฉพาะกิจ "
                                  : "Consent for Admission in Hospitel to receive COVID-19 Treatment",
                              style: "fontHeaderTableTop",
                              alignment: "center",
                            },
                          ],
                        },
                      ],
                    ],
                  },
                },
              ],
            },
            // {
            //   image: "logobkhpt",
            //   width: 145,
            //   alignment: "right",
            // },
            {
              // star-sized columns fill the remaining space
              // if there's more than one star-column, available width is divided equally
              margin: [0, 0, 0, 0],
              width: "*",
              stack: [
                {
                  columns: [
                    {
                      width: "auto",
                      text: [
                        { text: "Name: ", style: "fieldKey" },
                        {
                          text: props.patientName,
                          style: "fieldValue",
                          alignment: "right",
                        },
                      ],
                    },
                    {
                      // star-sized columns fill the remaining space
                      // if there's more than one star-column, available width is divided equally
                      width: "*",
                      text: [
                        { text: "Nationality: ", style: "fieldKey" },
                        { text: props.nationality, style: "fieldValue" },
                      ],
                    },
                    {
                      // star-sized columns fill the remaining space
                      // if there's more than one star-column, available width is divided equally
                      width: "*",
                      text: [
                        { text: "Room: ", style: "fieldKey" },
                        { text: props.room, style: "fieldValue" },
                      ],
                    },
                  ],
                  columnGap: 5,
                },
                {
                  columns: [
                    {
                      width: "35%",
                      text: [
                        { text: "HN: ", style: "fieldKey" },
                        { text: props.hn, style: "fieldValue" },
                      ],
                    },
                    {
                      // star-sized columns fill the remaining space
                      // if there's more than one star-column, available width is divided equally
                      width: "*",
                      text: [
                        { text: "Physician: ", style: "fieldKey" },
                        { text: props.physician, style: "fieldValue" },
                      ],
                    },
                  ],
                  columnGap: 5,
                },
                {
                  columns: [
                    {
                      width: "35%",
                      text: [
                        { text: "Visit Date: ", style: "fieldKey" },
                        { text: props.visitDate, style: "fieldValue" },
                      ],
                    },
                    {
                      // star-sized columns fill the remaining space
                      // if there's more than one star-column, available width is divided equally
                      width: "*",
                      text: [
                        { text: "Department: ", style: "fieldKey" },
                        { text: props.department, style: "fieldValue" },
                      ],
                    },
                  ],
                  columnGap: 5,
                },
                {
                  columns: [
                    {
                      width: "35%",
                      text: [
                        { text: "Birth Date: ", style: "fieldKey" },
                        { text: props.birthDate, style: "fieldValue" },
                      ],
                    },
                    {
                      // star-sized columns fill the remaining space
                      // if there's more than one star-column, available width is divided equally
                      width: "*",
                      text: [
                        { text: "Age: ", style: "fieldKey" },
                        { text: `${props.age} ${props.language === "TH"? " ปี" : " years"}`, style: "fieldValue" },
                      ],
                    },
                    {
                      // star-sized columns fill the remaining space
                      // if there's more than one star-column, available width is divided equally
                      width: "*",
                      text: [
                        { text: "Sex: ", style: "fieldKey" },
                        { text: props.sex, style: "fieldValue" },
                      ],
                    },
                  ],
                  columnGap: 5,
                },
                {
                  columns: [
                    {
                      width: "*",
                      text: [
                        { text: "Allergies: ", style: "fieldKey" },
                        {
                          text: props.allergies,
                          style: "fontEllipsis",
                          width: "100px",
                        },
                      ],
                    },
                  ],
                  columnGap: 5,
                },
                {
                  columns: [
                    {
                      width: "*",
                      text: [
                        { text: "Side Effect: ", style: "fieldKey" },
                        { text: props.sideEffect, style: "fieldValue" },
                      ],
                    },
                  ],
                  columnGap: 5,
                },
              ],
            },
          ],
          // optional space between columns
          columnGap: 5,
        },
      ],
    },
    content: [
      {
        text:
          props.language === "TH"
            ? "แบบฟอร์มการขอความยินยอมในการรับการตรวจและรักษาโรคติดเชื้อไวรัส โคโรน่า 2019 และโรคอื่นๆ ที่เกี่ยวข้อง และความยินยอมในการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของผู้รับบริการ"
            : "Consent for Investigational tests and receipt of COVID-19 Treatment and relevant diseases as well as Collection, Use and Disclose of service receiver’s personal information",
        alignment: "center",
        style: "fieldHeader",
      },
      {
        margin: [0, 20, 0, 0],
        columns: [
          props.language === "TH"
            ? {
                width: "auto",
                text: "ข้าพเจ้า ",
              }
            : {
                margin: [30, 0, 0, 0],
                width: "auto",
                text: "I ",
              },
          {
            width: "45%",
            alignment: "center",
            text: props.patientName,
          },
          {
            width: "auto",
            text: props.language === "TH" ? "อายุ" : "Age",
          },
          {
            width: "15%",
            alignment: "center",
            text: `${props.age} ${props.language === "TH"? " ปี" : " years"}`,
          },
        ],
      },
      {
        margin: [0, 0, 0, 10],
        columns: [
          {
            text:
              props.language === "TH"
                ? "เลขประจำตัวบัตรประชาชน / เลขหนังสือเดินทาง"
                : "ID number/ Passport number",
          },
          {
            text: props.citizenNumber,
          },
        ],
      },
      {
        style: "tableNormal",
        table: {
          widths: ["97%"],
          body: [
            [
              {
                stack: [
                  {
                    style: "tableHeader",
                    margin: [0, 0, 0, 10],
                    text:
                      props.language === "TH"
                        ? [
                            {
                              text:
                                "ข้าพเจ้ารับทราบข้อมูลจากบุคลากรของ โรงพยาบาลเฉพาะกิจ ภายใต้การดูแลของโรงพยาบาลกรุงเทพสำนักงานใหญ่ ซึ่งประกอบ กิจการโดยบริษัท กรุงเทพดุสิตเวชการ จำกัด (มหาชน) (เรียกรวมกันว่า ",
                            },
                            { text: "“สถานพยาบาล”", bold: true },
                            { text: " ตามหนังสือแนะนำ " },
                            {
                              text:
                                ") P/I-02.1-BHQ-007 การเข้าพักและการปฏิบัติตัวระหว่างพักรักษาตัว ในโรงพยาบาลเฉพาะกิจ ",
                              bold: true,
                            },
                            {
                              text:
                                "เป็นอย่างดีแล้ว รวมทั้งได้รับทราบข้อมูลเกี่ยวกับประมาณการ ค่าใช้จ่ายในการรักษาในสถานพยาบาลแล้ว และสามารถยอมรับได้",
                            },
                          ]
                        : [
                            {
                              text:
                                "I have well received the information from staffs of Hospitel under provision of Bangkok Hopsital Head Quarter operated by Bangkok Dusit Medical Services Public Company Limited as called “Health Provider” according to ",
                            },
                            { text: "P/I-02.1-BHQ-007: ", bold: true },
                            {
                              text:
                                "Admission and Self-Care during staying in Hospitel, including receipt of information about estimated medical expenses in the health provider and have been able to accept it.",
                            },
                          ],
                  },
                  {
                    columns: [
                      props.acknowledged1
                        ? { image: "checkmark", width: 14 }
                        : { image: "square", width: 14 },
                      {
                        width: "auto",
                        margin: [10, 0, 0, 0],
                        text:
                          props.language === "TH" ? "รับทราบ" : "Acknowledged",
                      },
                    ],
                  },
                ],
              },
            ],
          ],
        },
      },
      {
        margin: [0, 10, 0, 10],
        text:
          props.language === "TH"
            ? "ข้าพเจ้ารับทราบหน้าที่ของสถานพยาบาลในการรักษาความลับข้อมูลด้านสุขภาพของข้าพเจ้า โดยสถานพยาบาลจะเปิดเผยข้อมูล เท่าที่จำเป็นดังนี้:"
            : "I have acknowledged responsibility of health provider in maintaining confidentiality of my health information as the health provider will disclose such information as necessary as below:",
      },
      {
        style: "tableNormal",
        table: {
          widths: ["97%"],
          body: [
            [
              {
                stack: [
                  {
                    alignment: "center",
                    bold: true,
                    text:
                      props.language === "TH"
                        ? "หน้าที่ของสถานพยาบาลในการรักษาความลับข้อมูลด้านสุขภาพ และการเปิดเผยข้อมูลเท่าที่จำเป็นเพื่อการตรวจรักษา"
                        : "Responsibility of Health Provider in maintaining confidentiality of healthy information and disclosing such information for investigation as necessary",
                  },
                  props.language === "TH"
                    ? {
                        margin: [20, 0, 0, 0],
                        text:
                          "สถานพยาบาลมีหน้าที่ตามกฎหมายในการรักษาความลับของข้อมูลด้านสุขภาพซึ่งเป็นข้อมูลส่วนบุคคลตามพระราชบัญญัติสุขภาพ",
                      }
                    : {},
                  {
                    // style: "tableHeader",
                    margin: [0, 0, 0, 10],
                    text:
                      props.language === "TH"
                        ? "แห่งชาติ พ.ศ. 2550 และพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 เว้นแต่การเปิดเผยนั้นเป็นไปตามความประสงค์ของท่าน โดยตรง หรือมีกฎหมายบัญญัติโดยเฉพาะให้สถานพยาบาลต้องเปิดเผย ดังนั้น เพื่อให้สถานพยาบาลสามารถให้บริการทางการแพทย์ ให้เป็น ไปตามความประสงค์ของท่านโดยตรง สถานพยาบาลมีความจำเป็นต้องเปิดเผยข้อมูลส่วนบุคคลของท่าน ให้คณะแพทย์ พยาบาล และ/หรือ บุคลากรอื่นๆ ในทีมสุขภาพของสถานพยาบาล ทำการประมวลผลข้อมูลการตรวจรักษา วินิจฉัย รักษา และกระทำการใดๆ ตามหลักวิชาชีพ ที่เกี่ยวข้องเพื่อประโยชน์ในการตรวจและรักษาโรค โดยสถานพยาบาล จะรักษาความลับของข้อมูลส่วนบุคคล ของท่านตามมาตรฐาน และกฎหมายที่เกี่ยวข้อง"
                        : "By law, a health provider is responsible for maintaining confidentiality of health information as personal information in compliance with National Health Act B.E. 2550 and Personal Data Protection Act B.E.2562 unless the disclosure is your will directly or there is a law specifically stipulating the health provider to disclose such information. Therefore; to allow the health provider to provide medical cares to you according to your will directly, the health provider necessarily disclose your personal information to a team of physician- nurse and/ or other staffs in the health team of the health provider in order to analyze investigation, diagnosis, treatment or another actions based on professional principles for benefits of investigation and treatment as the health provider will maintain confidentiality of your personal data in compliance with applicable standards and laws.",
                  },
                  {
                    columns: [
                        props.acknowledged2
                        ? { image: "checkmark", width: 14 }
                        : { image: "square", width: 14 },
                      {
                        margin: [10, 0, 0, 0],
                        text:
                          props.language === "TH" ? "รับทราบ" : "Acknowledged",
                      },
                    ],
                  },
                ],
              },
            ],
          ],
        },
      },
      props.language === "TH"
        ? {
            margin: [0, 10, 0, 10],
            text:
              "ข้าพเจ้ายินยอมให้สถานพยาบาลให้บริการตรวจและรักษาโรคติดเชื้อไวรัส โคโรน่า 2019 และโรคอื่นๆ ที่เกี่ยวข้องให้กับข้าพเจ้า และให้สถาน พยาบาลใช้ข้อมูลส่วนบุคคลของข้าพเจ้าตามวัตถุประสงค์ต่างๆ ดังนี้",
          }
        : {
            margin: [0, 10, 0, 0],
            text:
              "I give my consent to the health provider to service investigation and treatments for COVID-19 and other relevant diseases to me, and to use my personal data according to the following purposes.",
          },
      {
        margin: props.language === "TH" ? [0, 0, 0, 0] : [0, 0, 0, 10],
        text:
          props.language === "TH"
            ? "1. ความยินยอมในการรับการตรวจและรักษาโรคติดเชื้อไวรัส โคโรน่า 2019 และโรคอื่นๆ ที่เกี่ยวข้อง"
            : "1. Consent for receipt of investigation and treatments for COVID-19 and other relevant diseases",
        bold: true,
      },
      {
        style: "tableNormal",
        table: {
          widths: ["97%"],
          body: [
            [
              {
                stack: [
                  {
                    // style: "tableHeader",
                    margin: [0, 0, 0, 10],
                    text: [
                      {
                        text:
                          props.language === "TH"
                            ? "ข้าพเจ้าสมัครใจให้คณะแพทย์ พยาบาล เจ้าหน้าที่ และ/หรือ บุคลากรอื่นๆ ในทีมสุขภาพของสถานพยาบาลทำการตรวจวินิจฉัย รักษา และกระทำการใดๆ ตามหลักวิชาชีพที่เกี่ยวข้องเพื่อประโยชน์ในการตรวจและรักษาโรคติดเชื้อไวรัส โคโรน่า 2019 และโรคอื่นๆ ที่เกี่ยวข้อง ของข้าพเจ้า โดยข้าพเจ้าได้รับแจ้งข้อมูลด้านสุขภาพที่เกี่ยวข้องกับการตรวจและรักษา ข้อดี ข้อเสีย กฎ ระเบียบและหลักเกณฑ์ ในการ เข้ารับการรักษา ณ สถานพยาบาล รวมทั้งคำประกาศสิทธิผู้ป่วยอย่างเพียงพอและเข้าใจเป็นอย่างดี รวมถึงได้รับสำเนาคำประกาศสิทธิ และข้อพึงปฏิบัติของผู้ป่วยแล้ว"
                            : "I voluntarity give consent to a team of physician-nurse and/or other staffs in the health team of the health provider to investigate, diagnose, treat or other act according to applicable professional principles for benefits of investigation and treatment for COVID-19 or other relevant diseases to myself as I have been informed relevant health information about investigation and treatment, advantages, disadvantages, rules, regulation and criteria for admission at Hospitel, including Declaration of Patient’s Rights as sufficiently and well understandingly as well as receiving a copy of Declaration of Patient’s Rights and Codes of Conduct.",
                      },
                    ],
                  },
                  {
                    columns: [
                        props.consent1
                        ? { image: "checkmark", width: 14 }
                        : { image: "square", width: 14 },
                      {
                        width: "20%",
                        margin: [10, 0, 40, 0],
                        text: props.language === "TH" ? "ยินยอม" : "Consent",
                      },
                      props.refuse1
                        ? { image: "checkmark", width: 14 }
                        : { image: "square", width: 14 },
                      {
                        margin: [10, 0, 0, 0],
                        text: props.language === "TH" ? "ไม่ยินยอม" : "Refuse",
                      },
                    ],
                  },
                ],
              },
            ],
          ],
        },
      },
      {
        margin: props.language === "TH" ? [0, 40, 0, 0] : [0, 0, 0, 0],
        bold: true,
        text:
          props.language === "TH"
            ? "2. ความยินยอมในการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลเพื่อวัตถุประสงค์ต่างๆ ดังนี้"
            : "2. Consent for Collection, Use and Disclose of personal data for other purposes as listed below ",
      },
      {
        margin: [20, 0, 0, 0],
        columns: [
          { text: "2.1", bold: true, width: "auto" },
          {
            bold: true,
            text:
              props.language === "TH"
                ? "เพื่อใช้สิทธิเรียกค่าสินไหมทดแทนจากบริษัทประกันภัยหรือ ใช้สิทธิเบิกค่ารักษาพยาบาลจากบุคคลที่สามซึ่งเป็นบุคคล หรือนิติบุคคลทั้งในประเทศไทยและต่างประเทศ"
                : "To file for compensation from insurance companies or reimbursement from the third party who is a person or legal entity in both Thailand or Overseas ",
          },
        ],
        columnGap: 5,
      },
      {
        style: "tableNormal",
        table: {
          widths: ["97%"],
          body: [
            [
              {
                stack: [
                  {
                    // style: "tableHeader",
                    margin: [0, 0, 0, 10],
                    text: [
                      {
                        text:
                          props.language === "TH"
                            ? "ข้าพเจ้ายินยอมให้สถานพยาบาลเปิดเผย และ/หรือส่งสำเนาข้อมูลส่วนบุคคลของข้าพเจ้าที่มีอยู่กับสถานพยาบาลให้บริษัทประกันภัย หรือผู้ให้ บริการบริหารจัดการสินไหมทดแทนของบริษัทประกันภัยนั้น เพื่อการปฏิบัติตามสัญญาที่ข้าพเจ้าหรือสถานพยาบาลได้ทำหรือจะทำไว้กับ บริษัทประกันภัย รวมถึงบุคคลที่สามตามที่ข้าพเจ้าหรือสถานพยาบาลมีสัญญาต่อกัน หรือบุคคล นิติบุคคลหรือหน่วยงานไม่ว่าภาครัฐ เอกชน หรือรัฐวิสาหกิจทั้งในประเทศไทย และต่างประเทศที่เป็นผู้ส่งข้าพเจ้ามาตรวจรักษากับสถานพยาบาลหรือเป็นผู้ชำระค่าบริการตรวจรักษา ของข้าพเจ้า"
                            : "I give my consent to the health provider to disclose and/or provide a copy of my personal data maintained in the health provider to an insurance company or a service provider, compensation management of such insurance company to comply with the contract I or the health provider has contacted with such insurance company, including the third party I or the health provider has a contract, or person, legal entity or department of government, private sector or state enterprises in Thailand or overseas who refers me to the health provider or pays for my medical expenses.",
                      },
                    ],
                  },
                  {
                    columns: [
                        props.consent2
                        ? { image: "checkmark", width: 14 }
                        : { image: "square", width: 14 },
                      {
                        width: "20%",
                        margin: [10, 0, 40, 0],
                        text: props.language === "TH" ? "ยินยอม" : "Consent",
                      },
                      props.refuse2
                        ? { image: "checkmark", width: 14 }
                        : { image: "square", width: 14 },
                      {
                        margin: [10, 0, 0, 0],
                        text: props.language === "TH" ? "ไม่ยินยอม" : "Refuse",
                      },
                    ],
                  },
                ],
              },
            ],
          ],
        },
      },
      {
        margin: [20, 10, 0, 0],
        columns: [
          { text: "2.2", bold: true, width: "auto" },
          {
            bold: true,
            text:
              props.language === "TH"
                ? "เพื่อให้ข้าพเจ้าและบุคลากรทางการแพทย์ของสถานพยาบาลในเครือข่าย¹ ที่ข้าพเจ้าเข้ารับการรักษาสามารถเข้าถึงข้อมูลการรักษา พยาบาลของข้าพเจ้าจากสถานพยาบาลในเครือข่าย ที่ข้าพเจ้าเข้ารับการรักษาเพื่อประโยชน์ในการให้บริการรักษาพยาบาล แก่ข้าพเจ้า"
                : "For I and medical staffs of the affiliated health providers¹ where I receive the service can access my health information from the affiliated health providers where I have received the service for benefits of treatments to myself",
          },
        ],
        columnGap: 5,
      },
      {
        style: "tableNormal",
        table: {
          widths: ["97%"],
          body: [
            [
              {
                stack: [
                  {
                    // style: "tableHeader",
                    margin: [0, 0, 0, 10],
                    text: [
                      {
                        text:
                          props.language === "TH"
                            ? "ข้าพเจ้ายินยอมให้สถานพยาบาลบริหารจัดการข้อมูลการรักษาพยาบาลของข้าพเจ้าเพื่อให้ข้าพเจ้าสามารถเข้าถึงข้อมูลการรักษาพยาบาลของ ข้าพเจ้าจากสถานพยาบาลในเครือข่ายที่ข้าพเจ้าเข้ารับการรักษา และให้บุคลากรทางการแพทย์ของสถานพยาบาลในเครือข่ายที่ข้าพเจ้า เข้ารับการรักษาสามารถเข้าถึงข้อมูลการรักษาพยาบาลของข้าพเจ้าเพื่อประโยชน์ในการให้บริการรักษาพยาบาลแก่ข้าพเจ้า"
                            : "I give my consent to the health provider managing my health information for me to access my health information from affiliated health providers where I have received services as well as to medical staffs of the affiliated health providers to be able to access my health information for benefits of treatments to myself",
                      },
                    ],
                  },
                  {
                    columns: [
                        props.consent3
                        ? { image: "checkmark", width: 14 }
                        : { image: "square", width: 14 },
                      {
                        width: "20%",
                        margin: [10, 0, 40, 0],
                        text: props.language === "TH" ? "ยินยอม" : "Consent",
                      },
                      props.refuse3
                        ? { image: "checkmark", width: 14 }
                        : { image: "square", width: 14 },
                      {
                        margin: [10, 0, 0, 0],
                        text: props.language === "TH" ? "ไม่ยินยอม" : "Refuse",
                      },
                    ],
                  },
                ],
              },
            ],
          ],
        },
      },
      {
        margin: [20, 10, 0, 0],
        columns: [
          { text: "2.3", bold: true, width: "auto" },
          {
            bold: true,
            text:
              props.language === "TH"
                ? "เพื่อวัตถุประสงค์ทางการตลาดของสถานพยาบาล"
                : "For marketing purposes of the health provider",
          },
        ],
        columnGap: 5,
      },
      {
        style: "tableNormal",
        table: {
          widths: ["97%"],
          body: [
            [
              {
                stack: [
                  {
                    // style: "tableHeader",
                    margin: [0, 0, 0, 10],
                    text: [
                      {
                        text:
                          props.language === "TH"
                            ? "ข้าพเจ้ายินยอมให้สถานพยาบาลทำการเก็บรวบรวม ใช้ และประมวลผลข้อมูลส่วนบุคคลเพื่อวิเคราะห์สภาวะสุขภาพของข้าพเจ้า และติดต่อ เพื่อสื่อสาร ส่งข้อมูลข่าวสารด้านการแพทย์ และนำเสนอโปรโมชั่น สินค้าและบริการ"
                            : "I give me consent to the health provider to collect, use and analyze my personal data for analyzing my health condition and communication of providing medical information and offering promotion, products and services",
                      },
                    ],
                  },
                  {
                    columns: [
                        props.consent4
                        ? { image: "checkmark", width: 14 }
                        : { image: "square", width: 14 },
                      {
                        width: "20%",
                        margin: [10, 0, 40, 0],
                        text: props.language === "TH" ? "ยินยอม" : "Consent",
                      },
                      props.refuse4
                        ? { image: "checkmark", width: 14 }
                        : { image: "square", width: 14 },
                      {
                        margin: [10, 0, 0, 0],
                        text: props.language === "TH" ? "ไม่ยินยอม" : "Refuse",
                      },
                    ],
                  },
                ],
              },
            ],
          ],
        },
      },
      {
        text:
          props.language === "TH"
            ? "ทั้งนี้ ข้าพเจ้าได้อ่านเอกสารแจ้งการประมวลผลข้อมูลส่วนบุคคลสำหรับผู้รับบริการตรวจรักษาโรคกับบริษัท กรุงเทพดุสิตเวชการ จำกัด"
            : "Herein, I have read notification of personal information analysis for service receivers by BDMS ",
        margin: [20, 10, 0, 0],
      },
      {
        text:
          props.language === "TH"
            ? "(มหาชน) แล้ว และรับทราบว่าสถานพยาบาลอาจมีการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้า เพื่อวัตถุประสงค์อื่นตามที่ กฎหมายกำหนด และ/หรือตามที่ระบุในเอกสารดังกล่าว โดยไม่จำเป็นต้องขอความยินยอมจากข้าพเจ้า"
            : "public company limited and acknowledged that the health provider may collect, use and disclose my personal data for other purposes according to laws as determined and/or specified in the aforementioned documents without a need to request my consent",
      },

      {
        margin: props.language === "TH" ? [0, 10, 0, 10] : [10, 10, 0, 10],
        text:
          props.language === "TH"
            ? "จึงขอลงลายมือไว้เพื่อเป็นหลักฐานประกอบคำยืนยัน"
            : "Herewith my writing as an evidence of confirmation ",
        bold: props.language !== "TH",
      },
      props.language === "TH"
        ? {
            margin: [0, 0, 20, 0],
            text: `ชื่อผู้ป่วย ${props.patientName} ลงนามผู้ป่วย ${props.patientSignature} วันที่ ${props.patientSignDate} เวลา ${props.patientSignTime} น.`,
            alignment: "left",
          }
        : {},
      props.language === "EN"
        ? {
            margin: [0, 0, 60, 0],
            stack: [
              {
                text: [
                  { text: "Patient’s Name ", width: "auto" },
                  {
                    text: props.patientName,
                    style: "fieldName",
                    width: "auto",
                  },
                  {
                    text:
                      " Patient’s signature Digitally signed by ",
                    width: "auto",
                  },
                  { text: props.patientSignature, style: "fieldName" },
                ],
              },
              {
                text: ` Date ${props.patientSignDate} Time ${props.patientSignTime}`,
              },
            ],
          }
        : {},
      {
        text:
          props.language === "TH"
            ? "ชื่อพยาน_________________________________(ตัวบรรจง)ลงนามพยาน _______________________ วันที่__________เวลา______น."
            : "Witness’s Name_________________________(Printed name) Witness’s signature ________________ Date__________Time___",
        alignment: "left",
      },
      {
        text:
          props.language === "TH"
            ? "ชื่อพยาน_________________________________(ตัวบรรจง)ลงนามพยาน _______________________ วันที่__________เวลา______น."
            : "Witness’s Name_________________________(Printed name) Witness’s signature ________________ Date__________Time____",
        alignment: "left",
      },
      {
        text:
          props.language === "TH"
            ? "(เฉพาะกรณีพิมพ์ลายนิ้วมือผู้ป่วย)"
            : "(In case where the patient’s fingerprint is needed) ",
        alignment: "justify",
      },
      {
        text:
          props.language === "TH"
            ? "ชื่อบุคลากรทางการแพทย์_____________________(ตัวบรรจง)ลงนามผู้ให้ข้อมูล____________________วันที่__________เวลา____น."
            : "Medical Staff’s Printed Name _____________________ Medical Staff’s signature ________________ Date__________Time____",
        alignment: "left",
      },
      {
        margin: props.language === "TH" ? [0, 10, 0, 10] : [0, 0, 0, 0],
        bold: props.language === "TH",
        text:
          props.language === "TH"
            ? "โปรดระบุเหตุผลที่ทำให้ผู้ป่วยไม่สามารถเซ็นยินยอมเพื่อรับการรักษาด้วยตนเองได้"
            : "(who giving the information to the patient)",
        alignment: "justify",
      },
      props.language === "EN"
        ? {
            margin: [0, 10, 0, 0],
            text:
              "State the reason why the patient is unable to provide consent by themselves (or to self-sign on this form)",
            alignment: "justify",
            bold: true,
          }
        : {},
      {
        margin: [30, 0, 0, 0],
        columns: [
          { image: "square", width: 14 },
          {
            text:
              props.language === "TH"
                ? "ยังไม่บรรลุนิติภาวะ - ผู้ป่วยอายุไม่ครบ 20 ปี บริบูรณ์"
                : "Minor-any unmarried male or female whose age has not reached the age of consent (20-year-old)",
          },
        ],
        columnGap: 10,
      },
      {
        margin: [30, 0, 0, 0],
        columns: [
          { image: "square", width: 14 },
          {
            text:
              props.language === "TH"
                ? "ผู้บกพร่องทางกายหรือจิต _____________________________________________________________________________"
                : "Physical / mental disorder _______________________________________________________________________",
            width: "auto",
          },
          // { text: props.disabillityText },
        ],
        columnGap: 10,
      },
      {
        margin: [30, 0, 0, 0],
        columns: [
          { image: "square", width: 14 },
          {
            text:
              props.language === "TH"
                ? "อื่น ๆ (โปรดระบุ) ___________________________________________________________________________________"
                : "Other (please specify) ___________________________________________________________________________",
          },
          // { text: props.otherReasonText },
        ],
        columnGap: 10,
      },
      {
        margin: props.language === "TH" ? [20, 10, 0, 10] : [0, 0, 0, 0],
        text:
          props.language === "TH"
            ? "ข้อความดังกล่าวข้างต้นได้ถูกอธิบายให้ผู้ที่มีอำนาจตามกฎหมายในการตัดสินใจแทนผู้ป่วย ( นามดังกล่าวข้างล่าง ) ได้รับทราบและแสดงความยินยอมเพื่อรับการรักษาดังกล่าวแล้ว"
            : "Above information has completely been explained to legally authorized representative (as name below) who has acknowledged and hereby given the consent for the mentioned treatments.",
      },
      props.language === "TH"
        ? {
            text:
              "ชื่อผู้แทนผู้ป่วย_____________________(ตัวบรรจง)ลงนามผู้แทนผู้ป่วย______________________วันที่______________เวลา_________น.",
            alignment: "justify",
          }
        : {},
      //   props.language === "TH" ? (
      //     {
      //       text: "ความสัมพันธ์กับผู้ป่วย______________________________หมายเลขบัตรประจำตัว _______________________________________________",
      //       alignment: 'justify',
      //     }
      //   ) : {},

      props.language === "EN"
        ? {
            margin: [0, 0, 0, 0],
            stack: [
              {
                text:
                  "Legally authorized representative (LAR)’s Name_________________(Printed name) LAR’s Signature _____________________",
                alignment: "left",
              },
              {
                text:
                  "Date: _____________Time___________Relationship__________________ Identification number ________________________",
                alignment: "left",
              },
              {
                text:
                  "Issue Place:________________________________ Issuing Date: __________________ Expiration Date: ____________________",
                alignment: "left",
              },
              {
                text:
                  "Current address of legally authorized representative: _____________________________________________________________",
                alignment: "left",
              },
            ],
          }
        : {},

      props.language === "EN"
        ? {
            text:
              "___________________________________________________________________________________________________________",
          }
        : {},
      props.language === "EN"
        ? {
            margin: [0, 10, 20, 0],
            stack: [
              {
                text: "Interpreter’s Statement",
                style: { bold: true },
                decoration: "underline",
              },
              {
                margin: [20, 0, 0, 0],
                text:
                  "I have given a/an ___________________ language translation of the Consent Form for Anesthesia including any",
              },
              {
                text:
                  "additional information that a doctor or assigned staff has explained completely.",
              },
              {
                margin: [30, 0, 0, 0],
                columns: [
                  {
                    width: "15%",
                    text: "Via",
                  },
                  { image: "square", width: 14 },
                  {
                    margin: [3, 0, 0, 0],
                    text: " Direct communication",
                  },
                  { image: "square", width: 14 },
                  {
                    margin: [3, 0, 0, 0],
                    text: " Tele-communication",
                  },
                  { image: "square", width: 14 },
                  {
                    margin: [3, 0, 0, 0],
                    text: "Tele-interpreter",
                  },
                ],
              },
              {
                margin: [0, 10, 0, 0],
                text:
                  "Interpreter’s Name____________________________ (Printed name) Interpreter’s Signature ____________________",
                alignment: "left",
              },
              {
                alignment: "left",
                text:
                  "Staff ID /ID Card No. /Passport No. ______________________________ Date ___________ Time __________________",
              },
            ],
          }
        : {},
      {
        fontSize: 10,
        margin: [20, 20, 20, 0],
        text:
          props.language === "TH"
            ? "¹ สถานพยาบาลในเครือข่าย หมายถึง สถานพยาบาลในกลุ่มหรือในเครือข่ายของบริษัทกรุงเทพดุสิตเวชการ จำกัด (มหาชน) ทั้งที่ประกอบกิจการในประเทศไทยและในต่างประเทศ"
            : "¹ Healthcare facilities in the network refer to healthcare facilities in the group or in the network of Bangkok Dusit Medical Services Public Company Limited, both operating in Thailand and abroad.",
      },
    ],
    images: {
      logobkhpt: origin + "/static/images/logobkhpt.png",
      checked: origin + "/static/images/checked.png",
      uncheck: origin + "/static/images/uncheck.png",
      checkmark: origin + "/static/images/square_checkmark.png",
      square: origin + "/static/images/square.png",
    },
  };

  var win = window.open("", "_blank");
  pdfMake.createPdf(docDefinition).open({}, win);
};
