export default function toPDFMakeData(props: any) {
  return {
    content: [
      {
        preserveLeadingSpaces: true,
        width: `auto`,
        pageBreak: ``,
        fontSize: `18`,
        decorationStyle: ``,
        alignment: `center`,
        decorationColor: ``,
        decoration: ``,
        color: ``,
        text: `Informed Consent to HIV Blood Test`,
        bold: `true`,
      },
      {
        preserveLeadingSpaces: true,
        decoration: ``,
        pageBreak: ``,
        fontSize: 15,
        alignment: `left`,
        decorationStyle: ``,
        text: ` `,
        decorationColor: ``,
        color: ``,
        bold: false,
        width: `auto`,
      },
      {
        columns: [
          {
            fontSize: 15,
            decorationStyle: ``,
            decorationColor: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            color: ``,
            text: ``,
            pageBreak: ``,
            width: 30,
            decoration: ``,
            bold: false,
          },
          {
            decorationColor: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            text: `I (Mr/Mrs./Ms.)`,
            color: ``,
            fontSize: 15,
            width: `auto`,
            bold: false,
            pageBreak: ``,
            decorationStyle: ``,
            decoration: ``,
          },
          {
            text: ` `,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            stack: [
              {
                text: props.items?.formatPatient,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.................................................................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            width: `auto`,
            pageBreak: ``,
            alignment: `left`,
            fontSize: 15,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            bold: false,
            text: `voluntarily consent to `,
            decoration: ``,
            decorationColor: ``,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            width: 30,
            decorationColor: ``,
            decoration: ``,
            pageBreak: ``,
            color: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            bold: false,
            decorationStyle: ``,
            text: ``,
          },
          {
            decoration: ``,
            color: ``,
            decorationStyle: ``,
            fontSize: 15,
            decorationColor: ``,
            width: `auto`,
            alignment: `left`,
            bold: false,
            preserveLeadingSpaces: true,
            text: `Dr.`,
            pageBreak: ``,
          },
          {
            text: ` `,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            stack: [
              {
                text: props.items?.formatDoctor,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `....................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            bold: false,
            width: `auto`,
            decoration: ``,
            text: `and Sapiens Hospital personnel to draw my blood for the`,
            decorationStyle: ``,
            color: ``,
            decorationColor: ``,
            alignment: `left`,
            fontSize: 15,
            pageBreak: ``,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        decorationColor: ``,
        bold: false,
        decorationStyle: ``,
        pageBreak: ``,
        width: `auto`,
        color: ``,
        preserveLeadingSpaces: true,
        fontSize: 15,
        alignment: `left`,
        decoration: ``,
        text: `purpose of AIDS/HIV test (Anti HIV/HIV Ag/HIV RNA PCR).`,
      },
      {
        columns: [
          {
            alignment: `left`,
            decoration: ``,
            preserveLeadingSpaces: true,
            width: 30,
            bold: false,
            decorationStyle: ``,
            fontSize: 15,
            pageBreak: ``,
            text: ``,
            decorationColor: ``,
            color: ``,
          },
          {
            text: `I was informed by the doctor about the necessity of the blood test which will be beneficial to my`,
            bold: false,
            color: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationStyle: ``,
            fontSize: 15,
            width: `auto`,
            decorationColor: ``,
            decoration: ``,
          },
        ],
      },
      {
        pageBreak: ``,
        decoration: ``,
        text: `treatment and this action does not violate my rights.`,
        bold: false,
        preserveLeadingSpaces: true,
        decorationColor: ``,
        width: `auto`,
        color: ``,
        fontSize: 15,
        decorationStyle: ``,
        alignment: `left`,
      },
      {
        columns: [
          {
            width: 30,
            decoration: ``,
            decorationColor: ``,
            pageBreak: ``,
            fontSize: 15,
            color: ``,
            text: ``,
            decorationStyle: ``,
            bold: false,
            preserveLeadingSpaces: true,
            alignment: `left`,
          },
          {
            pageBreak: ``,
            alignment: `left`,
            fontSize: 15,
            decorationColor: ``,
            color: ``,
            width: `auto`,
            decoration: ``,
            text: `I have already received PatientInformation document about HIV/AIDS Counseling.`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            bold: false,
          },
        ],
      },
      {
        alignment: `left`,
        fontSize: 15,
        pageBreak: ``,
        width: `auto`,
        preserveLeadingSpaces: true,
        text: `I'm glad to come to receive the result of laboratory test for HIV and will follow the patient instruction by myself`,
        decoration: ``,
        bold: false,
        decorationStyle: ``,
        decorationColor: ``,
        color: ``,
      },
      {
        columns: [
          {
            pageBreak: ``,
            decorationColor: ``,
            decoration: ``,
            decorationStyle: ``,
            width: 30,
            preserveLeadingSpaces: true,
            color: ``,
            text: ``,
            alignment: `left`,
            bold: false,
            fontSize: 15,
          },
          {
            pageBreak: ``,
            fontSize: 15,
            bold: false,
            decorationStyle: ``,
            color: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            text: `I hereby AGREE to receive the treatment mentioned above.`,
            width: `auto`,
            alignment: `left`,
            decorationColor: ``,
          },
        ],
      },
      {
        text: ` `,
        decoration: ``,
        decorationStyle: ``,
        decorationColor: ``,
        width: `auto`,
        color: ``,
        bold: false,
        fontSize: 15,
        pageBreak: ``,
        alignment: `left`,
        preserveLeadingSpaces: true,
        margin: [0, 0, 0, 0],
      },
      {
        columns: [
          {
            width: 200,
            decoration: ``,
            fontSize: 15,
            decorationStyle: ``,
            pageBreak: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            text: `Signature........................................................  `,
            bold: false,
            color: ``,
            alignment: `center`,
          },
          {
            preserveLeadingSpaces: true,
            bold: false,
            width: 80,
            alignment: `left`,
            decoration: ``,
            decorationStyle: ``,
            fontSize: 15,
            text: `  `,
            color: ``,
            decorationColor: ``,
            pageBreak: ``,
          },
          {
            pageBreak: ``,
            decoration: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            color: ``,
            bold: false,
            fontSize: 15,
            text: `Witness 1........................................................`,
            width: 200,
            alignment: `center`,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            text: `(.............................................................)`,
            color: ``,
            preserveLeadingSpaces: true,
            width: 200,
            decorationStyle: ``,
            pageBreak: ``,
            fontSize: 15,
            decorationColor: ``,
            decoration: ``,
            bold: false,
            alignment: `center`,
          },
          {
            alignment: `left`,
            bold: false,
            pageBreak: ``,
            decoration: ``,
            color: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            text: ``,
            decorationStyle: ``,
            width: 80,
            decorationColor: ``,
          },
          {
            width: 200,
            text: `(.............................................................)`,
            decorationColor: ``,
            alignment: `center`,
            color: ``,
            decoration: ``,
            decorationStyle: ``,
            bold: false,
            fontSize: 15,
            preserveLeadingSpaces: true,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            text: `Date........................Time..................`,
            alignment: `center`,
            decoration: ``,
            width: 200,
            bold: false,
            fontSize: 15,
            decorationStyle: ``,
            color: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            pageBreak: ``,
          },
          {
            color: ``,
            decorationColor: ``,
            decorationStyle: ``,
            text: ``,
            width: 80,
            pageBreak: ``,
            fontSize: 15,
            bold: false,
            preserveLeadingSpaces: true,
            decoration: ``,
            alignment: `left`,
          },
          {
            alignment: `center`,
            fontSize: 15,
            decorationStyle: ``,
            text: `Witness 2........................................................`,
            decoration: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decorationColor: ``,
            width: 200,
            color: ``,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            bold: false,
            preserveLeadingSpaces: true,
            text: ``,
            decoration: ``,
            decorationColor: ``,
            decorationStyle: ``,
            alignment: `left`,
            width: 280,
            color: ``,
            pageBreak: ``,
          },
          {
            decorationStyle: ``,
            color: ``,
            decoration: ``,
            text: `(.............................................................)`,
            pageBreak: ``,
            decorationColor: ``,
            fontSize: 15,
            alignment: `center`,
            preserveLeadingSpaces: true,
            bold: false,
            width: 200,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            alignment: `left`,
            pageBreak: ``,
            text: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            width: 280,
            decorationStyle: ``,
            fontSize: 15,
            bold: false,
            color: ``,
          },
          {
            decoration: ``,
            alignment: `center`,
            fontSize: 15,
            color: ``,
            pageBreak: ``,
            width: 200,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            bold: false,
            text: `(Fingerprint/consent over telephone)`,
          },
        ],
      },
      {
        width: `auto`,
        color: ``,
        alignment: `left`,
        decorationColor: ``,
        text: ` `,
        fontSize: 15,
        pageBreak: ``,
        decoration: ``,
        preserveLeadingSpaces: true,
        bold: false,
        decorationStyle: ``,
      },
      {
        columns: [
          {
            decorationColor: ``,
            color: ``,
            bold: `true`,
            decorationStyle: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            text: `Interpreter’s Statement`,
            width: `auto`,
            alignment: `left`,
            decoration: `underline`,
            fontSize: 15,
          },
        ],
      },
      {
        bold: false,
        decorationColor: ``,
        decoration: ``,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        width: `auto`,
        text: `I have given a translation of information Consent to HIV Blood Test including information that physician has explained to patient/patient’s representative.`,
        color: ``,
        decorationStyle: ``,
        alignment: `left`,
        fontSize: 15,
      },
      {
        text: ` `,
        width: `auto`,
        fontSize: 15,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        color: ``,
        bold: false,
        alignment: `left`,
        decoration: ``,
        pageBreak: ``,
        decorationStyle: ``,
      },
      {
        columns: [
          {
            fontSize: 15,
            decorationStyle: ``,
            bold: false,
            color: ``,
            pageBreak: ``,
            decorationColor: ``,
            alignment: `center`,
            width: 200,
            text: `Translate to Language........................................ `,
            preserveLeadingSpaces: true,
            decoration: ``,
          },
          {
            pageBreak: ``,
            decoration: ``,
            decorationColor: ``,
            width: 80,
            text: ` `,
            fontSize: 15,
            preserveLeadingSpaces: true,
            bold: false,
            alignment: `left`,
            color: ``,
            decorationStyle: ``,
          },
          {
            text: `Interpreter........................................................`,
            fontSize: 15,
            decoration: ``,
            alignment: `left`,
            width: `auto`,
            decorationStyle: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            color: ``,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            decorationColor: ``,
            alignment: `left`,
            fontSize: 15,
            color: ``,
            preserveLeadingSpaces: true,
            text: ``,
            width: 280,
            decorationStyle: ``,
            decoration: ``,
            pageBreak: ``,
          },
          {
            preserveLeadingSpaces: true,
            pageBreak: ``,
            text: `(.............................................................)`,
            bold: false,
            fontSize: 15,
            alignment: `center`,
            width: 200,
            decorationStyle: ``,
            color: ``,
            decorationColor: ``,
            decoration: ``,
          },
        ],
      },
      {
        fontSize: 15,
        decorationColor: ``,
        color: ``,
        text: ` `,
        preserveLeadingSpaces: true,
        decoration: ``,
        pageBreak: ``,
        alignment: `left`,
        decorationStyle: ``,
        width: `auto`,
        bold: false,
      },
      {
        color: ``,
        bold: `true`,
        fontSize: 15,
        width: `auto`,
        decorationStyle: ``,
        text: `Status of Signer (According to Thai Civil and Commercial Code)`,
        decoration: ``,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        decorationColor: ``,
        alignment: `left`,
      },
      {
        columns: [
          {
            fontSize: 15,
            pageBreak: ``,
            color: ``,
            decoration: ``,
            text: `[  ]`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            alignment: `center`,
            width: `auto`,
            decorationStyle: ``,
            bold: false,
          },
          {
            pageBreak: ``,
            decoration: ``,
            alignment: `left`,
            width: 5,
            color: ``,
            text: ``,
            fontSize: 15,
            decorationStyle: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decorationColor: ``,
          },
          {
            preserveLeadingSpaces: true,
            decorationColor: ``,
            text: `1. Patient who is major and capable of giving consent`,
            fontSize: 15,
            color: ``,
            decorationStyle: ``,
            bold: false,
            pageBreak: ``,
            decoration: ``,
            alignment: `left`,
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decoration: ``,
            alignment: `center`,
            color: ``,
            text: `[  ]`,
            pageBreak: ``,
            decorationColor: ``,
            width: `auto`,
            fontSize: 15,
          },
          {
            alignment: `left`,
            decoration: ``,
            pageBreak: ``,
            decorationColor: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            text: ``,
            width: 5,
            color: ``,
            bold: false,
          },
          {
            decoration: ``,
            width: `auto`,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            fontSize: 15,
            color: ``,
            text: `2. Legal husband or wife in case that the patient is not capable of giving consent (unconscious)`,
            decorationStyle: ``,
            bold: false,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            decoration: ``,
            decorationStyle: ``,
            text: `[  ]`,
            color: ``,
            alignment: `center`,
            preserveLeadingSpaces: true,
            width: `auto`,
            decorationColor: ``,
            fontSize: 15,
            pageBreak: ``,
          },
          {
            width: 5,
            text: ``,
            decorationColor: ``,
            alignment: `left`,
            fontSize: 15,
            pageBreak: ``,
            color: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decorationStyle: ``,
          },
          {
            alignment: `left`,
            decorationStyle: ``,
            color: ``,
            text: `3. Holder of parental responsibility in case that the patient is minor (under 20 years old)`,
            width: `auto`,
            pageBreak: ``,
            bold: false,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationColor: ``,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            alignment: `center`,
            preserveLeadingSpaces: true,
            text: `[  ]`,
            pageBreak: ``,
            width: `auto`,
            decorationStyle: ``,
            decorationColor: ``,
            bold: false,
            decoration: ``,
            fontSize: 15,
          },
          {
            pageBreak: ``,
            fontSize: 15,
            alignment: `left`,
            decorationStyle: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            text: ``,
            color: ``,
            bold: false,
            width: 5,
            decorationColor: ``,
          },
          {
            preserveLeadingSpaces: true,
            decorationColor: ``,
            fontSize: 15,
            pageBreak: ``,
            color: ``,
            decoration: ``,
            width: `auto`,
            bold: false,
            alignment: `left`,
            text: `4. Curator in case that the patient is quasi incompetent person (adjudged by the court)`,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            text: `[  ]`,
            color: ``,
            decorationStyle: ``,
            fontSize: 15,
            pageBreak: ``,
            width: `auto`,
            bold: false,
            decorationColor: ``,
            decoration: ``,
            alignment: `center`,
            preserveLeadingSpaces: true,
          },
          {
            text: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decoration: ``,
            width: 5,
            pageBreak: ``,
            color: ``,
            decorationColor: ``,
            decorationStyle: ``,
            alignment: `left`,
            fontSize: 15,
          },
          {
            width: `auto`,
            text: `5. Guardian in case that the patient is incompetent person (adjudged by the court)`,
            color: ``,
            bold: false,
            decorationStyle: ``,
            pageBreak: ``,
            decoration: ``,
            alignment: `left`,
            decorationColor: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            decoration: ``,
            color: ``,
            alignment: `left`,
            width: 15,
            decorationStyle: ``,
            bold: false,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            text: ``,
            pageBreak: ``,
          },
          {
            text: `*For no. 2-5 , please obtain certified true copy of the patient’s representative’s identification card/passport/document issued by governmental authority, which religion and blood type information are covered.`,
            decorationStyle: ``,
            color: ``,
            bold: false,
            width: `auto`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decoration: ``,
            alignment: `left`,
            decorationColor: ``,
          },
        ],
      },
      {
        decorationStyle: ``,
        width: `auto`,
        decorationColor: ``,
        bold: false,
        color: ``,
        fontSize: 15,
        text: ``,
        preserveLeadingSpaces: true,
        decoration: ``,
        alignment: `left`,
        pageBreak: `before`,
      },
      {
        columns: [
          {
            pageBreak: ``,
            decoration: ``,
            bold: false,
            fontSize: 15,
            text: `Relationship with patient..................................................................................................................`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            color: ``,
            decorationStyle: ``,
            alignment: `left`,
          },
        ],
      },
      {
        columns: [
          {
            width: `auto`,
            fontSize: 15,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            text: `Identification number of the patient’s representative.............................................................. `,
            pageBreak: ``,
            decoration: ``,
            color: ``,
            bold: false,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            color: ``,
            bold: false,
            text: `Telephone number............................................................................................................................ `,
            decorationStyle: ``,
            alignment: `left`,
            pageBreak: ``,
            width: `auto`,
            fontSize: 15,
            decorationColor: ``,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            decorationStyle: ``,
            text: `Email......................................................................................................................................................`,
            pageBreak: ``,
            color: ``,
            bold: false,
            width: `auto`,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            fontSize: 15,
            alignment: `left`,
            bold: `true`,
            decorationColor: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            text: `Note:`,
            width: `auto`,
            decorationStyle: ``,
          },
          {
            preserveLeadingSpaces: true,
            alignment: `left`,
            bold: false,
            decorationStyle: ``,
            text: ``,
            width: 5,
            color: ``,
            fontSize: 15,
            decoration: ``,
            decorationColor: ``,
            pageBreak: ``,
          },
          {
            decoration: ``,
            bold: false,
            color: ``,
            text: `In case of need to submit the result to the person(s) other than patient`,
            width: `auto`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            fontSize: 15,
            alignment: `left`,
            pageBreak: ``,
          },
        ],
      },
      {
        text: ` `,
        decoration: ``,
        decorationStyle: ``,
        decorationColor: ``,
        width: `auto`,
        color: ``,
        bold: false,
        fontSize: 15,
        pageBreak: ``,
        alignment: `left`,
        preserveLeadingSpaces: true,
        margin: [0, 0, 0, 0],
      },
      {
        columns: [
          {
            decorationColor: ``,
            fontSize: 15,
            decoration: ``,
            color: ``,
            bold: false,
            width: `auto`,
            text: `I consent/agree to release the Anti HIV result to .......................................................................................................`,
            alignment: `left`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
          },
          {
            pageBreak: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            color: ``,
            fontSize: 15,
            alignment: `left`,
            width: `auto`,
            bold: false,
            decorationColor: ``,
            text: ` only`,
            decorationStyle: ``,
          },
        ],
      },
      {
        preserveLeadingSpaces: true,
        text: ` `,
        alignment: `left`,
        decoration: ``,
        color: ``,
        fontSize: 15,
        pageBreak: ``,
        decorationStyle: ``,
        decorationColor: ``,
        bold: false,
        width: `auto`,
      },
      {
        columns: [
          {
            fontSize: 15,
            decorationStyle: ``,
            color: ``,
            decoration: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            width: 200,
            pageBreak: ``,
            text: `Signature........................................................   `,
            alignment: `center`,
          },
          {
            fontSize: 15,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationStyle: ``,
            color: ``,
            alignment: `left`,
            text: `  `,
            bold: false,
            pageBreak: ``,
            width: 80,
          },
          {
            fontSize: 15,
            color: ``,
            decorationColor: ``,
            text: `Witness 1.........................................................`,
            decoration: ``,
            bold: false,
            alignment: `center`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationStyle: ``,
            width: 200,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            color: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decorationStyle: ``,
            text: `(.............................................................)`,
            alignment: `center`,
            fontSize: 15,
            width: 200,
            bold: false,
          },
          {
            color: ``,
            width: 80,
            decoration: ``,
            text: ``,
            bold: false,
            decorationColor: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decorationStyle: ``,
            fontSize: 15,
          },
          {
            fontSize: 15,
            decorationStyle: ``,
            alignment: `center`,
            decoration: ``,
            bold: false,
            color: ``,
            pageBreak: ``,
            width: 200,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            text: `(.............................................................)`,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            pageBreak: ``,
            text: `Date.........................................`,
            width: 200,
            color: ``,
            bold: false,
            decoration: ``,
            decorationStyle: ``,
            alignment: `center`,
            fontSize: 15,
            preserveLeadingSpaces: true,
          },
          {
            fontSize: 15,
            bold: false,
            text: ``,
            decorationColor: ``,
            decoration: ``,
            decorationStyle: ``,
            alignment: `left`,
            pageBreak: ``,
            width: 80,
            preserveLeadingSpaces: true,
            color: ``,
          },
          {
            bold: false,
            width: 200,
            decorationColor: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            text: `Witness 2........................................................`,
            fontSize: 15,
            decoration: ``,
            alignment: `center`,
            color: ``,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            decoration: ``,
            width: 280,
            decorationColor: ``,
            bold: false,
            text: ``,
            pageBreak: ``,
            fontSize: 15,
            decorationStyle: ``,
            color: ``,
            alignment: `left`,
          },
          {
            decorationColor: ``,
            decorationStyle: ``,
            pageBreak: ``,
            width: 200,
            decoration: ``,
            bold: false,
            text: `(.............................................................)`,
            alignment: `center`,
            preserveLeadingSpaces: true,
            fontSize: 15,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            text: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decoration: ``,
            bold: false,
            decorationColor: ``,
            color: ``,
            width: 280,
            alignment: `left`,
          },
          {
            color: ``,
            decorationStyle: ``,
            pageBreak: ``,
            alignment: `center`,
            text: `(Fingerprint/consent over telephone)`,
            preserveLeadingSpaces: true,
            width: 200,
            decorationColor: ``,
            bold: false,
            fontSize: 15,
            decoration: ``,
          },
        ],
      },
      {
        color: ``,
        pageBreak: ``,
        bold: false,
        alignment: `left`,
        decorationStyle: ``,
        text: ` `,
        preserveLeadingSpaces: true,
        decoration: ``,
        decorationColor: ``,
        fontSize: 15,
        width: `auto`,
      },
      {
        columns: [
          {
            bold: `true`,
            decoration: ``,
            decorationStyle: ``,
            alignment: `center`,
            preserveLeadingSpaces: true,
            text: `Note:`,
            fontSize: 15,
            pageBreak: ``,
            color: ``,
            width: `auto`,
            decorationColor: ``,
          },
          {
            alignment: `left`,
            preserveLeadingSpaces: true,
            color: ``,
            text: ``,
            pageBreak: ``,
            fontSize: 15,
            width: 5,
            bold: false,
            decorationStyle: ``,
            decorationColor: ``,
            decoration: ``,
          },
          {
            width: `auto`,
            bold: false,
            fontSize: 15,
            color: ``,
            decoration: ``,
            pageBreak: ``,
            text: `The information in this Anti HIV Blood Test consent form is considered highly confidential`,
            decorationColor: ``,
            decorationStyle: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
          },
        ],
      },
    ],
    defaultStyle: {
      font: `THSarabunNew`,
    },
    pageOrientation: `portrait`,
    pageSize: `A4`,
  };
}
