import WasmController from "../react-lib/frameworks/WasmController";
import * as MobIsHealthInterface from "./MobIsHealthInterface";

export default class MobTransformController extends WasmController<
  MobIsHealthInterface.State,
  MobIsHealthInterface.Event,
  MobIsHealthInterface.Data
> {
  constructor(
    getState: () => MobIsHealthInterface.State,
    setState: (state: MobIsHealthInterface.State, callback?: any) => void,
    window: any
  ) {
    super(getState, setState, window, MobIsHealthInterface.DataInitial);
  }

  handleEvent = async (e: MobIsHealthInterface.Event) => {
    console.log(e.message, e.params);
    switch (e.message) {
      // Setup
      case "DidMount":
        MobIsHealthInterface.DidMount(this, e.params);
        return;

      case "DidUpdate":
        MobIsHealthInterface.DidUpdate(this, e.params);
        return;

      case "HandleSetValueRegisterInfo":
        MobIsHealthInterface.HandleSetValueRegisterInfo(this, e.params);
        return;

      case "HandleRegister":
        MobIsHealthInterface.HandleRegister(this, e.params);
        return;

      case "HandleSetProfileInfo":
        MobIsHealthInterface.HandleSetProfileInfo(this, e.params);
        return;

      case "HandleLogin":
        MobIsHealthInterface.HandleLogin(this, e.params);
        return;

      case "HandleResetPassword":
        MobIsHealthInterface.HandleResetPassword(this, e.params);
        return;

      case "HandleSetLoginInfo":
        MobIsHealthInterface.HandleSetLoginInfo(this, e.params);
        return;

      case "HandleUpdateUserProfile":
        MobIsHealthInterface.HandleUpdateUserProfile(this, e.params);
        return;

      case "HandleSetInitialInnerScreen":
        MobIsHealthInterface.HandleSetInitialInnerScreen(this, e.params);
        return;

      case "HandleSignOut":
        MobIsHealthInterface.HandleSignOut(this, e.params);
        return;

      case "GetPatientDetail":
        MobIsHealthInterface.GetPatientDetail(this, e.params);
        return;

      case "HandleCreateAppointment":
        MobIsHealthInterface.HandleCreateAppointment(this, e.params);
        return;

      case "HandleGetAppointment":
        MobIsHealthInterface.HandleGetAppointment(this, e.params);
        return;

      case "HandleUpdateAppointment":
        MobIsHealthInterface.HandleUpdateAppointment(this, e.params);
        return;

      case "HandleCancelAppointment":
        MobIsHealthInterface.HandleCancelAppointment(this, e.params);
        return;

      case "HandleConfirmAppointment":
        MobIsHealthInterface.HandleConfirmAppointment(this, e.params);
        return;

      case "HandlePatientAppointmentView":
        MobIsHealthInterface.HandlePatientAppointmentView(this, e.params);
        return;

      case "HandleDivisionList":
        MobIsHealthInterface.HandleDivisionList(this, e.params);
        return;

      case "HandleDoctorList":
        MobIsHealthInterface.HandleDoctorList(this, e.params);
        return;

      case "HandleDivisionScheduleTimeList":
        MobIsHealthInterface.HandleDivisionScheduleTimeList(this, e.params);
        return;

      case "HandleDoctorDivisionServiceBlockSchedule":
        MobIsHealthInterface.HandleDoctorDivisionServiceBlockSchedule(
          this,
          e.params
        );
        return;

      case "HandleGetTimeSlotByDate":
        MobIsHealthInterface.HandleGetTimeSlotByDate(this, e.params);
        return;

      case "HandleRequestTokenWithEmail":
        MobIsHealthInterface.HandleRequestTokenWithEmail(this, e.params);
        return;

      case "HandleRequestToken":
        MobIsHealthInterface.HandleRequestToken(this, e.params);
        return;

      case "HandleGetUserProfile":
        MobIsHealthInterface.HandleGetUserProfile(this, e.params);
        return;

      case "HandleAutoHn":
        MobIsHealthInterface.HandleAutoHn(this, e.params);
        return;

      case "HandleCheckConsent":
        MobIsHealthInterface.HandleCheckConsent(this, e.params);
        return;
      
      case "HandleChangePasswordApi":
        MobIsHealthInterface.HandleChangePasswordApi(this, e.params);
        return;

      default:
        let testExhausitve: never = e;
    }
    console.log("-- Unhandled case");
  };
}
