import React, { useEffect, useState } from "react";

import "react-lib/apps/IsHealth/IHMobile/ishealth.scss";
import {
  Button,
  Divider,
  Form,
  Icon,
  Image,
  Input,
  InputProps,
} from "semantic-ui-react";

import { LoginInfoInfo } from "./TypeModal";
import { error } from "console";
// import { logoSquare } from "/images/ishealth/logo-square.png";

import InputFiledCircular from "react-lib/apps/IsHealth/IHMobile/InputFiledCircular";

type MobLoginTypes = {
  onEvent?: any;
  history: any;
  loginInfo?: LoginInfoInfo;
  loading?: boolean;
  setProps?: any;
};

const MobLogin = (props: MobLoginTypes) => {
  const [hindPassword, setHindPassword] = useState<boolean>(true);
  console.log("saika ~ MobLogin:");

  const handleGotoRegister = () => {
    props.history.push("/register");
  };

  const handleGoto = (goto: string) => {
    switch (goto) {
      case "register":
        HandleDefault();
        props.history.push("/register");
        break;
      case "forgotPass":
        HandleDefault();
        props.setProps("errorMessage", "");
        props.history.push("/forgot-password");
        break;
      case "login":
        HandleDefault();
        if (
          !props.loginInfo?.error?.username &&
          !props.loginInfo?.error?.password &&
          !props.loading
        ) {
          props.onEvent({
            message: "HandleLogin",
            params: { history: props.history },
          });
        }
        break;
    }
  };

  const HandleDefault = () => {
    props.setProps("profileInfo", {});
    props.setProps("registerInfo", {});
  };

  const handleClearError = (filed: string) => {
    let filedAll: any = props.loginInfo?.error || {};
    if (
      (filed === "password" || filed === "username") &&
      props.loginInfo?.error?.message
    ) {
      if (filedAll["message"]) {
        filedAll["message"] = "";
      }
    }

    if (filedAll[filed]) {
      filedAll[filed] = "";
    }

    props.onEvent({
      message: "HandleSetLoginInfo",
      params: { key: "error", value: { ...filedAll } },
    });
  };

  const handleCheckField = () => {
    let disable = false;
    if (props.loginInfo?.error?.username || props.loginInfo?.error?.password) {
      disable = true;
    }
    return disable;
  };

  return (
    <div className="content-header">
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Image
          style={{ width: "270px" }}
          src={"/images/ishealth/logo-square.png"}
        />
      </div>

      {/* Form Login */}

      <div className="content-detail">
        <div style={{ padding: "30px" }}>
          <div
            style={{
              color: "#B7D7E2",
              fontWeight: "bold",
              fontSize: "18px",
              marginBottom: "20px",
            }}
          >
            เข้าสู่ระบบ
          </div>

          <InputFiledCircular
            typeIcon="LOCK"
            error={
              props.loginInfo?.error?.username ||
              props.loginInfo?.error?.message ||
              ""
            }
            hideTextErr={!!props.loginInfo?.error?.message}
            label="ชื่อผู้ใช้งาน"
            value={props.loginInfo?.username || ""}
            onChange={(e: any, v: InputProps) => {
              props.onEvent({
                message: "HandleSetLoginInfo",
                params: { key: "username", value: v.value },
              });
            }}
            onClickClearError={() => handleClearError("username")}
          />

          <InputFiledCircular
            typeIcon="PASSWORD"
            error={
              props.loginInfo?.error?.password ||
              props.loginInfo?.error?.message ||
              ""
            }
            label="รหัสผ่าน"
            value={props.loginInfo?.password || ""}
            type={"password"}
            hindPassword={hindPassword}
            onClickHind={() => setHindPassword(!hindPassword)}
            onChange={(e: any, v: InputProps) => {
              props.onEvent({
                message: "HandleSetLoginInfo",
                params: { key: "password", value: v.value },
              });
            }}
            onClickClearError={() => handleClearError("password")}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div
              style={{
                fontSize: "14px",
                color: "var(--primary-font-color)",
                textAlign: "end",
                margin: "10px 0",
              }}
              onClick={() => handleGoto("forgotPass")}
            >
              ลืมรหัสผ่าน?
            </div>
          </div>

          <div
            style={{
              width: "100%",
              flex: "1",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div className="ishealth-buttom">
                <Button
                  disabled={handleCheckField()}
                  loading={props.loading}
                  onClick={() => handleGoto("login")}
                >
                  เข้าสู่ระบบ
                </Button>
              </div>
            </div>
            <Divider
              horizontal
              inverted
              style={{ color: "#A2BFC9", fontSize: "14px" }}
            >
              หรือ
            </Divider>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div className="ishealth-buttom">
                <Button onClick={() => handleGoto("register")}>
                  ลงทะเบียน
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MobLogin.displayname = "Login";
export default MobLogin;
