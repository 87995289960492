
/* Generated apis ---------------------- */
import { to } from 'react-lib/utils'
import BaseService from  'react-lib/apis/services/BaseService'
import config from '../../config/config'
const HOST = `${config.API_HOST}`
/* [] */
/* params:  */
/* data:  */
const preflight : 
{
  get: ({params, apiToken, extra, division_id, device_id}:
             {
                params?: any,
                apiToken?: any,
                extra?: any,
                division_id?: any,
                device_id?: any,
            }) => any,
  post:   ({params, data, apiToken, extra}:
            { 
            params?: any,
            data?: any,
            apiToken?: any,
            extra?: any
        }) => any,
  put:   ({params, data, apiToken, extra}:
             { 
                params?: any,
                data?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  patch:    ({params, data, apiToken, extra}:
             { 
                params?: any,
                data?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  get: async ({params, apiToken, extra, division_id, device_id}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    if (division_id) {
      base.client.defaults.headers["division"] = division_id
    }
    if (device_id) {
      base.client.defaults.headers["device"] = device_id
    }
    console.log("base.client.defaults.headers", base.client.defaults.headers)

    const result = await to(base.client.get(`${HOST}/preflight/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  post: async ({params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.post(`${HOST}/preflight/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  put: async ({params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.put(`${HOST}/preflight/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  patch: async ({params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.patch(`${HOST}/preflight/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

export default preflight