
/* Generated apis ---------------------- */
import { to } from 'react-lib/utils'
import BaseService from  'react-lib/apis/services/BaseService'
import config from '../../config/config'
import CentralLabTestSerializer from '../types/CentralLabTestSerializer_apps_LAB'


const HOST = `${config.API_HOST}`
/* ['generics.ListAPIView'] */
/* params: 'code', 'lab_code', 'name', 'encounter', 'emr', 'progression_cycle', 'lab_speciality', 'lab_division', 'only_blood_bank' */
/* data:  */
const CentralLabTestList : 
{
  list:     ({params, apiToken, extra}:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
    const result = await to(base.client.get(`${HOST}/apis/LAB/central-lab-test/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

export default CentralLabTestList

