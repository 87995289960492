export default function toPDFMakeData(props: any) {
  return {
    content: [
      {
        alignment: `center`,
        bold: `true`,
        width: `auto`,
        fontSize: `18`,
        decorationColor: ``,
        text: `หนังสือแสดงการยินยอมตรวจหาสารเสพติด`,
        pageBreak: ``,
        color: ``,
        decoration: ``,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
      },
      {
        decorationColor: ``,
        decorationStyle: ``,
        pageBreak: ``,
        width: 0,
        bold: false,
        preserveLeadingSpaces: true,
        color: ``,
        decoration: ``,
        fontSize: 15,
        alignment: `left`,
        text: ` `,
      },
      {
        decorationColor: ``,
        decoration: ``,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        alignment: `left`,
        color: ``,
        pageBreak: ``,
        text: `กรอกข้อมูลโดยผู้ป่วย ก่อนทำการเก็บตัวอย่าง`,
        fontSize: 15,
        width: `auto`,
        bold: false,
      },
      {
        columns: [
          {
            fontSize: 15,
            decoration: ``,
            color: ``,
            alignment: `left`,
            width: 30,
            text: ``,
            bold: false,
            pageBreak: ``,
            decorationColor: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
          },
          {
            text: `ข้าพเจ้ายินยอมให้ บริษัท เซเปี้ยนซ์ เมดิเคชั่น จำกัด “โรงพยาบาล” ทำการเก็บตัวอย่างปัสสาวะของข้าพเจ้าเพื่อ`,
            decorationStyle: ``,
            width: `auto`,
            decorationColor: ``,
            decoration: ``,
            pageBreak: ``,
            alignment: `left`,
            bold: false,
            color: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
          },
        ],
      },
      {
        decorationColor: ``,
        width: `auto`,
        color: ``,
        alignment: `left`,
        decorationStyle: ``,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        bold: false,
        text: `ตรวจหาสารเสพติดตามรายการต่อไปนี้`,
        fontSize: 15,
        decoration: ``,
      },
      {
        columns: [
          {
            decorationColor: ``,
            pageBreak: ``,
            bold: false,
            text: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decorationStyle: ``,
            width: 35,
            decoration: ``,
            color: ``,
          },
          {
            decorationColor: ``,
            bold: false,
            width: `auto`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            pageBreak: ``,
            decoration: ``,
            text: `[  ]`,
            fontSize: 15,
            color: ``,
            alignment: `left`,
          },
          {
            decorationColor: ``,
            pageBreak: ``,
            decorationStyle: ``,
            alignment: `left`,
            decoration: ``,
            fontSize: 15,
            bold: false,
            text: ``,
            width: 5,
            color: ``,
            preserveLeadingSpaces: true,
          },
          {
            decoration: ``,
            alignment: `left`,
            fontSize: 15,
            text: `บาร์บิทูเรต`,
            color: ``,
            pageBreak: ``,
            decorationStyle: ``,
            width: `auto`,
            decorationColor: ``,
            bold: false,
            preserveLeadingSpaces: true,
          },
          {
            bold: false,
            pageBreak: ``,
            text: ``,
            width: 190,
            color: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            fontSize: 15,
            alignment: `left`,
            decorationStyle: ``,
          },
          {
            width: `auto`,
            color: ``,
            pageBreak: ``,
            fontSize: 15,
            decorationStyle: ``,
            bold: false,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            text: `[  ]`,
            decoration: ``,
          },
          {
            color: ``,
            text: ``,
            decorationColor: ``,
            alignment: `left`,
            width: 5,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decoration: ``,
            bold: false,
            pageBreak: ``,
          },
          {
            decoration: ``,
            color: ``,
            alignment: `left`,
            decorationColor: ``,
            pageBreak: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            text: `เบนโซไดอะซิปีน`,
            width: `auto`,
            decorationStyle: ``,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            fontSize: 15,
            decorationColor: ``,
            decoration: ``,
            width: 35,
            bold: false,
            decorationStyle: ``,
            color: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            text: ``,
          },
          {
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationStyle: ``,
            text: `[  ]`,
            width: `auto`,
            color: ``,
            fontSize: 15,
            decoration: ``,
            decorationColor: ``,
            bold: false,
            alignment: `left`,
          },
          {
            width: 5,
            text: ``,
            decorationStyle: ``,
            decorationColor: ``,
            decoration: ``,
            color: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            bold: false,
            alignment: `left`,
          },
          {
            decorationStyle: ``,
            bold: false,
            decorationColor: ``,
            fontSize: 15,
            width: `auto`,
            text: `โคเคน`,
            alignment: `left`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            color: ``,
          },
          {
            preserveLeadingSpaces: true,
            decorationColor: ``,
            pageBreak: ``,
            width: 208,
            bold: false,
            alignment: `left`,
            text: ``,
            color: ``,
            decoration: ``,
            decorationStyle: ``,
            fontSize: 15,
          },
          {
            alignment: `left`,
            bold: false,
            decorationStyle: ``,
            decorationColor: ``,
            decoration: ``,
            width: `auto`,
            pageBreak: ``,
            color: ``,
            text: `[  ]`,
            preserveLeadingSpaces: true,
            fontSize: 15,
          },
          {
            preserveLeadingSpaces: true,
            fontSize: 15,
            color: ``,
            alignment: `left`,
            text: ``,
            bold: false,
            decoration: ``,
            pageBreak: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
          },
          {
            fontSize: 15,
            decorationColor: ``,
            pageBreak: ``,
            text: `เมทาโดน`,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            bold: false,
            color: ``,
            alignment: `left`,
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            width: 35,
            decorationColor: ``,
            color: ``,
            decorationStyle: ``,
            text: ``,
            pageBreak: ``,
            fontSize: 15,
            bold: false,
          },
          {
            decoration: ``,
            decorationStyle: ``,
            alignment: `left`,
            color: ``,
            decorationColor: ``,
            bold: false,
            width: `auto`,
            fontSize: 15,
            pageBreak: ``,
            text: `[  ]`,
            preserveLeadingSpaces: true,
          },
          {
            decoration: ``,
            alignment: `left`,
            bold: false,
            decorationColor: ``,
            color: ``,
            decorationStyle: ``,
            width: 5,
            pageBreak: ``,
            text: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
          },
          {
            text: `แคนาบินอยด์/เตตร้าไฮโดรแคนนาบินอล/กัญชา`,
            decoration: ``,
            pageBreak: ``,
            width: 186,
            color: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            bold: false,
            fontSize: 15,
            decorationColor: ``,
            decorationStyle: ``,
          },
          {
            text: ``,
            decorationColor: ``,
            decorationStyle: ``,
            pageBreak: ``,
            fontSize: 15,
            color: ``,
            bold: false,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decoration: ``,
            width: 47,
          },
          {
            width: `auto`,
            decorationStyle: ``,
            bold: false,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationColor: ``,
            color: ``,
            pageBreak: ``,
            alignment: `left`,
            text: `[  ]`,
          },
          {
            preserveLeadingSpaces: true,
            color: ``,
            alignment: `left`,
            decorationColor: ``,
            decoration: ``,
            pageBreak: ``,
            bold: false,
            text: ``,
            decorationStyle: ``,
            fontSize: 15,
            width: 5,
          },
          {
            preserveLeadingSpaces: true,
            alignment: `left`,
            width: `auto`,
            decorationStyle: ``,
            pageBreak: ``,
            decoration: ``,
            decorationColor: ``,
            bold: false,
            color: ``,
            text: `โอปิแอล`,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            decorationColor: ``,
            fontSize: 15,
            color: ``,
            width: 35,
            preserveLeadingSpaces: true,
            text: ``,
            alignment: `left`,
            bold: false,
            decorationStyle: ``,
            pageBreak: ``,
          },
          {
            fontSize: 15,
            color: ``,
            bold: false,
            decoration: ``,
            width: `auto`,
            decorationColor: ``,
            decorationStyle: ``,
            alignment: `left`,
            text: `[  ]`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
          },
          {
            bold: false,
            alignment: `left`,
            preserveLeadingSpaces: true,
            width: 5,
            decoration: ``,
            decorationColor: ``,
            decorationStyle: ``,
            text: ``,
            pageBreak: ``,
            fontSize: 15,
            color: ``,
          },
          {
            pageBreak: ``,
            text: `แอมเฟตามีนและ/หรือเมทแอมเฟตามีน`,
            fontSize: 15,
            decorationColor: ``,
            bold: false,
            decoration: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            color: ``,
            alignment: `left`,
          },
          {
            fontSize: 15,
            width: 80,
            decorationColor: ``,
            color: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            pageBreak: ``,
            text: ``,
            decorationStyle: ``,
            bold: false,
            decoration: ``,
          },
          {
            alignment: `left`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decoration: ``,
            text: `[  ]`,
            decorationStyle: ``,
            bold: false,
            width: `auto`,
            color: ``,
            fontSize: 15,
            decorationColor: ``,
          },
          {
            preserveLeadingSpaces: true,
            text: ``,
            decorationStyle: ``,
            color: ``,
            width: 5,
            fontSize: 15,
            pageBreak: ``,
            bold: false,
            decoration: ``,
            decorationColor: ``,
            alignment: `left`,
          },
          {
            preserveLeadingSpaces: true,
            bold: false,
            alignment: `left`,
            decoration: ``,
            color: ``,
            text: `อื่นๆ โปรดระบุ .................................................`,
            decorationColor: ``,
            fontSize: 15,
            width: `auto`,
            pageBreak: ``,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            bold: false,
            decorationColor: ``,
            decorationStyle: ``,
            alignment: `left`,
            width: `auto`,
            decoration: ``,
            color: ``,
            fontSize: 15,
            text: `บริษัทผู้ร้องขอให้ทำการตรวจ .......................................................................................................................................................`,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            pageBreak: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            fontSize: 15,
            width: `auto`,
            text: `ชื่อผู้ติดต่อ ...........................................................................................`,
            decoration: ``,
            decorationStyle: ``,
            color: ``,
          },
          {
            alignment: `left`,
            color: ``,
            width: `auto`,
            decorationColor: ``,
            text: `		`,
            decoration: ``,
            decorationStyle: ``,
            fontSize: 15,
            pageBreak: ``,
            bold: false,
            preserveLeadingSpaces: true,
          },
          {
            decoration: ``,
            pageBreak: ``,
            bold: false,
            fontSize: 15,
            decorationColor: ``,
            color: ``,
            decorationStyle: ``,
            text: `หมายเลขโทรศัพท์.............................................................`,
            preserveLeadingSpaces: true,
            width: `auto`,
            alignment: `right`,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            decorationColor: ``,
            pageBreak: ``,
            text: ``,
            decorationStyle: ``,
            bold: false,
            decoration: ``,
            color: ``,
            width: 30,
            alignment: `left`,
            preserveLeadingSpaces: true,
          },
          {
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decoration: ``,
            text: `ข้าพเจ้าทราบดีและยินยอมให้โรงพยาบาลทำการเก็บตัวอย่างปัสสาวะของข้าพเจ้าเพิ่มเติม เพื่อตรวจหาสารเสพติด`,
            fontSize: 15,
            decorationColor: ``,
            bold: false,
            pageBreak: ``,
            alignment: `left`,
            width: `auto`,
            color: ``,
          },
        ],
      },
      {
        text: `ดังกล่าวซ้ำ หากพบว่าในขั้นตอนการเก็บตัวอย่างมีการเปลี่ยน หรือมีการกระทำให้เกิดการเปลี่ยนแปลงใดๆ ซึ่งมีผลต่อผล`,
        fontSize: 15,
        decoration: ``,
        alignment: `left`,
        bold: false,
        decorationStyle: ``,
        width: `auto`,
        color: ``,
        pageBreak: ``,
        decorationColor: ``,
        preserveLeadingSpaces: true,
      },
      {
        width: `auto`,
        margin: [0, 0, 0, 0],
        preserveLeadingSpaces: true,
        pageBreak: ``,
        bold: false,
        color: ``,
        alignment: `left`,
        fontSize: 15,
        decoration: ``,
        decorationStyle: ``,
        decorationColor: ``,
        text: `การตรวจหาสารเสพติดดังกล่าว`,
      },
      {
        columns: [
          {
            fontSize: 15,
            alignment: `left`,
            text: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            bold: false,
            width: 30,
            decorationColor: ``,
            decoration: ``,
            decorationStyle: ``,
            color: ``,
          },
          {
            pageBreak: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            color: ``,
            bold: false,
            decorationStyle: ``,
            alignment: `left`,
            decoration: ``,
            text: `ข้าพเจ้ายินยอมให้โรงพยาบาลตรวจสอบผลการตรวจตัวอย่างปัสสาวะของข้าพเจ้าและรายงานผลดังกล่าวไปยังบริษัท`,
            decorationColor: ``,
            width: `auto`,
          },
        ],
      },
      {
        text: `ที่ร้องขอตามที่ระบุมาข้างต้น`,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        decorationColor: ``,
        decoration: ``,
        color: ``,
        bold: false,
        fontSize: 15,
        alignment: `left`,
        width: `auto`,
      },
      {
        columns: [
          {
            alignment: `left`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            bold: false,
            decorationStyle: ``,
            width: 30,
            decorationColor: ``,
            decoration: ``,
            color: ``,
            text: ``,
            pageBreak: ``,
          },
          {
            bold: false,
            decoration: ``,
            pageBreak: ``,
            decorationStyle: ``,
            color: ``,
            decorationColor: ``,
            fontSize: 15,
            text: `ข้าพเจ้าขอแจ้งรายการยา วิตามิน สมุนไพร ผลิตภัณฑ์เสริมอาหารทั้งหมด ที่รับประทานอยู่ในปัจจุบัน รวมถึงที่ได้`,
            alignment: `left`,
            width: `auto`,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        color: ``,
        width: `auto`,
        pageBreak: ``,
        fontSize: 15,
        alignment: `left`,
        bold: false,
        text: `รับประทานมาแล้ว ในช่วงเวลา 30 (สามสิบ) วัน ก่อนการเก็บตัวอย่างปัสสาวะ (ไม่ว่ารับประทานตามคำสั่งแพทย์หรือซื้อ`,
        preserveLeadingSpaces: true,
        decorationColor: ``,
        decoration: ``,
        decorationStyle: ``,
      },
      {
        width: `auto`,
        preserveLeadingSpaces: true,
        margin: [0, 0, 0, 0],
        color: ``,
        decoration: ``,
        alignment: `left`,
        decorationColor: ``,
        bold: false,
        text: `รับประทานเอง)`,
        pageBreak: ``,
        decorationStyle: ``,
        fontSize: 15,
      },
      {
        columns: [
          {
            width: `auto`,
            decoration: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            color: ``,
            bold: false,
            decorationColor: ``,
            decorationStyle: ``,
            fontSize: 15,
            text: `........................................................................................................................................................................................................ `,
            alignment: `center`,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            color: ``,
            width: `auto`,
            alignment: `left`,
            pageBreak: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            text: `........................................................................................................................................................................................................ `,
            decorationStyle: ``,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            fontSize: 15,
            text: `........................................................................................................................................................................................................ `,
            decorationColor: ``,
            bold: false,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decoration: ``,
            width: `auto`,
            decorationStyle: ``,
            color: ``,
          },
        ],
      },
      {
        decoration: ``,
        preserveLeadingSpaces: true,
        alignment: `left`,
        fontSize: 15,
        width: `auto`,
        text: ` `,
        bold: false,
        decorationColor: ``,
        pageBreak: ``,
        color: ``,
        decorationStyle: ``,
      },
      {
        columns: [
          {
            width: 200,
            text: `ลงนาม.................................................................`,
            color: ``,
            decorationColor: ``,
            alignment: `center`,
            pageBreak: ``,
            bold: false,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationStyle: ``,
          },
          {
            text: ``,
            alignment: `left`,
            bold: false,
            decoration: ``,
            width: 80,
            decorationColor: ``,
            pageBreak: ``,
            color: ``,
            fontSize: 15,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
          },
          {
            fontSize: 15,
            text: `พยาน 1 ลงนาม........................................................`,
            preserveLeadingSpaces: true,
            bold: false,
            decoration: ``,
            alignment: `center`,
            width: 200,
            decorationStyle: ``,
            color: ``,
            pageBreak: ``,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            alignment: `center`,
            pageBreak: ``,
            decorationColor: ``,
            width: 200,
            bold: false,
            color: ``,
            decoration: ``,
            fontSize: 15,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            text: `(.......................................................................)`,
          },
          {
            decorationStyle: ``,
            fontSize: 15,
            decoration: ``,
            bold: false,
            alignment: `left`,
            width: 80,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            color: ``,
            text: ``,
            decorationColor: ``,
          },
          {
            preserveLeadingSpaces: true,
            fontSize: 15,
            text: `(.......................................................................)`,
            decorationStyle: ``,
            decoration: ``,
            width: 200,
            color: ``,
            pageBreak: ``,
            alignment: `center`,
            decorationColor: ``,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            width: 200,
            pageBreak: ``,
            bold: false,
            fontSize: 15,
            alignment: `center`,
            decorationColor: ``,
            decoration: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            text: `วันที่...............................เวลา...................`,
          },
          {
            text: ``,
            bold: false,
            alignment: `left`,
            decorationColor: ``,
            width: 80,
            fontSize: 15,
            decorationStyle: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            color: ``,
            pageBreak: ``,
          },
          {
            bold: false,
            width: 200,
            text: `พยาน 2 ลงนาม........................................................`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decoration: ``,
            fontSize: 15,
            alignment: `center`,
            decorationColor: ``,
            color: ``,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            preserveLeadingSpaces: true,
            text: ``,
            fontSize: 15,
            width: 200,
            decoration: ``,
            decorationColor: ``,
            alignment: `left`,
            decorationStyle: ``,
            pageBreak: ``,
            color: ``,
          },
          {
            alignment: `left`,
            decorationColor: ``,
            fontSize: 15,
            text: ``,
            decorationStyle: ``,
            color: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            bold: false,
            width: 80,
            decoration: ``,
          },
          {
            decoration: ``,
            text: `(.......................................................................)`,
            fontSize: 15,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            width: 200,
            pageBreak: ``,
            alignment: `center`,
            bold: false,
            color: ``,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            width: 280,
            fontSize: 15,
            preserveLeadingSpaces: true,
            color: ``,
            text: ``,
            decoration: ``,
            bold: false,
            decorationColor: ``,
            alignment: `left`,
            decorationStyle: ``,
            pageBreak: ``,
          },
          {
            bold: false,
            decoration: ``,
            preserveLeadingSpaces: true,
            text: `(กรณีพิมพ์รายนิ้วมือ/ยินยอมทางโทรศัพท์)`,
            decorationColor: ``,
            alignment: `center`,
            fontSize: 15,
            decorationStyle: ``,
            width: 200,
            pageBreak: ``,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            text: `การแปลและให้ข้อมูลโดยผู้แปลภาษา`,
            fontSize: 15,
            width: `auto`,
            alignment: `left`,
            preserveLeadingSpaces: true,
            color: ``,
            decoration: `underline`,
            bold: `true`,
            decorationStyle: ``,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            text: ``,
            width: `auto`,
            decoration: ``,
            decorationStyle: ``,
            fontSize: 15,
            alignment: `left`,
            pageBreak: ``,
            bold: false,
            color: ``,
            preserveLeadingSpaces: true,
          },
          {
            text: `        ข้าพเจ้าได้แปลหนังสือแสดงความยินยอมตรวจหาสารเสพติด (Consent Drug Screen Test) รวมทั้งข้อมูลซึ่งแพทย์ได้อธิบาย ให้ผู้ป่วย/ผู้แทนผู้ป่วยทราบ`,
            pageBreak: ``,
            fontSize: 15,
            alignment: `left`,
            bold: false,
            decoration: ``,
            decorationStyle: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            color: ``,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            bold: false,
            decoration: ``,
            decorationStyle: ``,
            text: `ภาษาที่แปล............................................`,
            preserveLeadingSpaces: true,
            alignment: `center`,
            pageBreak: ``,
            color: ``,
            decorationColor: ``,
            width: 200,
          },
          {
            color: ``,
            fontSize: 15,
            decorationColor: ``,
            alignment: `left`,
            decorationStyle: ``,
            decoration: ``,
            bold: false,
            pageBreak: ``,
            width: 80,
            text: ``,
            preserveLeadingSpaces: true,
          },
          {
            pageBreak: ``,
            color: ``,
            text: `ผู้แปลลงนาม.........................................................`,
            decorationStyle: ``,
            bold: false,
            decoration: ``,
            width: 200,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            alignment: `center`,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            decoration: ``,
            text: ``,
            pageBreak: ``,
            decorationColor: ``,
            color: ``,
            alignment: `left`,
            bold: false,
            fontSize: 15,
            width: 280,
            preserveLeadingSpaces: true,
          },
          {
            text: `(................................................................)`,
            width: 200,
            decoration: ``,
            color: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            bold: false,
            alignment: `center`,
            pageBreak: ``,
            decorationColor: ``,
            decorationStyle: ``,
          },
        ],
      },
      {
        color: ``,
        decorationStyle: ``,
        alignment: `left`,
        pageBreak: `before`,
        fontSize: 15,
        decorationColor: ``,
        decoration: ``,
        width: `auto`,
        text: `สถานะของผู้ลงนาม (ตามประมวลกฎหมายแพ่งและพาณิชย์)`,
        preserveLeadingSpaces: true,
        bold: `true`,
      },
      {
        columns: [
          {
            color: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decoration: ``,
            text: `[  ]`,
            decorationColor: ``,
            decorationStyle: ``,
            width: `auto`,
            pageBreak: ``,
            bold: false,
            alignment: `center`,
          },
          {
            bold: false,
            decorationStyle: ``,
            fontSize: 15,
            width: 5,
            text: ``,
            decorationColor: ``,
            decoration: ``,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            color: ``,
          },
          {
            width: `auto`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            alignment: `left`,
            fontSize: 15,
            decorationColor: ``,
            bold: false,
            decoration: ``,
            text: `1. ผู้ป่วย ซึ่งบรรลุนิติภาวะ และมีสติสัมปชัญญะสมบูรณ์`,
            color: ``,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            fontSize: 15,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            width: `auto`,
            text: `[  ]`,
            decorationColor: ``,
            pageBreak: ``,
            alignment: `center`,
            bold: false,
          },
          {
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decoration: ``,
            pageBreak: ``,
            decorationColor: ``,
            fontSize: 15,
            color: ``,
            alignment: `left`,
            width: 5,
            bold: false,
            text: ``,
          },
          {
            decorationStyle: ``,
            decorationColor: ``,
            text: `2. สามีหรือภรรยาตามกฎหมาย กรณีที่ผู้ป่วยไม่มีสติสัมปชัญญะ (ไม่รู้สึกตัว)`,
            color: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            width: `auto`,
            fontSize: 15,
            bold: false,
            alignment: `left`,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            alignment: `center`,
            color: ``,
            bold: false,
            decorationStyle: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decoration: ``,
            text: `[  ]`,
            pageBreak: ``,
            width: `auto`,
          },
          {
            preserveLeadingSpaces: true,
            bold: false,
            width: 5,
            decoration: ``,
            color: ``,
            decorationStyle: ``,
            fontSize: 15,
            pageBreak: ``,
            text: ``,
            alignment: `left`,
            decorationColor: ``,
          },
          {
            bold: false,
            text: `3. ผู้ใช้อำนาจปกครองกรณีผู้ป่วยอายุต่ำกว่า 20 ปี บริบูรณ์ที่ยังไม่บรรลุนิติภาวะ`,
            color: ``,
            decoration: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decorationStyle: ``,
            width: `auto`,
            alignment: `left`,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            bold: false,
            decoration: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            color: ``,
            alignment: `center`,
            pageBreak: ``,
            decorationStyle: ``,
            text: `[  ]`,
            decorationColor: ``,
          },
          {
            text: ` `,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            fontSize: 15,
            decorationColor: ``,
            color: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            text: `4. ผู้พิทักษ์ กรณีผู้ป่วยเป็นคนเสมือนไร้ความสามารถ  (ตามคำสั่งศาล)`,
            bold: false,
            pageBreak: ``,
            decoration: ``,
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            text: `[  ]`,
            preserveLeadingSpaces: true,
            alignment: `center`,
            bold: false,
            decorationStyle: ``,
            decoration: ``,
            pageBreak: ``,
            fontSize: 15,
            color: ``,
            width: `auto`,
            decorationColor: ``,
          },
          {
            text: ``,
            alignment: `left`,
            bold: false,
            pageBreak: ``,
            decorationColor: ``,
            color: ``,
            decorationStyle: ``,
            decoration: ``,
            width: 5,
            preserveLeadingSpaces: true,
            fontSize: 15,
          },
          {
            pageBreak: ``,
            alignment: `left`,
            decorationColor: ``,
            width: `auto`,
            text: `5. ผู้อนุบาล กรณีผู้ป่วยเป็นคนไร้ความสามารถ (ตามคำสั่งศาล)`,
            decoration: ``,
            bold: false,
            fontSize: 15,
            color: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            width: 18,
            fontSize: 15,
            alignment: `left`,
            text: ``,
            decorationStyle: ``,
            decorationColor: ``,
            bold: false,
            decoration: ``,
          },
          {
            decoration: ``,
            alignment: `left`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            width: `auto`,
            decorationStyle: ``,
            color: ``,
            decorationColor: ``,
            bold: false,
            text: `*กรณีข้อ 2-5 ให้ขอสำเนาเอกสารบัตรประชาชน หนังสือเดินทาง เอกสารที่ออกโดยราชการจากผู้ลงนามแทน โดยปกปิดข้อมูลศาสนาและหมู่เลือด ซึ่งปรากฎบนสำเนาดังกล่าว (หากมี) พร้อมลงนามรับรองสำเนาถูกต้อง`,
          },
        ],
      },
      {
        alignment: `left`,
        color: ``,
        decorationStyle: ``,
        fontSize: 15,
        width: `auto`,
        decorationColor: ``,
        decoration: ``,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        text: ` `,
        bold: false,
      },
      {
        columns: [
          {
            bold: false,
            color: ``,
            preserveLeadingSpaces: true,
            text: `ความสัมพันธ์กับผู้ป่วย...............................................................................................................................`,
            decorationStyle: ``,
            decoration: ``,
            pageBreak: ``,
            width: `auto`,
            alignment: `left`,
            fontSize: 15,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            decorationStyle: ``,
            color: ``,
            text: `เลขที่บัตรประจำตัวประชาชนผู้ลงนามแทนผู้ป่วย.....................................................................................`,
            width: `auto`,
            alignment: `left`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            pageBreak: ``,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decoration: ``,
            decorationStyle: ``,
            bold: false,
            alignment: `left`,
            color: ``,
            pageBreak: ``,
            text: `เบอร์โทรศัพท์............................................................................................................................................`,
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            fontSize: 15,
            color: ``,
            bold: false,
            decorationColor: ``,
            decorationStyle: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            text: `อีเมล..........................................................................................................................................................`,
            width: `auto`,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            width: `auto`,
            decoration: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            color: ``,
            pageBreak: ``,
            text: ``,
            alignment: `left`,
            decorationStyle: ``,
            decorationColor: ``,
            bold: false,
          },
          {
            decoration: ``,
            color: ``,
            fontSize: 15,
            decorationColor: ``,
            text: `กรอกข้อมูลเมื่อเสร็จสิ้นขั้นตอนการเก็บตัวอย่าง`,
            pageBreak: `before`,
            preserveLeadingSpaces: true,
            bold: `true`,
            width: `auto`,
            alignment: `left`,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            decorationColor: ``,
            alignment: `left`,
            color: ``,
            text: ``,
            width: `auto`,
            decoration: ``,
            preserveLeadingSpaces: true,
            bold: false,
            pageBreak: ``,
            decorationStyle: ``,
          },
          {
            bold: `true`,
            pageBreak: ``,
            text: `สำหรับเจ้าหน้าที่ผู้สังเกตการณ์เก็บตัวอย่างปัสสาวะ`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            fontSize: 15,
            color: ``,
            decoration: ``,
            width: `auto`,
            decorationColor: ``,
            alignment: `left`,
          },
        ],
      },
      {
        preserveLeadingSpaces: true,
        width: `auto`,
        pageBreak: ``,
        text: `ข้าพเจ้าได้ปฏิบัติตามขั้นตอนการการเก็บปัสสาวะตรวจสารเสพติด ดังต่อไปนี้`,
        decorationColor: ``,
        decoration: ``,
        color: ``,
        bold: false,
        decorationStyle: ``,
        alignment: `left`,
        fontSize: 15,
      },
      {
        columns: [
          {
            bold: false,
            width: `auto`,
            decorationStyle: ``,
            fontSize: 15,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            text: `[  ]`,
            decoration: ``,
            color: ``,
            decorationColor: ``,
          },
          {
            decoration: ``,
            alignment: `left`,
            bold: false,
            width: 5,
            decorationColor: ``,
            pageBreak: ``,
            decorationStyle: ``,
            text: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            color: ``,
          },
          {
            alignment: `left`,
            decorationColor: ``,
            width: `auto`,
            decoration: ``,
            text: `ข้าพเจ้าได้ตรวจสอบตัวตนของผู้ป่วยโดย`,
            fontSize: 15,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decorationStyle: ``,
            color: ``,
          },
          {
            color: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            pageBreak: ``,
            text: ``,
            decoration: ``,
            fontSize: 15,
            width: 5,
            decorationStyle: ``,
            alignment: `left`,
          },
          {
            fontSize: 15,
            width: `auto`,
            pageBreak: ``,
            decorationStyle: ``,
            decorationColor: ``,
            alignment: `left`,
            preserveLeadingSpaces: `true`,
            color: ``,
            text: `[  ]`,
            decoration: ``,
            bold: false,
          },
          {
            alignment: `left`,
            fontSize: 15,
            decorationColor: ``,
            decoration: ``,
            text: ``,
            pageBreak: ``,
            color: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            bold: false,
            width: 5,
          },
          {
            decorationStyle: ``,
            decorationColor: ``,
            color: ``,
            preserveLeadingSpaces: true,
            bold: false,
            width: `auto`,
            text: `บัตรประชาชน`,
            decoration: ``,
            fontSize: 15,
            alignment: `left`,
            pageBreak: ``,
          },
          {
            bold: false,
            decoration: ``,
            text: `  `,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            pageBreak: ``,
            fontSize: 15,
            decorationColor: ``,
            alignment: `left`,
            width: 5,
            color: ``,
          },
          {
            decoration: ``,
            width: `auto`,
            pageBreak: ``,
            color: ``,
            alignment: `left`,
            bold: false,
            fontSize: 15,
            text: `[  ]`,
            decorationColor: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
          },
          {
            text: ``,
            alignment: `left`,
            fontSize: 15,
            decoration: ``,
            width: 5,
            pageBreak: ``,
            color: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decorationColor: ``,
            bold: false,
          },
          {
            bold: false,
            decoration: ``,
            alignment: `left`,
            fontSize: 15,
            decorationStyle: ``,
            color: ``,
            text: `หนังสือเดินทาง เลขที่...........................................................`,
            pageBreak: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            text: `[  ]`,
            color: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            alignment: `center`,
            decorationColor: ``,
            width: `auto`,
            decorationStyle: ``,
            bold: false,
            decoration: ``,
          },
          {
            bold: false,
            decoration: ``,
            pageBreak: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            color: ``,
            fontSize: 15,
            alignment: `left`,
            width: 5,
            text: ``,
            decorationStyle: ``,
          },
          {
            decorationColor: ``,
            decoration: ``,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            bold: false,
            color: ``,
            text: `ข้าพเจ้าได้แจ้งขั้นตอนการตรวจให้ผู้ป่วยทราบและทบทวนความเข้าใจของผู้ป่วย`,
            fontSize: 15,
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            text: `[  ]`,
            fontSize: 15,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            alignment: `center`,
            bold: false,
            color: ``,
            decoration: ``,
            decorationColor: ``,
            pageBreak: ``,
          },
          {
            pageBreak: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            color: ``,
            text: ``,
            width: 5,
            bold: false,
            alignment: `left`,
            fontSize: 15,
            decoration: ``,
          },
          {
            color: ``,
            alignment: `left`,
            decoration: ``,
            decorationStyle: ``,
            fontSize: 15,
            pageBreak: ``,
            bold: false,
            width: `auto`,
            text: `ข้าพเจ้าได้ป้องกันการปนเปื้อนในการเก็บปัสสาวะ ด้วยการให้ผู้ป่วยเปลี่ยนชุดโรงพยาบาลตรวจสอบชุดที่ใส่ด้วยมือหยดสีลงไป ในชักโครก และล็อควาล์วน้ำ`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            text: `[  ]`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            bold: false,
            alignment: `center`,
            decorationStyle: ``,
            decorationColor: ``,
            pageBreak: ``,
            width: `auto`,
            color: ``,
          },
          {
            pageBreak: ``,
            decorationStyle: ``,
            bold: false,
            text: ``,
            width: 5,
            color: ``,
            decorationColor: ``,
            decoration: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            fontSize: 15,
          },
          {
            bold: false,
            decorationStyle: ``,
            pageBreak: ``,
            text: `ผู้ป่วยใช้เวลาเก็บถาดในระยะเวลาที่กำหนด (3 นาที) และไม่กดชักโครกระหว่างเก็บตัวอย่าง`,
            width: `auto`,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationColor: ``,
            fontSize: 15,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            pageBreak: ``,
            fontSize: 15,
            bold: false,
            decorationStyle: ``,
            decoration: ``,
            text: `[  ]`,
            color: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            alignment: `center`,
          },
          {
            text: ` `,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            decorationColor: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decoration: ``,
            width: `auto`,
            color: ``,
            bold: false,
            alignment: `left`,
            text: `ปัสสาวะมีสีปกติ (เหลืองใส) และอุณหภูมิของปัสสาวะวัดได้ที่`,
            fontSize: 15,
            decorationStyle: ``,
          },
          {
            decoration: ``,
            color: ``,
            text: `..................................องศาเซลเซียส (อุณหภูมิหลังเก็บเสร็จต้อง`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            bold: false,
            pageBreak: ``,
            decorationColor: ``,
            width: `auto`,
            alignment: `left`,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            decorationColor: ``,
            text: ``,
            decorationStyle: ``,
            color: ``,
            pageBreak: ``,
            bold: false,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decoration: ``,
            width: 18,
          },
          {
            color: ``,
            text: `อยู่ระหว่าง 33-37 องศาเซลเซียส)`,
            bold: false,
            width: `auto`,
            decorationStyle: ``,
            decoration: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
          },
        ],
      },
      {
        columns: [
          {
            alignment: `center`,
            color: ``,
            pageBreak: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decoration: ``,
            width: `auto`,
            decorationStyle: ``,
            bold: false,
            decorationColor: ``,
            text: `[  ]`,
          },
          {
            alignment: `left`,
            decorationColor: ``,
            decoration: ``,
            decorationStyle: ``,
            width: 5,
            fontSize: 15,
            bold: false,
            text: ``,
            pageBreak: ``,
            color: ``,
            preserveLeadingSpaces: true,
          },
          {
            text: `ข้าพเจ้าทำการผนึกสติ๊กเกอร์ปิดภาชนะบรรจุปัสสาวะต่อหน้าผู้ป่วย`,
            alignment: `left`,
            decoration: ``,
            color: ``,
            bold: false,
            pageBreak: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationColor: ``,
            width: `auto`,
          },
        ],
      },
      {
        decoration: ``,
        decorationColor: ``,
        alignment: `left`,
        preserveLeadingSpaces: true,
        text: `  `,
        bold: false,
        pageBreak: ``,
        color: ``,
        width: `auto`,
        decorationStyle: ``,
        fontSize: 15,
      },
      {
        columns: [
          {
            bold: false,
            pageBreak: ``,
            fontSize: 15,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            color: ``,
            width: 170,
            decoration: ``,
            alignment: `center`,
            text: `..............................................................`,
            decorationStyle: ``,
          },
          {
            decorationColor: ``,
            decorationStyle: ``,
            decoration: ``,
            alignment: `center`,
            width: 170,
            fontSize: 15,
            preserveLeadingSpaces: true,
            bold: false,
            color: ``,
            text: `..............................................................`,
            pageBreak: ``,
          },
          {
            fontSize: 15,
            decoration: ``,
            decorationStyle: ``,
            bold: false,
            alignment: `center`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            width: 170,
            decorationColor: ``,
            text: `..............................................................`,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            text: `ชื่อเจ้าหน้าที่`,
            width: 170,
            decorationStyle: ``,
            color: ``,
            fontSize: 15,
            decorationColor: ``,
            decoration: ``,
            alignment: `center`,
            bold: false,
            pageBreak: ``,
            preserveLeadingSpaces: true,
          },
          {
            decorationStyle: ``,
            bold: false,
            width: 170,
            alignment: `center`,
            decoration: ``,
            pageBreak: ``,
            color: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            text: `ลายเซ็นเจ้าหน้าที่`,
            fontSize: 15,
          },
          {
            decorationColor: ``,
            text: `วันที่`,
            preserveLeadingSpaces: true,
            decoration: ``,
            width: 170,
            color: ``,
            fontSize: 15,
            pageBreak: ``,
            alignment: `center`,
            bold: false,
            decorationStyle: ``,
          },
        ],
      },
      {
        alignment: `left`,
        decoration: ``,
        color: ``,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        fontSize: 15,
        decorationStyle: ``,
        decorationColor: ``,
        text: ` `,
        bold: false,
        width: `auto`,
      },
      {
        columns: [
          {
            decoration: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            text: ``,
            decorationStyle: ``,
            decorationColor: ``,
            fontSize: 15,
            width: `auto`,
            color: ``,
            bold: false,
            alignment: `left`,
          },
          {
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decoration: ``,
            fontSize: 15,
            alignment: `left`,
            text: `สำหรับผู้ป่วยเมื่อเสร็จสิ้นขั้นตอนการเก็บปัสสาวะ`,
            pageBreak: ``,
            width: `auto`,
            decorationColor: ``,
            bold: `true`,
            color: ``,
          },
        ],
      },
      {
        decorationStyle: ``,
        width: `auto`,
        fontSize: 15,
        decorationColor: ``,
        bold: false,
        text: `ข้าพเจ้ายืนยันว่าการเก็บตัวอย่างปัสสาวะครั้งนี้เจ้าหน้าที่โรงพยาบาลได้ปิดผนึกภาชนะที่บรรจุตัวอย่างปัสสาวะของข้าพเจ้าต่อหน้า ข้าพเจ้าเรียบร้อยแล้ว`,
        alignment: `left`,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        color: ``,
        decoration: ``,
      },
      {
        color: ``,
        alignment: `left`,
        width: `auto`,
        text: `  `,
        pageBreak: ``,
        decorationStyle: ``,
        decorationColor: ``,
        fontSize: 15,
        bold: false,
        preserveLeadingSpaces: true,
        decoration: ``,
      },
      {
        columns: [
          {
            decorationColor: ``,
            fontSize: 15,
            pageBreak: ``,
            decorationStyle: ``,
            text: `ลงนาม.................................................................`,
            color: ``,
            alignment: `left`,
            decoration: ``,
            width: 200,
            bold: false,
            preserveLeadingSpaces: true,
          },
          {
            bold: false,
            text: ``,
            decorationColor: ``,
            decoration: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            alignment: `center`,
            color: ``,
            fontSize: 15,
            width: 80,
          },
          {
            pageBreak: ``,
            decorationColor: ``,
            decorationStyle: ``,
            bold: false,
            alignment: `center`,
            color: ``,
            decoration: ``,
            text: `พยาน 1 ลงนาม........................................................`,
            width: 200,
            fontSize: 15,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            pageBreak: ``,
            bold: false,
            fontSize: 15,
            color: ``,
            decoration: ``,
            decorationColor: ``,
            text: `(.......................................................................)`,
            preserveLeadingSpaces: true,
            alignment: `center`,
            width: 200,
          },
          {
            decorationColor: ``,
            decorationStyle: ``,
            width: 80,
            decoration: ``,
            bold: false,
            text: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            fontSize: 15,
            color: ``,
          },
          {
            pageBreak: ``,
            text: `(.......................................................................)`,
            alignment: `center`,
            decoration: ``,
            fontSize: 15,
            width: 200,
            color: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decorationStyle: ``,
          },
        ],
      },
      {
        decorationStyle: ``,
        text: `  `,
        decorationColor: ``,
        decoration: ``,
        fontSize: 15,
        alignment: `left`,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        width: `auto`,
        bold: false,
        color: ``,
      },
      {
        columns: [
          {
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            fontSize: 15,
            decoration: ``,
            width: 200,
            text: `วันที่..................................................................`,
            color: ``,
            bold: false,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            alignment: `center`,
          },
          {
            width: 80,
            decoration: ``,
            text: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            color: ``,
            decorationStyle: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            decorationColor: ``,
          },
          {
            decorationStyle: ``,
            width: 200,
            text: `พยาน 2 ลงนาม........................................................`,
            pageBreak: ``,
            fontSize: 15,
            bold: false,
            alignment: `center`,
            decoration: ``,
            color: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            width: 200,
            color: ``,
            decoration: ``,
            decorationColor: ``,
            text: ``,
            alignment: `left`,
            pageBreak: ``,
            fontSize: 15,
            bold: false,
          },
          {
            decorationStyle: ``,
            width: 80,
            fontSize: 15,
            decorationColor: ``,
            decoration: ``,
            bold: false,
            alignment: `left`,
            preserveLeadingSpaces: true,
            color: ``,
            text: ``,
            pageBreak: ``,
          },
          {
            alignment: `center`,
            fontSize: 15,
            decorationColor: ``,
            color: ``,
            width: 200,
            decorationStyle: ``,
            text: `(.......................................................................)`,
            decoration: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            bold: false,
            margin: [0, 0, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            width: 280,
            text: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            alignment: `left`,
            decorationColor: ``,
            decorationStyle: ``,
            decoration: ``,
            pageBreak: ``,
            bold: false,
          },
          {
            text: `(กรณีพิมพ์รายนิ้วมือ/ยินยอมทางโทรศัพท์)`,
            decoration: ``,
            width: 200,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            color: ``,
            alignment: `center`,
            decorationColor: ``,
            fontSize: 15,
            bold: false,
            pageBreak: ``,
          },
        ],
      },
    ],
    pageOrientation: `portrait`,
    defaultStyle: {
      font: `THSarabunNew`,
    },
    pageSize: `A4`,
  };
}
