/* Generated apis ---------------------- */
import { to } from "react-lib/utils";
import BaseService from "react-lib/apis/services/BaseService";
import config from "../../config/config";
const HOST = `${config.API_HOST}`;
/* ['UserProfileAPI'] */
/* params:  */
/* data:  */
const ProxyMyProfileAPI: {
  get: ({
    params,
    apiToken,
    extra,
    pk
  }: {
    params?: any;
    apiToken?: any;
    extra?: any;
    pk?:any;
  }) => any;
  post: ({
    params,
    apiToken,
    data,
    extra,
    pk
  }: {
    params?: any;
    apiToken?: any;
    data?: any;
    extra?: any;
    pk?:any;
  }) => any;
} = {
  get: async ({ params, apiToken, extra, pk }) => {
    const base = new BaseService();
    if (apiToken) {
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    const result = await to(
      base.client
        .get(`${HOST}/profile/api/${pk}/`, {
          params: params ? { ...params } : {},
          ...extra,
        })
        .then(base.handleResponse)
        .catch(base.throwErrorMessage)
    );
    return [result[1], result[0], result[2]];
  },
  post: async ({params, apiToken, data ,extra, pk}) => {
    const base = new BaseService();
    const result = await to(
      base.client
        .post(
          `${HOST}/profile/api/${pk}/`,
          { ...data },
          {  params: params ? { ...params } : {},
          ...extra,}
        )
        .then(base.handleResponse)
        .catch(base.throwErrorMessage)
    );
    return [result[1], result[0], result[2]];
  },
}

export default ProxyMyProfileAPI;
