import WasmController from "../../../../frameworks/WasmController";
// APIs
import ZoneLogList from "issara-sdk/apis/ZoneLogList_core";

export type State = {
  // sequence
  ZoneLogSequence?: {
    sequenceIndex?: string | null;

    zoneLogList?: any;
  } | null;
};

export const StateInitial: State = {
  // sequence
  ZoneLogSequence: {
    sequenceIndex: null,

    zoneLogList: {},
  },
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  const [response, error, network] = await ZoneLogList.list({
    apiToken: controller.apiToken,
    params: { encounter: params.encounter, limit: 100 },
  });

  controller.setState({
    ZoneLogSequence: {
      sequenceIndex: "START",
      zoneLogList: error ? {} : response,
    },
  });
};
