import FormSymbolImages from "./AddSymbolPrintList";
import CONFIG from "config/config";
import CompanyLogoForm from "react-lib/apps/HISV3/common/CompanyLogoForm";

type HeaderPrintListProps = Partial<{
  createdDate: string;
  patientInfo: any;
  formName: string;
  styles: Record<string, any>;
  header: any;
  images: any;
  pageMargins: number[];
}>;

/*                            Note                                */
// HeaderPrintList [TH/EN] => แสดงข้อมูลของผู้ป่วย (ปัจจุบันเป็น Sapiens)
// HeaderSapiensInfo => แสดงข้อมูลของโรงพยาบาล Sapiens

const FORM_NAME = "AddHeaderSapiensPrintList";
const DEFAULT_PAGE_MARGINS = [38, 120, 38, 40];

const LOGO_HEIGHT = 34;
const LOGO_RESIZE =
  1 -
  (CONFIG.LOGO_REPORT.find((i: any) => i.type === 2)?.height - LOGO_HEIGHT) /
    CONFIG.LOGO_REPORT.find((i: any) => i.type === 2)?.height;
const LOGO_WIDTH = CONFIG.LOGO_REPORT.find((i: any) => i.type === 2)?.width * LOGO_RESIZE;

export const AddHeaderPrintListTH = async (doc: any, props: HeaderPrintListProps) => {
  const companyLogoForm = await CompanyLogoForm({
    type: 2,
    font: "KanitLM",
    height: LOGO_HEIGHT,
    form: FORM_NAME,
  });
  const { images } = companyLogoForm;

  return {
    ...doc,
    // logo height 80 + marginHeader 16 = 96 (pageMargins must greater than Header + Header Bottom Margin)
    // margin: [left, top, right, bottom]
    pageMargins: props.pageMargins ? props.pageMargins : DEFAULT_PAGE_MARGINS,
    header: () => {
      return {
        table: {
          widths: ["55%", "45%"],
          body: [
            [
              // Left Side (logo and form name)
              {
                stack: [
                  {
                    image: "logo",
                    width: LOGO_WIDTH,
                    height: LOGO_HEIGHT,
                    margin: [0, 0, 0, 24],
                  },
                  {
                    text: props.formName,
                    alignment: `center`,
                    font: `KanitLM`,
                    fontSize: 10,
                    bold: true,
                    margin: [0, -16, 0, 0],
                  },
                ],
                border: [true, true, false, true],
              },
              // Right Side (patient information)
              {
                stack: [
                  {
                    text: ` `,
                    preserveLeadingSpaces: true,
                    font: `KanitLM`,
                    fontSize: 8,
                  },
                  {
                    text: `ชื่อ : ${props.patientInfo?.formatPatient}`,
                    font: `KanitLM`,
                    fontSize: 8,
                    bold: true,
                  },
                  {
                    columns: [
                      {
                        text: `HN : ${props.patientInfo?.hn}`,
                        font: `KanitLM`,
                        fontSize: 8,
                        bold: true,
                      },
                      {
                        text: `EN/AN : ${props.patientInfo?.patientEncounter}`,
                        font: `KanitLM`,
                        fontSize: 8,
                        bold: true,
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: `วันเกิด : ${props.patientInfo?.birthdate}`,
                        font: `KanitLM`,
                        fontSize: 8,
                        bold: true,
                      },
                      {
                        text: `อายุ : ${props.patientInfo?.ageYear} ปี ${props.patientInfo?.ageMonth} เดือน ${props.patientInfo?.ageDay} วัน`,
                        font: `KanitLM`,
                        fontSize: 8,
                        bold: true,
                      },
                    ],
                  },
                  {
                    text: `เพศ : ${
                      props.patientInfo?.gender
                        ? props.patientInfo.gender === "F"
                          ? "หญิง"
                          : props.patientInfo.gender === "M"
                          ? "ชาย"
                          : props.patientInfo.gender
                        : "ไม่ระบุ"
                    }`,
                    font: `KanitLM`,
                    fontSize: 8,
                    bold: true,
                  },
                  {
                    text: ` `,
                    preserveLeadingSpaces: true,
                    font: `KanitLM`,
                    fontSize: 8,
                  },
                ],
                border: [false, true, true, true],
              },
            ],
          ],
        },
        layout: {
          paddingTop: function (i: any, node: any) {
            return 5;
          },
          paddingBottom: function (i: any, node: any) {
            return 5;
          },
          paddingLeft: function (i: any, node: any) {
            return 5;
          },
          paddingRight: function (i: any, node: any) {
            return 5;
          },
        },
        margin: [16, 16, 16, 0],
      };
    },
    footer: (currentPage: any, pageCount: any) => {
      return {
        columns: [
          {
            text: `วันที่พิมพ์ : ${props.createdDate}`,
            alignment: `left`,
            font: `KanitLM`,
            fontSize: 8,
          },
          {
            text: `หน้า ` + currentPage.toString() + ` จาก ` + pageCount,
            alignment: `right`,
            font: `KanitLM`,
            fontSize: 8,
          },
        ],
        margin: [16, 16, 16, 16],
      };
    },
    images: {
      ...images,
      checked: FormSymbolImages.checked,
      unchecked: FormSymbolImages.unchecked,
      squarebox: FormSymbolImages.squarebox,
      checkmark: FormSymbolImages.checkmark,
      ...props.images,
    },
    styles: {
      ...(props.styles || {}),
    },
  };
};

export const AddHeaderPrintListEN = async (doc: any, props: HeaderPrintListProps) => {
  const companyLogoForm = await CompanyLogoForm({
    type: 2,
    font: "KanitLM",
    height: LOGO_HEIGHT,
    form: FORM_NAME,
  });
  const { images } = companyLogoForm;

  return {
    ...doc,
    // logo height 80 + marginHeader 16 = 96 (pageMargins must greater than Header + Header Bottom Margin)
    // margin: [left, top, right, bottom]
    pageMargins: props.pageMargins ? props.pageMargins : DEFAULT_PAGE_MARGINS,
    header: () => {
      return {
        table: {
          widths: ["52%", "48%"],
          body: [
            [
              // Left Side (logo and form name)
              {
                stack: [
                  {
                    image: "logo",
                    width: LOGO_WIDTH,
                    height: LOGO_HEIGHT,
                    margin: [0, 0, 0, 24],
                  },
                  {
                    text: props.formName,
                    alignment: `center`,
                    font: `Poppins`,
                    fontSize: 10,
                    bold: true,
                    margin: [0, -16, 0, 0],
                  },
                ],
                border: [true, true, false, true],
              },
              // Right Side (patient information)
              {
                stack: [
                  {
                    text: ` `,
                    preserveLeadingSpaces: true,
                    font: `PoppinsLM`,
                    fontSize: 8,
                  },
                  {
                    columns: [
                      {
                        text: `Name :`,
                        preserveLeadingSpaces: true,
                        font: `PoppinsLM`,
                        fontSize: 8,
                        bold: true,
                        width: `auto`,
                      },
                      {
                        text: ` ${props.patientInfo?.formatPatient}`,
                        preserveLeadingSpaces: true,
                        font: `KanitLM`,
                        fontSize: 8,
                        bold: true,
                        width: `auto`,
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: `HN : ${props.patientInfo?.hn}`,
                        font: `PoppinsLM`,
                        fontSize: 8,
                        bold: true,
                      },
                      {
                        text: `EN/AN : ${props.patientInfo?.patientEncounter}`,
                        font: `PoppinsLM`,
                        fontSize: 8,
                        bold: true,
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: `Birth date : ${props.patientInfo?.patientBirthdateEN}`,
                        font: `PoppinsLM`,
                        fontSize: 8,
                        bold: true,
                      },
                      {
                        text: `Age : ${props.patientInfo?.ageYear} years ${props.patientInfo?.ageMonth} months ${props.patientInfo?.ageDay} days`,
                        font: `PoppinsLM`,
                        fontSize: 8,
                        bold: true,
                      },
                    ],
                  },
                  {
                    text: `Gender : ${
                      props.patientInfo?.gender
                        ? props.patientInfo.gender === "F"
                          ? "Female"
                          : props.patientInfo.gender === "M"
                          ? "Male"
                          : props.patientInfo.gender
                        : "N/A"
                    }`,
                    font: `PoppinsLM`,
                    fontSize: 8,
                    bold: true,
                  },
                  {
                    text: ` `,
                    preserveLeadingSpaces: true,
                    font: `PoppinsLM`,
                    fontSize: 8,
                  },
                ],
                border: [false, true, true, true],
              },
            ],
          ],
        },
        layout: {
          paddingTop: function (i: any, node: any) {
            return 5;
          },
          paddingBottom: function (i: any, node: any) {
            return 5;
          },
          paddingLeft: function (i: any, node: any) {
            return 5;
          },
          paddingRight: function (i: any, node: any) {
            return 5;
          },
        },
        margin: [16, 16, 16, 0],
      };
    },
    footer: (currentPage: any, pageCount: any) => {
      return {
        columns: [
          {
            text: `Printed date : ${props.createdDate}`,
            alignment: `left`,
            font: `PoppinsLM`,
            fontSize: 8,
          },
          {
            text: `Page ` + currentPage.toString() + ` of ` + pageCount,
            alignment: `right`,
            font: `PoppinsLM`,
            fontSize: 8,
          },
        ],
        margin: [16, 16, 16, 16],
      };
    },
    images: {
      ...images,
      checked: FormSymbolImages.checked,
      unchecked: FormSymbolImages.unchecked,
      squarebox: FormSymbolImages.squarebox,
      checkmark: FormSymbolImages.checkmark,
      ...props.images,
    },
    styles: {
      ...(props.styles || {}),
    },
  };
};

export const AddHeaderSapiensInfoTH = async (doc: any, props: HeaderPrintListProps) => {
  const companyLogoForm = await CompanyLogoForm({
    type: 2,
    font: "KanitLM",
    height: LOGO_HEIGHT,
    form: FORM_NAME,
  });
  const { images } = companyLogoForm;

  return {
    ...doc,
    // * Logo height 100
    pageMargins: props.pageMargins ? props.pageMargins : DEFAULT_PAGE_MARGINS,
    header: function () {
      return {
        stack: [
          {
            columns: [
              // { image: 'logo', width: 150, height: 150 },
              { image: "logo", width: LOGO_WIDTH, height: LOGO_HEIGHT, margin: [10, 12, 0, 0] },
              { text: "", width: "*" },
              {
                table: {
                  body: [
                    [
                      {
                        stack: [
                          {
                            text: "บริษัท เซเปี้ยนซ์ เมดิเคชั่น จำกัด",
                            font: "KanitLM",
                            style: "hospitalName",
                          },
                          {
                            text: "1559/232 ถนนเจริญนคร",
                            font: "KanitLM",
                            style: "hospitalInfo",
                          },
                          {
                            text: "แขวงบางลำภูล่าง เขตคลองสาน",
                            font: "KanitLM",
                            style: "hospitalInfo",
                          },
                          {
                            text: "กรุงเทพมหานคร 10600",
                            font: "KanitLM",
                            style: "hospitalInfo",
                          },
                        ],
                        border: [false, false, true, false],
                        alignment: "right",
                      },
                      {
                        stack: [
                          {
                            text: "Sapiens Medication Co., Ltd.",
                            font: "Poppins",
                            style: "hospitalName",
                          },
                          {
                            text: "1559/232 Charoennakhon Rd.",
                            font: "Poppins",
                            style: "hospitalInfo",
                          },
                          {
                            text: "Bang Lamphu Lang, Klongsan,",
                            font: "Poppins",
                            style: "hospitalInfo",
                          },
                          {
                            text: "Bangkok 10600",
                            font: "Poppins",
                            style: "hospitalInfo",
                          },
                        ],
                        border: [true, false, false, false],
                        alignment: "right",
                      },
                    ],
                    [
                      {
                        text: "arnatudomsakdi@yahoo.com Tel. +66 81 906 733-7",
                        font: "Poppins",
                        border: [false, false, false, false],
                        colSpan: 2,
                        alignment: "right",
                        style: "hospitalInfo",
                      },
                      {},
                    ],
                  ],
                },
                width: 220,
              },
            ],
            columnGap: 10,
            margin: [0, 16, 16, 0],
          },
        ].concat(props.header || ({ text: "" } as any)),
      };
    },
    footer: (currentPage: any, pageCount: any) => {
      return {
        columns: [
          {
            text: `หน้า ` + currentPage.toString() + ` จาก ` + pageCount,
            alignment: `left`,
            font: `KanitLM`,
            fontSize: 8,
          },
          {
            text: `วันที่พิมพ์/Printed date : ${props.createdDate}`,
            alignment: `right`,
            font: `KanitLM`,
            fontSize: 8,
          },
        ],
        margin: [16, 16, 16, 16],
      };
    },
    images: {
      ...images,
      checked: FormSymbolImages.checked,
      unchecked: FormSymbolImages.unchecked,
      squarebox: FormSymbolImages.squarebox,
      checkmark: FormSymbolImages.checkmark,
      ...props.images,
    },
    styles: {
      hospitalName: {
        fontSize: 7,
        bold: true,
      },
      hospitalInfo: {
        fontSize: 7,
      },
      header: {
        fontSize: 20,
        bold: true,
        margin: [0, 0, 0, 2],
      },
      ...(props.styles || {}),
    },
  };
};
