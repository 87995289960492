import React, { useEffect, useState } from "react";

import "react-lib/apps/IsHealth/IHMobile/ishealth.scss";
import { Image } from "semantic-ui-react";

import CONFIG from "config/config";
// import { logoSquare } from "/images/ishealth/logo-square.png";s

type MobBottomBarTypes = {
  onEvent: any;
  onclick?: Function;
  history: any;
  initialInnerScreen: any;
  activeItem: "main" | "appointment" | "history" | "chat" | "etc";
  chatUnread?: number;
};

const MobBottomBar = (props: MobBottomBarTypes) => {
  const [active, setActive] = useState<string>("");
  const [chatUnreadCount, setChatUnreadCount] = useState<number>(0);

  useEffect(() => {
    setActive(props.activeItem);
  }, [props.activeItem]);

  useEffect(() => {
    setChatUnreadCount(props.chatUnread || 0);
  }, [props.chatUnread]);

  useEffect(() => {
    function updateSize() {
      console.log(
        props.initialInnerScreen,
        window.innerWidth,
        window.innerHeight
      );
      if (window.innerWidth !== props.initialInnerScreen?.width) {
        props.onEvent({
          message: "HandleSetInitialInnerScreen",
          params: {
            height: window.innerHeight,
            width: window.innerWidth,
          },
        });
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [props.initialInnerScreen]);

  useEffect(() => {
    const handleOrientationchange = () => {
      console.log(
        "orientation",
        window.orientation,
        window.innerWidth,
        window.innerHeight
      );
      props.onEvent({
        message: "HandleSetInitialInnerScreen",
        params: {
          height: window.innerHeight,
          width: window.innerWidth,
        },
      });
    };
    window.addEventListener("orientationchange", handleOrientationchange);
    return () =>
      window.removeEventListener("orientationchange", handleOrientationchange);
  }, []);

  const handleClickMenu = (route: string) => {
    props.history.replace(route === "main" ? "/" : route);
    setActive(route);
  };

  const menu = [
    {
      name: "หน้าหลัก",
      route: "main",
    },
    {
      name: "นัดหมาย",
      route: "appointment",
    },
    {
      name: "ประวัติการรักษา",
      route: "history",
    },
    {
      name: "แชท",
      route: "chat",
    },
    {
      name: "อื่นๆ",
      route: "etc",
    },
  ];
  console.log("saika ~ chatUnread:", props.chatUnread);
  return (
    <div
      className="navigation-bottom"
      style={{ top: `${(props.initialInnerScreen?.height || 0) - 63}px` }}
    >
      {menu.map(
        (item: any) =>
          !CONFIG.HIDDEN_MENU.includes(item.route) && (
            <div
              className={`${active === item.route ? "active" : ""}`}
              key={item.route}
              onClick={() => handleClickMenu(item.route)}
            >
              <div
                style={{
                  position: "relative",
                  flexDirection: "column",
                }}
              >
                <div>
                  <Image
                    src={`/images/ishealth/bottom_bar/${item.route}${
                      active === item.route ? "_active" : ""
                    }.png`}
                  />
                </div>
                <div style={{ minWidth: "max-content" }}>{item.name}</div>
                {item.route === "chat" && (
                  <div
                    className="count-unread-noti"
                    style={{
                      width: "18px",
                      height: "18px",
                      position: "absolute",
                      top: "-10px",
                      right: "-10px",
                      ...(!((chatUnreadCount || 0) > 0) && { display: "none" }),
                    }}
                  >
                    {chatUnreadCount}
                  </div>
                )}
              </div>
            </div>
          )
      )}
    </div>
  );
};

MobBottomBar.displayname = "BottomBar";
export default MobBottomBar;
