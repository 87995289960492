
// import ReactDOM from 'react-dom';
// import MainPatient from './patient-lib/MainPatient';
import * as serviceWorker from './serviceWorker';
import "./index.css"
import { createRoot } from 'react-dom/client';
import { HashRouter, BrowserRouter } from 'react-router-dom';
import React from 'react';
import MobIsHealth from 'patient-lib/MobIsHealth'
// const MobIsHealth = React.lazy(()=> import('patient-lib/MobIsHealth'))
// ReactDOM.render(<BrowserRouter><MainPatient /></BrowserRouter>, document.getElementById('root'));

// ReactDOM.render(<BrowserRouter><div /></BrowserRouter>, document.getElementById('root'));
const container = document.getElementById('root');

const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<BrowserRouter><MobIsHealth/></BrowserRouter>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.register();
