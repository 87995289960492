import AddTablePreSedationTH from "./AddTablePreSedationTH";

export default function toPDFMakeData(props: any) {
  return {
    content: [
      {
        width: `auto`,
        bold: `true`,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        fontSize: `18`,
        color: ``,
        text: `หนังสือยินยอมก่อนการให้ยาระงับปวดหรือยาระงับความรู้สึกตัวการทำหัตถการ/การผ่าตัด`,
        pageBreak: ``,
        decoration: ``,
        decorationStyle: ``,
        alignment: `center`,
      },
      {
        text: ` `,
        width: `auto`,
        bold: false,
        color: ``,
        pageBreak: ``,
        decorationColor: ``,
        fontSize: 15,
        decoration: ``,
        alignment: `left`,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
      },
      {
        columns: [
          {
            fontSize: 15,
            preserveLeadingSpaces: true,
            bold: false,
            pageBreak: ``,
            decorationColor: ``,
            alignment: `left`,
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            width: 30,
            color: ``,
          },
          {
            bold: false,
            decorationStyle: ``,
            width: `auto`,
            pageBreak: ``,
            decorationColor: ``,
            decoration: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            alignment: `left`,
            color: ``,
            text: `ข้าพเจ้าขอแสดงความยินยอมโดยความสมัครใจ ให้มีการเฝ้าติดตามการทำงานของร่างกายและให้มีการใช้ยากล่อมประสาท`,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            width: `auto`,
            pageBreak: ``,
            color: ``,
            decorationStyle: ``,
            fontSize: 15,
            decoration: ``,
            text: `และยาระงับความรู้สึกภายใต้การดูแลของนายแพทย์/แพทย์หญิง`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
          },
          {
            decoration: ``,
            fontSize: 15,
            bold: false,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            color: ``,
            text: ` `,
            width: 5,
            pageBreak: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
          },
          {
            stack: [
              {
                color: ``,
                bold: false,
                fontSize: 15,
                alignment: `center`,
                preserveLeadingSpaces: true,
                decoration: ``,
                text: props.items?.formatDoctor,
                pageBreak: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                margin: [0, 0, 0, -15],
              },
              {
                margin: [0, 0, 0, 0],
                decorationColor: ``,
                text: `.............................................................`,
                alignment: `left`,
                decoration: ``,
                pageBreak: ``,
                width: `auto`,
                color: ``,
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                fontSize: 15,
                bold: false,
              },
            ],
          },
          {
            width: `auto`,
            color: ``,
            bold: false,
            fontSize: 15,
            decorationColor: ``,
            decoration: ``,
            text: `หรือแพทย์ที่ได้รับมอบหมาย`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            alignment: `left`,
          },
        ],
      },
      {
        pageBreak: ``,
        decoration: ``,
        decorationStyle: ``,
        color: ``,
        preserveLeadingSpaces: true,
        bold: false,
        decorationColor: ``,
        width: `auto`,
        fontSize: 15,
        text: `ให้ทำหน้าที่ ของ${props.items?.hospital}`,
        alignment: `left`,
      },
      {
        columns: [
          {
            color: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            text: ``,
            pageBreak: ``,
            alignment: `left`,
            bold: false,
            decorationColor: ``,
            fontSize: 15,
            decoration: ``,
            width: 30,
          },
          {
            text: `ข้าพเจ้า `,
            fontSize: 15,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationColor: ``,
            width: `auto`,
            decorationStyle: ``,
            bold: false,
            color: ``,
            alignment: `left`,
          },
          {
            fontSize: 15,
            bold: false,
            decorationColor: ``,
            decorationStyle: ``,
            alignment: `left`,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            width: 5,
            decoration: ``,
            text: ` `,
            color: ``,
            preserveLeadingSpaces: true,
          },
          {
            stack: [
              {
                preserveLeadingSpaces: true,
                pageBreak: ``,
                width: `auto`,
                decoration: ``,
                margin: [0, 0, 0, -15],
                alignment: `center`,
                text: props.items?.formatPatient,
                fontSize: 15,
                bold: false,
                decorationStyle: ``,
                decorationColor: ``,
                color: ``,
              },
              {
                pageBreak: ``,
                decoration: ``,
                decorationColor: ``,
                alignment: `left`,
                margin: [0, 0, 0, 0],
                fontSize: 15,
                preserveLeadingSpaces: true,
                text: `........................................................`,
                decorationStyle: ``,
                width: `auto`,
                color: ``,
                bold: false,
              },
            ],
            width: `auto`,
          },
          {
            text: `ได้รับการนัดหมายเพื่อทำหัตการ/การผ่าตัด`,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: `auto`,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            stack: [
              {
                decorationStyle: ``,
                margin: [0, 0, 0, -15],
                preserveLeadingSpaces: true,
                decoration: ``,
                decorationColor: ``,
                alignment: `left`,
                text: props.items?.formatOperation,
                bold: false,
                fontSize: 15,
                width: `auto`,
                pageBreak: ``,
                color: ``,
              },
              {
                pageBreak: ``,
                color: ``,
                decoration: ``,
                bold: false,
                margin: [0, 0, 0, 0],
                alignment: `left`,
                width: `auto`,
                text: `..................................................................................................................................................................................................................`,
                fontSize: 15,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decorationStyle: ``,
              },
            ],
          },
        ],
      },
      {
        decorationStyle: ``,
        fontSize: 15,
        decorationColor: ``,
        text: `ข้าพเจ้าได้รับการอธิบายและยอมรับว่าการใช้ยาระงับความรู้สึก ไม่ว่าจะในรูปแบบใดก็ตาม อาจก่อให้เกิดภาวะแทรกซ้อนต่างๆ แม้อุบัติการณ์ของภาวะแทรกซ้อนที่ร้ายแรงจะต่ำ แต่ก็เกิดได้โดยไม่คาดหมาย เช่น การติดเชื้อ มีเลือดออกผิดปกติ ฟันได้รับ`,
        color: ``,
        decoration: ``,
        pageBreak: ``,
        width: `auto`,
        alignment: `left`,
        bold: false,
        preserveLeadingSpaces: true,
      },
      {
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        pageBreak: ``,
        fontSize: 15,
        alignment: `left`,
        decorationColor: ``,
        color: ``,
        text: `ความเสียหาย ปฏิกิริยาอันไม่พึงประสงค์ต่อยาที่ได้รับ ลิ่มเลือกอุดตันในหลอดเลือด สูญเสียความรู้สึก สูญเสียการมองเห็น สูญเสียการทำงานของแขนและขา อัมพาต สมองและหัวใจทำงานผิดปกติ อวัยวะเสียหน้าที่หรือหยุดการทำงาน หรือเสียชีวิต ภาวะแทรกซ้อน ตามที่ระบุข้างท้ายนี้ สามารถเกิดขึ้นได้กับข้าพเจ้าเช่นกัน ข้าพเจ้าเข้าใจความเสี่ยง ประโยชน์ ทางเลือกวิธีการ`,
        bold: false,
        width: `auto`,
        decoration: ``,
      },
      {
        text: `ใช้ยาระงับความรู้สึก และยาระงับปวดหลังทำหัตถการ ตามรูปแบบที่เลือกด้านล่าง แต่ในบางกรณีการใช้ยาระงับความรู้สึก บาง`,
        pageBreak: ``,
        color: ``,
        decorationColor: ``,
        width: `auto`,
        fontSize: 15,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        bold: false,
        alignment: `left`,
        decoration: ``,
      },
      {
        decorationColor: ``,
        preserveLeadingSpaces: true,
        fontSize: 15,
        color: ``,
        decorationStyle: ``,
        text: `ประเภท เช่น ยาชาเฉพาะที่อาจจะไม่ประสบความสำเร็จและจำเป็นต้องปรับเปลี่ยนใช้วิธีอื่นๆ ร่วมด้วยหรือใช้แทน เช่น ดมยาสลบ เป็นต้น`,
        decoration: ``,
        width: `auto`,
        alignment: `left`,
        pageBreak: ``,
        bold: false,
      },
      {
        columns: [
          {
            decoration: ``,
            preserveLeadingSpaces: true,
            text: ``,
            decorationColor: ``,
            width: 30,
            decorationStyle: ``,
            alignment: `left`,
            pageBreak: ``,
            color: ``,
            fontSize: 15,
            bold: false,
          },
          {
            preserveLeadingSpaces: true,
            width: `auto`,
            decorationColor: ``,
            decoration: ``,
            text: `ข้าพเจ้ายินยอมรับยาระงับความรู้สึกตามที่ระบุด้านล่าง ร่วมทั้งการปรับเปลี่ยนเพื่อใช้วิธีอื่นๆ หากมีความจำเป็น`,
            fontSize: 15,
            decorationStyle: ``,
            bold: false,
            pageBreak: ``,
            alignment: `left`,
            color: ``,
          },
        ],
      },
      {
        alignment: `left`,
        fontSize: 15,
        width: `auto`,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        pageBreak: ``,
        decorationColor: ``,
        bold: false,
        decoration: ``,
        color: ``,
        text: `รูปแบบการใช้ยาระงับความรู้สึก`,
      },
      {
        columns: [
          {
            decoration: ``,
            decorationStyle: ``,
            fontSize: 15,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            color: ``,
            text: ``,
            width: 30,
            decorationColor: ``,
            alignment: `left`,
            bold: false,
          },
          {
            width: `auto`,
            pageBreak: ``,
            bold: false,
            color: ``,
            text: `[  ]`,
            decorationStyle: ``,
            alignment: `center`,
            decorationColor: ``,
            decoration: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
          },
          {
            color: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            width: 5,
            decoration: ``,
            decorationColor: ``,
            bold: false,
            alignment: `left`,
            decorationStyle: ``,
            text: ``,
          },
          {
            pageBreak: ``,
            decorationStyle: ``,
            alignment: `left`,
            color: ``,
            bold: false,
            width: `auto`,
            preserveLeadingSpaces: true,
            decoration: ``,
            text: `ดมยาสลบ หรือบริหารยาสลบทางหลอดเลือดดำ `,
            decorationColor: ``,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            decorationColor: ``,
            fontSize: 15,
            color: ``,
            bold: false,
            alignment: `left`,
            text: ``,
            width: 30,
            decoration: ``,
            pageBreak: ``,
            decorationStyle: ``,
          },
          {
            width: `auto`,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `center`,
            text: `[  ]`,
            decorationColor: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
          },
          {
            bold: false,
            alignment: `left`,
            decoration: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            pageBreak: ``,
            text: ``,
            fontSize: 15,
            color: ``,
            width: 5,
          },
          {
            color: ``,
            preserveLeadingSpaces: true,
            text: `การเฝ้าติดตามการทำงานของร่างกายอย่างใกล้ชิด (ใช้หรือไม่ใช้ยากล่อมประสาทร่วมด้วย)`,
            width: `auto`,
            fontSize: 15,
            alignment: `left`,
            decorationStyle: ``,
            bold: false,
            pageBreak: ``,
            decoration: ``,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            width: 30,
            decorationColor: ``,
            pageBreak: ``,
            fontSize: 15,
            color: ``,
            decorationStyle: ``,
            bold: false,
            alignment: `left`,
            decoration: ``,
            text: ``,
          },
          {
            width: `auto`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            alignment: `center`,
            bold: false,
            color: ``,
            text: `[  ]`,
            fontSize: 15,
            decoration: ``,
            pageBreak: ``,
            decorationColor: ``,
          },
          {
            width: 5,
            fontSize: 15,
            decoration: ``,
            bold: false,
            pageBreak: ``,
            color: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            text: ``,
            decorationStyle: ``,
            alignment: `left`,
          },
          {
            bold: false,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            color: ``,
            text: `บล็อกหลัง (ใช้หรือไม่ใช้ยากล่อมประสาทร่วมด้วย)`,
            fontSize: 15,
            decorationStyle: ``,
            decorationColor: ``,
            decoration: ``,
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            decorationColor: ``,
            fontSize: 15,
            width: 30,
            color: ``,
            text: ``,
            decoration: ``,
            bold: false,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            alignment: `left`,
          },
          {
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationStyle: ``,
            color: ``,
            bold: false,
            alignment: `center`,
            decoration: ``,
            decorationColor: ``,
            pageBreak: ``,
            text: `[  ]`,
            width: `auto`,
          },
          {
            text: ``,
            fontSize: 15,
            decoration: ``,
            bold: false,
            width: 5,
            decorationStyle: ``,
            alignment: `left`,
            color: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            pageBreak: ``,
          },
          {
            alignment: `left`,
            width: `auto`,
            decorationStyle: ``,
            text: `บล็อกเส้นประสาท (ใช้หรือไม่ใช้ยากล่อมประสาทร่วมด้วย)`,
            decorationColor: ``,
            color: ``,
            bold: false,
            decoration: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decoration: ``,
            text: ``,
            width: 30,
            color: ``,
            bold: false,
            decorationStyle: ``,
            pageBreak: ``,
          },
          {
            alignment: `center`,
            decorationColor: ``,
            decoration: ``,
            fontSize: 15,
            text: `[  ]`,
            color: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            pageBreak: ``,
            bold: false,
          },
          {
            color: ``,
            fontSize: 15,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            bold: false,
            alignment: `left`,
            text: ``,
            decorationColor: ``,
            decoration: ``,
            width: 5,
            pageBreak: ``,
          },
          {
            text: `ยากล่อมประสาท`,
            decorationStyle: ``,
            bold: false,
            decoration: ``,
            color: ``,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            width: `auto`,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            decoration: ``,
            preserveLeadingSpaces: true,
            text: ``,
            decorationStyle: ``,
            bold: false,
            decorationColor: ``,
            color: ``,
            width: 30,
            pageBreak: ``,
            fontSize: 15,
          },
          {
            decorationStyle: ``,
            width: `auto`,
            decorationColor: ``,
            alignment: `left`,
            text: `ข้าพเจ้าเข้าใจว่า กรณีที่ข้าพเจ้าเสพหรือใช้สารเสพติดชนิดใดๆ ก็ตามอันอาจมีผลทำให้เกิดภาวะแทรกซ้อนเพิ่มขึ้นได้ `,
            preserveLeadingSpaces: true,
            color: ``,
            bold: false,
            decoration: ``,
            pageBreak: ``,
            fontSize: 15,
          },
        ],
      },
      {
        color: ``,
        alignment: `left`,
        preserveLeadingSpaces: true,
        text: `จำเป็นจะต้องแจ้งให้วิสัญญีแพทย์ทราบ ในกรณีที่ข้าพเจ้าได้รับอนุญาตให้กลับบ้านได้ภายใน 24 ชั่วโมง หลังจากการผ่าตัดนั้น ข้าพเจ้าจะไม่ขับขี่ยานพาหนะหรือใช้ยานพาหนะโดยสารสาธารณะใดๆ และจะมีบุคคลบรรลุนิติภาวะนำ (หรือพา) ข้าพเจ้ากลับที่พัก`,
        decorationStyle: ``,
        decoration: ``,
        decorationColor: ``,
        width: `auto`,
        bold: false,
        fontSize: 15,
        pageBreak: ``,
      },
      {
        columns: [
          {
            text: ``,
            pageBreak: ``,
            decoration: ``,
            decorationStyle: ``,
            fontSize: 15,
            bold: false,
            alignment: `left`,
            preserveLeadingSpaces: true,
            color: ``,
            width: 30,
            decorationColor: ``,
          },
          {
            decoration: ``,
            width: `auto`,
            bold: false,
            decorationStyle: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            alignment: `left`,
            text: `ข้าพเจ้าได้อ่านหนังสือยินยอมฉบับนี้ หรือมีบุคคลอื่นให้ข้าพเจ้าเข้าใจแล้ว ข้าพเจ้ามีโอกาสซักถามและได้รับคำตอบเป็น`,
            decorationColor: ``,
            color: ``,
          },
        ],
      },
      {
        fontSize: 15,
        alignment: `left`,
        decoration: ``,
        decorationColor: ``,
        text: `ที่หน้าพอใจ และมีเวลาเพียงพอในการพิจารณาเพื่อการตัดสินใจ`,
        color: ``,
        width: `auto`,
        decorationStyle: ``,
        pageBreak: ``,
        bold: false,
        preserveLeadingSpaces: true,
      },
      {
        fontSize: 15,
        pageBreak: ``,
        text: `สำหรับผู้ป่วยผู้หญิง : ในกรณีที่ข้าพเจ้าตั้งครรภ์ ข้าพเจ้าเข้าใจว่าการผ่าตัดที่ไม่รีบด่วนนั้นควรจะทำหลังจากที่ ข้าพเจ้าคลอดบุตร`,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        decorationColor: ``,
        color: ``,
        bold: false,
        width: `auto`,
        decoration: ``,
        alignment: `left`,
      },
      {
        preserveLeadingSpaces: true,
        width: `auto`,
        alignment: `left`,
        color: ``,
        decorationColor: ``,
        bold: false,
        decorationStyle: ``,
        pageBreak: ``,
        text: `เรียบร้อยแล้ว ข้าพเจ้าเข้าใจว่ายาระงับความรู้สึกสามารถผ่านทางรกและอาจทำให้ทารกในครรภ์หมดความรู้สึกไปชั่วขณะ แม้ว่า`,
        decoration: ``,
        fontSize: 15,
      },
      {
        alignment: `left`,
        decorationStyle: ``,
        text: `ภาวะแทรกซ้อนจากการใช้ยาระงับความรู้สึกระหว่างตั้งครรภ์จะเกิดขึ้นน้อยมากก็ตามแต่ความเสี่ยงที่จะเกิดต่อทารกในครรภ์นั้นหมายรวมถึง แต่มิได้จำกัดอยู่เพียง การพิการแต่กำเนิด การคลอดก่อนกำหนด สมองถูกทำลายอย่างถาวร และถึงขั้นเสียชีวิต`,
        decorationColor: ``,
        bold: false,
        decoration: ``,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        width: `auto`,
        fontSize: 15,
        color: ``,
      },
      {
        columns: [
          {
            color: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            bold: false,
            decorationColor: ``,
            fontSize: 15,
            text: ``,
            pageBreak: ``,
            alignment: `left`,
            decorationStyle: ``,
            width: 30,
          },
          {
            fontSize: 15,
            decorationStyle: ``,
            decoration: ``,
            text: `ความเสี่ยงอื่นๆ ที่อาจเกิดขึ้นกับข้าพเจ้าจากการใช้ยาระงับความรู้สึก คือ: ..................................................................................`,
            alignment: `left`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            decorationColor: ``,
            color: ``,
            bold: false,
          },
        ],
      },
      {
        alignment: `left`,
        width: `auto`,
        color: ``,
        fontSize: 15,
        text: `…………………………………………………………………………………………………………….........................................................................................`,
        bold: false,
        preserveLeadingSpaces: true,
        decoration: ``,
        decorationStyle: ``,
        decorationColor: ``,
        pageBreak: ``,
      },
      {
        decoration: ``,
        preserveLeadingSpaces: true,
        bold: false,
        decorationStyle: ``,
        pageBreak: `before`,
        width: `auto`,
        color: ``,
        decorationColor: ``,
        alignment: `left`,
        fontSize: 15,
        text: ``,
      },
      {
        columns: [
          {
            decorationStyle: ``,
            color: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            bold: false,
            text: `ผู้ให้ข้อมูลข้างต้นแก่ข้าพเจ้าคือ `,
            decoration: ``,
            decorationColor: ``,
            width: `auto`,
            alignment: `left`,
            pageBreak: ``,
          },
          {
            preserveLeadingSpaces: true,
            bold: false,
            alignment: `left`,
            decoration: ``,
            color: ``,
            pageBreak: ``,
            decorationColor: ``,
            text: ``,
            decorationStyle: ``,
            width: 5,
            fontSize: 15,
          },
          {
            stack: [
              {
                pageBreak: ``,
                alignment: `left`,
                decorationColor: ``,
                bold: false,
                color: ``,
                text: props.items?.operatingData?.anes_name,
                preserveLeadingSpaces: true,
                fontSize: 15,
                decoration: ``,
                width: `auto`,
                margin: [0, 0, 0, -15],
                decorationStyle: ``,
              },
              {
                margin: [0, 0, 0, 0],
                fontSize: 15,
                decorationColor: ``,
                bold: false,
                color: ``,
                decorationStyle: ``,
                alignment: `left`,
                decoration: ``,
                pageBreak: ``,
                preserveLeadingSpaces: true,
                text: `...............................................................................`,
                width: `auto`,
              },
            ],
          },
        ],
      },
      {
        width: `auto`,
        decoration: ``,
        fontSize: 15,
        alignment: `left`,
        preserveLeadingSpaces: true,
        text: `ณ ที่นี้ ข้าพเจ้าขอแสดงความยินยอมเพื่อรับการรักษาดังกล่าวข้างต้น`,
        color: ``,
        pageBreak: ``,
        bold: false,
        decorationColor: ``,
        decorationStyle: ``,
      },
      {
        decorationColor: ``,
        bold: false,
        alignment: `left`,
        fontSize: 15,
        pageBreak: ``,
        text: ` `,
        decorationStyle: ``,
        decoration: ``,
        width: `auto`,
        preserveLeadingSpaces: true,
        color: ``,
      },
      {
        columns: [
          {
            fontSize: 15,
            alignment: `center`,
            text: `ลงนาม........................................................   `,
            width: 200,
            decoration: ``,
            pageBreak: ``,
            color: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decorationStyle: ``,
            bold: false,
          },
          {
            preserveLeadingSpaces: true,
            width: 80,
            decorationStyle: ``,
            text: `  `,
            fontSize: 15,
            decorationColor: ``,
            bold: false,
            color: ``,
            pageBreak: ``,
            alignment: `left`,
            decoration: ``,
          },
          {
            alignment: `center`,
            text: `พยาน 1 ลงนาม........................................................ `,
            color: ``,
            decoration: ``,
            decorationStyle: ``,
            pageBreak: ``,
            bold: false,
            decorationColor: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            width: 200,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            width: 200,
            fontSize: 15,
            decorationStyle: ``,
            text: `(.............................................................)`,
            decoration: ``,
            alignment: `center`,
            pageBreak: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            color: ``,
          },
          {
            decorationStyle: ``,
            text: ``,
            color: ``,
            alignment: `left`,
            decoration: ``,
            width: 80,
            preserveLeadingSpaces: true,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            decorationColor: ``,
          },
          {
            bold: false,
            fontSize: 15,
            decorationStyle: ``,
            color: ``,
            decoration: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            alignment: `center`,
            width: 200,
            text: `(.............................................................)`,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            color: ``,
            alignment: `center`,
            decoration: ``,
            decorationColor: ``,
            decorationStyle: ``,
            fontSize: 15,
            width: 200,
            text: `แพทย์ลงนาม........................................................`,
            preserveLeadingSpaces: true,
            bold: false,
          },
          {
            alignment: `left`,
            decorationColor: ``,
            width: 80,
            decorationStyle: ``,
            bold: false,
            pageBreak: ``,
            text: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decoration: ``,
            color: ``,
          },
          {
            alignment: `center`,
            preserveLeadingSpaces: true,
            width: 200,
            pageBreak: ``,
            color: ``,
            bold: false,
            decoration: ``,
            decorationStyle: ``,
            fontSize: 15,
            decorationColor: ``,
            text: `พยาน 2 ลงนาม........................................................`,
          },
        ],
      },
      {
        columns: [
          {
            text: `(.............................................................)`,
            pageBreak: ``,
            width: 200,
            preserveLeadingSpaces: true,
            color: ``,
            decorationColor: ``,
            bold: false,
            alignment: `center`,
            fontSize: 15,
            decorationStyle: ``,
            decoration: ``,
          },
          {
            fontSize: 15,
            text: ``,
            pageBreak: ``,
            color: ``,
            decoration: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            width: 80,
            bold: false,
            decorationColor: ``,
            decorationStyle: ``,
          },
          {
            color: ``,
            bold: false,
            decorationColor: ``,
            text: `(.............................................................)`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decoration: ``,
            decorationStyle: ``,
            width: 200,
            fontSize: 15,
            alignment: `center`,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            fontSize: 15,
            decorationStyle: ``,
            decoration: ``,
            alignment: `center`,
            color: ``,
            text: `(แพทย์ผู้ให้คำอธิบาย)`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            bold: false,
            width: 200,
          },
          {
            bold: false,
            decorationStyle: ``,
            decoration: ``,
            fontSize: 15,
            color: ``,
            width: 80,
            decorationColor: ``,
            alignment: `left`,
            text: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
          },
          {
            color: ``,
            fontSize: 15,
            decorationColor: ``,
            alignment: `center`,
            decoration: ``,
            width: 200,
            decorationStyle: ``,
            pageBreak: ``,
            text: `(กรณีพิมพ์ลายนิ้วมือ/ยินยอมทางโทรศัพท์)`,
            preserveLeadingSpaces: true,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            width: 200,
            alignment: `left`,
            bold: false,
            decoration: ``,
            fontSize: 15,
            color: ``,
            decorationStyle: ``,
            pageBreak: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            text: ``,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            fontSize: 15,
            pageBreak: ``,
            decoration: ``,
            text: `วันที่........................เวลา..................`,
            width: 200,
            decorationColor: ``,
            alignment: `center`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            bold: false,
          },
        ],
      },
      {
        bold: false,
        decoration: ``,
        width: `auto`,
        fontSize: 15,
        text: ` `,
        color: ``,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        decorationColor: ``,
        alignment: `left`,
        decorationStyle: ``,
      },
      {
        columns: [
          {
            decorationColor: ``,
            preserveLeadingSpaces: true,
            bold: `true`,
            pageBreak: ``,
            width: `auto`,
            alignment: `left`,
            decorationStyle: ``,
            fontSize: 15,
            color: ``,
            text: `การแปลและให้ข้อมูลโดยผู้แปลภาษา`,
            decoration: `underline`,
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            color: ``,
            decorationStyle: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decoration: ``,
            bold: false,
            width: `auto`,
            alignment: `left`,
            decorationColor: ``,
            pageBreak: ``,
          },
          {
            text: `    ข้าพเจ้าได้แปลหนังสือยินยอมก่อนการให้ยาระงับปวดหรือยาระงับความรู้สึกตัวการทำหัตถการ/การผ่าตัด (Pre Procedural Sedation and Pre Anesthesia Informed Consent) รวมทั้งข้อมูลซึ่งแพทย์ได้อธิบายให้แก่ผู้ป่วย/ตัวแทนผู้ป่วยทราบ`,
            decorationColor: ``,
            bold: false,
            color: ``,
            decoration: ``,
            fontSize: 15,
            alignment: `left`,
            decorationStyle: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            fontSize: 15,
            decorationColor: ``,
            alignment: `center`,
            color: ``,
            text: `ภาษาที่แปล................................................`,
            preserveLeadingSpaces: true,
            decoration: ``,
            width: 200,
            decorationStyle: ``,
            bold: false,
          },
          {
            fontSize: 15,
            preserveLeadingSpaces: true,
            decoration: ``,
            pageBreak: ``,
            bold: false,
            color: ``,
            decorationColor: ``,
            width: 80,
            alignment: `left`,
            decorationStyle: ``,
            text: ` `,
          },
          {
            fontSize: 15,
            alignment: `center`,
            width: 200,
            decoration: ``,
            text: `ผู้แปลลงนาม........................................................ `,
            bold: false,
            decorationStyle: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            preserveLeadingSpaces: true,
            width: 280,
            color: ``,
            bold: false,
            decorationStyle: ``,
            text: ``,
            fontSize: 15,
            decoration: ``,
            decorationColor: ``,
            pageBreak: ``,
          },
          {
            alignment: `center`,
            color: ``,
            text: `(.............................................................)`,
            decoration: ``,
            pageBreak: ``,
            decorationStyle: ``,
            width: 200,
            fontSize: 15,
            bold: false,
            preserveLeadingSpaces: true,
            decorationColor: ``,
          },
        ],
      },
      {
        margin: [0, 0, 0, 0],
        bold: false,
        decorationColor: ``,
        decorationStyle: ``,
        alignment: `left`,
        pageBreak: `before`,
        color: ``,
        preserveLeadingSpaces: true,
        fontSize: 15,
        decoration: ``,
        text: ` `,
        width: `auto`,
      },
      AddTablePreSedationTH,
    ],
    pageOrientation: `portrait`,
    defaultStyle: {
      font: `THSarabunNew`,
    },
    pageSize: `A4`,
  };
}
