export default function toPDFMakeData(props: any) {
  return {
    content: [
      {
        alignment: `center`,
        preserveLeadingSpaces: true,
        decorationColor: ``,
        pageBreak: ``,
        bold: `true`,
        decoration: ``,
        text: `Consent for Blood/Blood Product Transfusion`,
        fontSize: `18`,
        decorationStyle: ``,
        color: ``,
        width: `auto`,
      },
      {
        color: ``,
        decorationColor: ``,
        pageBreak: ``,
        alignment: `left`,
        decorationStyle: ``,
        decoration: ``,
        width: `auto`,
        fontSize: 15,
        preserveLeadingSpaces: true,
        bold: false,
        text: ` `,
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationStyle: ``,
            width: 30,
            decorationColor: ``,
            color: ``,
            decoration: ``,
            bold: false,
            alignment: `left`,
            text: ``,
            pageBreak: ``,
          },
          {
            width: `auto`,
            color: ``,
            decorationColor: ``,
            decorationStyle: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            text: `I (Mr/Mrs./Ms.)`,
            alignment: `left`,
            fontSize: 15,
            decoration: ``,
            bold: false,
          },
          {
            text: ` `,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            stack: [
              {
                text: props.items?.formatPatient,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `................................................................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            decoration: ``,
            fontSize: 15,
            decorationStyle: ``,
            decorationColor: ``,
            color: ``,
            text: `request (of authorizes)`,
            preserveLeadingSpaces: true,
            alignment: `left`,
            pageBreak: ``,
            bold: false,
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            text: `Dr.`,
            decorationStyle: ``,
            bold: false,
            decoration: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            width: `auto`,
            decorationColor: ``,
            color: ``,
            fontSize: 15,
          },
          {
            text: ` `,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            stack: [
              {
                text: props.items?.formatDoctor,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.........................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            decorationStyle: ``,
            color: ``,
            bold: false,
            pageBreak: ``,
            decoration: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            alignment: `left`,
            text: `and hospital’s authorized personnel to treat my condition(s).`,
            decorationColor: ``,
            width: `auto`,
          },
        ],
      },
      {
        decorationColor: ``,
        preserveLeadingSpaces: true,
        color: ``,
        fontSize: 15,
        decoration: ``,
        decorationStyle: ``,
        bold: false,
        pageBreak: ``,
        text: `I have been informed that I need to receive blood or its component as determined by my physician(s).`,
        width: `auto`,
        alignment: `left`,
      },
      {
        color: ``,
        preserveLeadingSpaces: true,
        bold: false,
        alignment: `left`,
        decorationStyle: ``,
        text: ` I understand why I need blood and/or its components to treat my condition and that there are no other treatment options. and without this procedure. I may experience life-threatening complications. Furthermore,`,
        decorationColor: ``,
        pageBreak: ``,
        width: `auto`,
        fontSize: 15,
        decoration: ``,
      },
      {
        decorationStyle: ``,
        pageBreak: ``,
        decoration: ``,
        bold: false,
        decorationColor: ``,
        alignment: `left`,
        fontSize: 15,
        preserveLeadingSpaces: true,
        text: `I acknowledge that my blood will be drawn to monitor the results of treatment and that I may receive blood add/or its components regulatory  according to my physician’s treatment plan. Finally, I understand how the blood add/or its components will be  delivered and the associated risks of receiving blood  add / or its components. as follows:`,
        width: `auto`,
        color: ``,
      },
      {
        columns: [
          {
            decoration: `underline`,
            color: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            bold: `true`,
            pageBreak: ``,
            text: `Transfusion of blood or Its Component Method and Its Technique.`,
            decorationStyle: ``,
            fontSize: 15,
            alignment: `left`,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            width: 30,
            fontSize: 15,
            preserveLeadingSpaces: true,
            bold: false,
            color: ``,
            alignment: `left`,
            decorationStyle: ``,
            pageBreak: ``,
            decoration: ``,
            text: ``,
          },
          {
            decoration: ``,
            alignment: `left`,
            fontSize: 15,
            bold: false,
            color: ``,
            decorationColor: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            text: `Blood supply or its components will to patient by sterilized intravenous techniques. Sterlizabon consists`,
            width: `auto`,
            decorationStyle: ``,
          },
        ],
      },
      {
        decorationColor: ``,
        alignment: `left`,
        decorationStyle: ``,
        bold: false,
        fontSize: 15,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        color: ``,
        decoration: ``,
        width: `auto`,
        text: `of cleansing the skin where the needle is to be placed. Verification of correct or blood  components will be done by patient identification, Monitoring abnormal symptoms during or after receive blood and/or  blood components by measuring the vital signs periodically before, during and after transfusion of blood and/or blood components.`,
      },
      {
        columns: [
          {
            decorationColor: ``,
            bold: `true`,
            preserveLeadingSpaces: true,
            color: ``,
            width: `auto`,
            text: `Risk and Possible Complications of Blood Transfusion`,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            decoration: `underline`,
            decorationStyle: ``,
          },
        ],
      },
      {
        width: `auto`,
        text: `Risk and complications from blood transfusion are slight however, there might be a small possibility of the following risk and complications:`,
        decoration: ``,
        alignment: `left`,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        fontSize: 15,
        decorationStyle: ``,
        decorationColor: ``,
        bold: false,
        color: ``,
      },
      {
        columns: [
          {
            decoration: ``,
            width: 30,
            bold: false,
            text: ``,
            pageBreak: ``,
            alignment: `left`,
            color: ``,
            decorationColor: ``,
            fontSize: 15,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
          },
          {
            decorationColor: ``,
            decoration: ``,
            decorationStyle: ``,
            width: `auto`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            bold: false,
            color: ``,
            pageBreak: ``,
            alignment: `left`,
            text: `- Temporary and mid complication including bruising, swelling, and pain in the transfusion area .`,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            color: ``,
            bold: false,
            fontSize: 15,
            width: 30,
            pageBreak: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decoration: ``,
            text: ``,
          },
          {
            decoration: ``,
            alignment: `left`,
            text: `- Complication from blood components due to rarely occurring blood Incompatibility between donor and receive after comprehensive checks.`,
            bold: false,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            color: ``,
            decorationStyle: ``,
            fontSize: 15,
            width: `auto`,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            fontSize: 15,
            color: ``,
            decoration: ``,
            text: ``,
            decorationStyle: ``,
            alignment: `left`,
            pageBreak: ``,
            width: 30,
            bold: false,
            decorationColor: ``,
          },
          {
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decoration: ``,
            width: `auto`,
            decorationColor: ``,
            bold: false,
            fontSize: 15,
            color: ``,
            text: `- Some infections can be caused by blood or blood components such as hepatitis, malaria, HIV etc.`,
            alignment: `left`,
            decorationStyle: ``,
          },
        ],
      },
      {
        decoration: ``,
        decorationStyle: ``,
        decorationColor: ``,
        bold: false,
        alignment: `left`,
        width: `auto`,
        color: ``,
        text: `However, the rate of infection is very low because at blood units are screened for infection according to International standard.`,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        fontSize: 15,
      },
      {
        bold: false,
        color: ``,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        width: `auto`,
        decoration: ``,
        alignment: `left`,
        fontSize: 15,
        text: ` `,
        decorationStyle: ``,
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            fontSize: 15,
            decoration: ``,
            decorationColor: ``,
            decorationStyle: ``,
            alignment: `left`,
            text: ``,
            pageBreak: ``,
            bold: false,
            width: 30,
            color: ``,
          },
          {
            alignment: `left`,
            text: `I hereby I AGREE to receive the treatment  mentioned above.`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            bold: false,
            color: ``,
            decorationStyle: ``,
            fontSize: 15,
            decorationColor: ``,
            decoration: ``,
            width: `auto`,
          },
        ],
      },
      {
        bold: false,
        fontSize: 15,
        width: `auto`,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        decoration: ``,
        decorationColor: ``,
        color: ``,
        text: ` `,
        pageBreak: ``,
        alignment: `left`,
      },
      {
        columns: [
          {
            width: 200,
            text: `Signature........................................................   `,
            preserveLeadingSpaces: true,
            bold: false,
            pageBreak: ``,
            decorationStyle: ``,
            color: ``,
            alignment: `center`,
            decorationColor: ``,
            fontSize: 15,
            decoration: ``,
          },
          {
            alignment: `left`,
            text: `  `,
            fontSize: 15,
            decorationStyle: ``,
            bold: false,
            decorationColor: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            width: 80,
            pageBreak: ``,
            color: ``,
          },
          {
            decoration: ``,
            decorationColor: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            width: 200,
            text: `Witness 1........................................................`,
            alignment: `center`,
            pageBreak: ``,
            fontSize: 15,
            color: ``,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            pageBreak: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decoration: ``,
            fontSize: 15,
            alignment: `center`,
            width: 200,
            text: `(.............................................................)`,
            color: ``,
          },
          {
            decoration: ``,
            width: 80,
            alignment: `left`,
            decorationStyle: ``,
            text: ``,
            bold: false,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            color: ``,
            fontSize: 15,
            decorationColor: ``,
          },
          {
            color: ``,
            width: 200,
            text: `(.............................................................)`,
            decorationColor: ``,
            alignment: `center`,
            bold: false,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            pageBreak: ``,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            width: 200,
            decorationColor: ``,
            pageBreak: ``,
            color: ``,
            bold: false,
            alignment: `center`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            fontSize: 15,
            text: `Date........................Time..................`,
          },
          {
            decoration: ``,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decorationStyle: ``,
            width: 80,
            bold: false,
            color: ``,
            text: ``,
            fontSize: 15,
          },
          {
            preserveLeadingSpaces: true,
            fontSize: 15,
            text: `Witness 2........................................................`,
            pageBreak: ``,
            width: 200,
            decorationColor: ``,
            decorationStyle: ``,
            color: ``,
            alignment: `center`,
            bold: false,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            alignment: `left`,
            decorationColor: ``,
            pageBreak: ``,
            text: ``,
            color: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            width: 280,
            bold: false,
            fontSize: 15,
          },
          {
            color: ``,
            alignment: `center`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            text: `(.............................................................)`,
            width: 200,
            decoration: ``,
            bold: false,
            decorationColor: ``,
            decorationStyle: ``,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            color: ``,
            bold: false,
            decorationColor: ``,
            width: 280,
            decoration: ``,
            decorationStyle: ``,
            text: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            pageBreak: ``,
          },
          {
            fontSize: 15,
            decoration: ``,
            alignment: `center`,
            width: 200,
            color: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            text: `(Fingerprint/consent over telephone)`,
            decorationColor: ``,
            bold: false,
            pageBreak: ``,
          },
        ],
      },
      {
        decoration: ``,
        preserveLeadingSpaces: true,
        decorationColor: ``,
        pageBreak: `before`,
        text: ``,
        width: `auto`,
        color: ``,
        decorationStyle: ``,
        fontSize: 15,
        alignment: `left`,
        bold: false,
      },
      {
        columns: [
          {
            width: `auto`,
            decorationStyle: ``,
            decoration: `underline`,
            text: `Interpreter’s Statement`,
            preserveLeadingSpaces: true,
            color: ``,
            pageBreak: ``,
            decorationColor: ``,
            fontSize: 15,
            alignment: `left`,
            bold: `true`,
          },
        ],
      },
      {
        width: `auto`,
        bold: false,
        decorationStyle: ``,
        pageBreak: ``,
        alignment: `left`,
        decorationColor: ``,
        fontSize: 15,
        text: `I have given a translation of Consent for Blood/Blood Product Transfusion including information that physician has explained to patient/patient’s representative.`,
        color: ``,
        preserveLeadingSpaces: true,
        decoration: ``,
      },
      {
        pageBreak: ``,
        alignment: `left`,
        color: ``,
        fontSize: 15,
        text: ` `,
        decorationStyle: ``,
        bold: false,
        decoration: ``,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        width: `auto`,
      },
      {
        columns: [
          {
            bold: false,
            fontSize: 15,
            text: `Translate to Language...................................... `,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationStyle: ``,
            color: ``,
            width: 200,
            alignment: `center`,
            decoration: ``,
          },
          {
            decorationStyle: ``,
            color: ``,
            bold: false,
            decorationColor: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            width: 80,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            fontSize: 15,
            text: ``,
          },
          {
            fontSize: 15,
            color: ``,
            decorationStyle: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            alignment: `center`,
            width: 200,
            text: `Interpreter........................................................`,
            decoration: ``,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            decorationStyle: ``,
            text: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            alignment: `left`,
            width: 280,
            bold: false,
            fontSize: 15,
            decorationColor: ``,
            pageBreak: ``,
          },
          {
            pageBreak: ``,
            preserveLeadingSpaces: true,
            width: 200,
            bold: false,
            decoration: ``,
            alignment: `center`,
            color: ``,
            text: `(.............................................................)`,
            fontSize: 15,
            decorationColor: ``,
            decorationStyle: ``,
          },
        ],
      },
      {
        text: ` `,
        decoration: ``,
        decorationStyle: ``,
        decorationColor: ``,
        width: `auto`,
        color: ``,
        bold: false,
        fontSize: 15,
        pageBreak: ``,
        alignment: `left`,
        preserveLeadingSpaces: true,
        margin: [0, 0, 0, 0],
      },
      {
        color: ``,
        decoration: ``,
        fontSize: 15,
        preserveLeadingSpaces: true,
        decorationColor: ``,
        alignment: `left`,
        bold: `true`,
        width: `auto`,
        text: `Status of Signer (According to Thai Civil and Commercial Code)`,
        pageBreak: ``,
        decorationStyle: ``,
      },
      {
        columns: [
          {
            decorationStyle: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            text: `[  ]`,
            decorationColor: ``,
            fontSize: 15,
            bold: false,
            color: ``,
            alignment: `center`,
            decoration: ``,
            pageBreak: ``,
          },
          {
            decorationStyle: ``,
            text: ``,
            decorationColor: ``,
            fontSize: 15,
            color: ``,
            decoration: ``,
            bold: false,
            alignment: `left`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            width: 5,
          },
          {
            color: ``,
            width: `auto`,
            alignment: `left`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            text: `1. Patient who is major and capable of giving consent`,
            decorationColor: ``,
            bold: false,
            pageBreak: ``,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            bold: false,
            text: `[  ]`,
            alignment: `center`,
            decorationStyle: ``,
            fontSize: 15,
            pageBreak: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            decoration: ``,
          },
          {
            width: 5,
            fontSize: 15,
            decorationColor: ``,
            text: ``,
            alignment: `left`,
            decorationStyle: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            color: ``,
            bold: false,
            pageBreak: ``,
          },
          {
            decorationColor: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            decoration: ``,
            bold: false,
            decorationStyle: ``,
            pageBreak: ``,
            fontSize: 15,
            text: `2. Legal husband or wife in case that the patient is not capable of giving consent (unconscious)`,
            alignment: `left`,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            decorationColor: ``,
            bold: false,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            text: `[  ]`,
            fontSize: 15,
            width: `auto`,
            color: ``,
            alignment: `center`,
          },
          {
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            width: 5,
            text: ``,
            alignment: `left`,
            decorationColor: ``,
            pageBreak: ``,
            fontSize: 15,
            color: ``,
            bold: false,
          },
          {
            bold: false,
            fontSize: 15,
            preserveLeadingSpaces: true,
            text: `3. Holder of parental responsibility in case that the patient is minor (under 20 years old)`,
            alignment: `left`,
            decorationColor: ``,
            decoration: ``,
            pageBreak: ``,
            decorationStyle: ``,
            color: ``,
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            fontSize: 15,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            text: `[  ]`,
            pageBreak: ``,
            color: ``,
            width: `auto`,
            bold: false,
            alignment: `center`,
          },
          {
            decoration: ``,
            alignment: `left`,
            decorationColor: ``,
            fontSize: 15,
            width: 5,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            text: ``,
            bold: false,
            color: ``,
            pageBreak: ``,
          },
          {
            preserveLeadingSpaces: true,
            bold: false,
            pageBreak: ``,
            decorationColor: ``,
            alignment: `left`,
            width: `auto`,
            decorationStyle: ``,
            fontSize: 15,
            text: `4. Curator in case that the patient is quasi incompetent person (adjudged by the court)`,
            color: ``,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            fontSize: 15,
            text: `[  ]`,
            decorationColor: ``,
            alignment: `center`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            width: `auto`,
            color: ``,
            bold: false,
            decorationStyle: ``,
          },
          {
            text: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            fontSize: 15,
            decorationColor: ``,
            width: 5,
            decorationStyle: ``,
            color: ``,
            alignment: `left`,
            bold: false,
          },
          {
            preserveLeadingSpaces: true,
            pageBreak: ``,
            text: `5. Guardian in case that the patient is incompetent person (adjudged by the court)`,
            width: `auto`,
            decoration: ``,
            decorationStyle: ``,
            color: ``,
            decorationColor: ``,
            fontSize: 15,
            alignment: `left`,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            width: 15,
            decoration: ``,
            decorationColor: ``,
            text: ``,
            pageBreak: ``,
            color: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            bold: false,
            alignment: `left`,
          },
          {
            decorationStyle: ``,
            decoration: ``,
            color: ``,
            decorationColor: ``,
            bold: false,
            alignment: `left`,
            pageBreak: ``,
            text: `*For no. 2-5 , please obtain certified true copy of the patient’s representative’s identification card/passport/document issued by governmental authority, which religion and blood type information are covered.`,
            width: `auto`,
            fontSize: 15,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        color: ``,
        fontSize: 15,
        alignment: `left`,
        preserveLeadingSpaces: true,
        bold: false,
        decorationStyle: ``,
        decoration: ``,
        pageBreak: ``,
        text: ` `,
        width: `auto`,
        decorationColor: ``,
      },
      {
        columns: [
          {
            alignment: `left`,
            color: ``,
            decorationStyle: ``,
            decoration: ``,
            bold: false,
            text: `Relationship with patient............................................................................................................... `,
            preserveLeadingSpaces: true,
            width: `auto`,
            decorationColor: ``,
            pageBreak: ``,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            text: `Identification number of the patient’s representative........................................................... `,
            bold: false,
            alignment: `left`,
            decoration: ``,
            width: `auto`,
            decorationColor: ``,
            color: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            width: `auto`,
            pageBreak: ``,
            text: `Telephone number..........................................................................................................................`,
            decorationStyle: ``,
            bold: false,
            decorationColor: ``,
            fontSize: 15,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            color: ``,
            bold: false,
            width: `auto`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationStyle: ``,
            text: `Email.................................................................................................................................................... `,
            alignment: `left`,
            fontSize: 15,
          },
        ],
      },
    ],
    pageOrientation: `portrait`,
    defaultStyle: {
      font: `THSarabunNew`,
    },
    pageSize: `A4`,
  };
}
